// Copyright 2024 The SeedV Lab (Beijing SeedV Technology Co., Ltd.)
// All Rights Reserved.

@import 'scss/abstracts/mixins';

.container {
  display: flex;
  flex: 1 0 200px;
  @include xs {
    flex: 1 0 140px;
  }
  height: 140px;
  padding: 16px;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
  border-radius: 12px;
  border: 1px solid #d9d9d9;
  position: relative;
  z-index: 1;
  --border: 1px;

  &.active {
    border: none;
    background: linear-gradient(to bottom, #b133ff, #4232ff, #55008a);
    &::after {
      content: '';
      width: calc(100% - calc(var(--border)) * 2);
      height: calc(100% - calc(var(--border)) * 2);
      position: absolute;
      left: var(--border);
      top: var(--border);
      background: #fff;
      border-radius: 11px;
      z-index: 0;
    }
  }

  .content {
    color: #1f1f1f;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
    z-index: 1;
  }

  .btn {
    width: 100%;
    z-index: 1;
    font-size: 14px;
  }
}
