// Copyright 2024 The SeedV Lab (Beijing SeedV Technology Co., Ltd.)
// All Rights Reserved.

@import 'scss/abstracts/mixins';

.langs {
  display: none;
  @include xs-sm {
    display: block;
  }
}
.container {
  padding: 16px 8px;
  border-radius: 16px;
  border: 2px solid #3021d9;
  box-shadow: 0px 0.8px 4px 0px rgba(3, 4, 71, 0.08),
    0px 6px 12px 0px rgba(3, 4, 71, 0.1);
  background: #fff;
  cursor: default;

  .item {
    display: flex;
    align-items: center;
    padding: 10px 8px;
    cursor: pointer;

    .icon {
      width: 20px;
      height: 20px;
      fill: #56565c;
    }

    span {
      color: #15151f;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 24px;
      display: block;
      margin-left: 8px;
      white-space: nowrap;
      @include xs-sm {
        font-size: 20px;
      }
    }

    &:hover {
      background: #e2dfff;
      border-radius: 6px;
    }
  }
}
