@import 'scss/abstracts/_mixins';
.image_grid {
  align-self: stretch;
  @include project-grid(auto, 24px, flex-start, 5, 24px);
  @include lg {
    @include project-grid(auto, 24px, flex-start, 5, 24px);
  }
  @include md {
    @include project-grid(auto, 24px, flex-start, 4, 24px);
  }
  @include sm {
    @include project-grid(auto, 24px, flex-start, 3, 24px);
  }
  @include xs {
    @include project-grid(auto, 24px, flex-start, 2, 24px);
  }
  padding: 0;
  justify-items: flex-start;
  .image_container {
    display: flex;
    flex-direction: column;
    gap: 8px;
    width: 100%;
    .image_wrap_outer {
      width: 100%;
      padding-bottom: 100%;
      position: relative;
    }
    .image_wrap {
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      padding: 6px;
      flex-shrink: 0;
      border-radius: 8px;
      border: 1px solid var(--neutral-color-divider-12, rgba(0, 0, 0, 0.12));
      display: flex;
      align-items: center;
      justify-content: center;
      position: absolute;
      user-select: none;
      transition: transform 0.3s ease-in-out;
      background: #fff;

      .checkbox {
        position: absolute;
        fill: #3021d9;
        left: 14px;
        top: 14px;
        width: 13px;
        height: 13px;
      }
      &.uploader {
        padding: 0;
      }
    }
    .image_wrap_mask_content {
      position: absolute;
      display: flex;
      gap: 4px;
      align-items: center;
      justify-content: center;
      flex-direction: column;

      color: #000;
      font-family: Poppins;
      font-size: 14px;
      font-weight: 400;
      line-height: 24px;
    }
    .image {
      border-radius: 4px;
      box-shadow: 0px 1.5px 3px 0px rgba(3, 4, 71, 0.1),
        0px 0.2px 1px 0px rgba(3, 4, 71, 0.08);
      max-height: 100%;
      max-width: 100%;
      &.image_uploading {
        transition: filter 0.5s ease; /* 平滑过渡效果 */
        filter: opacity(20%);
      }
    }
    .more_popover {
      height: 24px;
    }
    .footer {
      overflow: hidden;
      color: var(--Text-Color-Secondary-Text-Black, #56565c);
      font-size: 14px;
      font-weight: 400;
      height: 24px;
      display: flex;
      align-self: stretch;
      align-items: center;
      .updateTime {
        flex: 1 1 auto;
        overflow: hidden;
        color: var(--Text-Color-Secondary-Text-Black, #56565c);
        font-size: 14px;
        font-weight: 400;
        line-height: 20px;
        @include text-overflow-ellipsis(1);
      }
      .more {
        flex: 0 0 24px;
        height: 24px;
        cursor: pointer;
      }
    }
    .footer_upload {
      @include text-overflow-ellipsis(1);
      color: var(--Text-Color-Secondary-Text-Black, #56565c);
      text-align: center;
      font-size: 14px;
      font-weight: 600;
      line-height: 20px;
    }
    &:hover {
      .image_wrap.uploader {
        box-shadow: 0px 0.8px 4px 0px rgba(3, 4, 71, 0.08),
          0px 6px 12px 0px rgba(3, 4, 71, 0.1);
        transform: scale(1.03);
      }
    }
  }
}
.blanckDataButton {
  position: relative;
}
.uploadBtnInBlanckData {
  position: absolute;
  width: 100%;
  height: 100%;
  opacity: 0;
}
.blankDataSection.isInModal {
  margin-top: 140px;
}
