// Copyright 2024 The SeedV Lab (Beijing SeedV Technology Co., Ltd.)
// All Rights Reserved.

.container {
  width: 580px;
  max-width: 100%;
  padding: 36px 24px;
  border-radius: 12px;
  border: 1px solid rgba(0, 0, 0, 0.12);
  background: #fff;
  box-shadow: 0px 0.8px 4px 0px rgba(3, 4, 71, 0.08),
    0px 6px 12px 0px rgba(3, 4, 71, 0.1);

  .gift {
    width: 64px;
    height: 64px;
    fill: #aaaaad;
    margin: 0 auto 30px;
    display: block;
  }

  .title {
    color: #1f1f1f;
    font-size: 20px;
    font-weight: 600;
    line-height: 28px;
    text-align: center;
    margin-bottom: 24px;

    span {
      color: #ae1860;
      font-size: 20px;
      font-weight: 600;
      line-height: 28px;
    }
  }

  .content {
    color: #030447;
    font-size: 16px;
    font-weight: 400;
    margin-bottom: 26px;
  }

  .input-box {
    height: 44px;
    padding: 8px;
    border-radius: 8px;
    background: #f1f4f9;
    position: relative;

    .input {
      width: calc(100% - (8px * 2) - 64px - 12px);
      background: none;
      border: none;
      outline: none;
      color: #56565c;
      font-size: 16px;
      font-weight: 400;
    }

    .button {
      width: 64px;
      height: 36px;
      border-radius: 36px;
      background: #3021d9;
      cursor: pointer;
      position: absolute;
      right: 8px;
      top: 4px;
      color: #f2f2f2;
      text-shadow: 0px 1px 0px rgba(0, 0, 0, 0.15);
      font-size: 16px;
      font-weight: 500;
      line-height: 24px;
    }
  }

  &.center {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
