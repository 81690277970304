.cardDesign {
  width: 100%;
  height: 100%;
  position: relative;
  .scene-image {
    position: relative;
    width: calc(356.8 / 540 * 100%);
    height: calc(356.8 / 540 * 100%);
    left: calc((540 - 356.8) / 2 / 540 * 100%);
    top: calc(52 / 540 * 100%);
    &.scene-rotate {
      width: calc(312.5 / 540 * 100%);
      height: calc(312.5 / 540 * 100%);
      left: calc(131 / 540 * 100%);
      top: calc(81.81 / 540 * 100%);
      transform: rotate(16deg); /* 旋转-16度 */
      transform-origin: center; /* 旋转中心点 */
    }
  }
  .card-design-box {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-size: cover;
  }
}
