@import 'scss/abstracts/mixins';

.container {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow-y: scroll;
  position: relative;
  padding-bottom: 34px;
  .title {
    padding: 80px 0 60px 0;
    color: #1f1f1f;
    font-size: 36px;
    font-weight: 600;
    line-height: 40px;
  }
  .content {
    align-self: stretch;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 24px;
    padding: 0 40px;
    width: 100%;
    max-width: 1398px;
    margin: 0 auto;
    .item-wrap {
      display: flex;
      flex-direction: column;
      gap: 12px;
      align-self: stretch;
    }
    .item-label {
      color: #1f1f1f;
      font-size: 18px;
      font-weight: 500;
      line-height: 1.556;
    }
    .item-tool-btns {
      display: flex;
      gap: 24px;
      flex-wrap: wrap;
      svg {
        width: 25px;
      }
    }
    @include sm {
      padding: 0px 24px;
    }
    @include xs {
      padding: 0 16px;
      .item-label {
        text-align: center;
      }
      .item-tool-btns {
        flex-direction: column;
        align-items: center;
      }
    }
  }
}
