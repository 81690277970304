// Copyright 2024 The SeedV Lab (Beijing SeedV Technology Co., Ltd.)
// All Rights Reserved.
@import 'scss/abstracts/_mixins';

.mask {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 100;
}

.promotion {
  display: flex;
  width: 220px;
  padding: 12px 16px;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  position: fixed;
  z-index: 101;
  border-radius: 20px;
  background: #a5f8f2;
  box-shadow: 0px 0.2px 1px 0px rgba(3, 4, 71, 0.08),
    0px 1.5px 3px 0px rgba(3, 4, 71, 0.1);
  .icon {
    position: absolute;
    bottom: -6px;
    right: 0px;
    width: 66px;
    height: 66px;
    fill: white;
    fill-opacity: 0.7;
    transform: rotate(-45deg);
    z-index: -1;
  }
  .description {
    color: #006461;
    width: 100%;
    font-weight: 400;
    z-index: 1;
    @include text-overflow-ellipsis(6);
  }
  .target {
    height: 24px;
    line-height: 24px;
    padding: 0 16px;
    color: #fff;
    font-size: 12px;
    font-weight: 500;
    align-self: flex-start;
    border-radius: 36px;
    background: #464646;
    max-width: 100%;
    cursor: pointer;
    user-select: none;
    @include text-overflow-ellipsis(1);
    &:hover,
    &:active {
      background: #3d21d9;
    }
  }
}
