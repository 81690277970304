// Copyright 2024 The SeedV Lab (Beijing SeedV Technology Co., Ltd.)
// All Rights Reserved.

@import 'scss/abstracts/_animates.scss';

.billing-button {
  display: flex;
  align-items: center;

  .load-icon {
    animation: spin 2s linear infinite;
    stroke: #ababab;
    fill: none !important;
    margin-right: 4px;
  }

  .button {
    color: #3021d9;
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    background: transparent;
    cursor: pointer;

    &:disabled {
      cursor: default;
      color: #ababab;
    }
  }

  &.disabled {
    cursor: default;
  }
}

.tips {
  width: 260px;
  color: #000;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  padding: 6px 8px;
  border-radius: 4px;
  background: #fff;
}

.button {
  color: #3021d9;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  background: transparent;
  cursor: pointer;
}

.icon {
  width: 16px;
  height: 16px;
  fill: #56565c;
  margin-left: 4px;
  cursor: pointer;
}
