// Copyright 2024 The SeedV Lab (Beijing SeedV Technology Co., Ltd.)
// All Rights Reserved.

@import 'scss/abstracts/mixins';

.container {
  width: 248px;
  height: 36px;
  display: flex;
  align-items: center;
  padding: 0 2px;
  border-radius: 4px;
  position: relative;
  cursor: pointer;

  @include xs-sm() {
    width: 100%;
    .icon {
      display: none;
    }
    .menu {
      cursor: default;
      position: fixed;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background: #fff;
      padding: 16px;
      max-height: 575px;
      z-index: 11;
    }
    .header {
      height: 45px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      svg {
        fill: #515151;
        cursor: pointer;
      }
    }
    .content {
      position: relative;
      transform: none;
      border: none;
      box-shadow: none;
      padding: 16px 0;
    }
  }

  .avatar {
    width: 36px;
    height: 36px;
    margin-right: 8px;
    border-radius: 50%;
  }

  .userInfo {
    flex-grow: 1;
    color: #1f1f1f;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 21px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;

    display: flex;
    align-items: center;
    flex-wrap: nowrap;
    gap: 4px;
    margin-right: 10px;
    .username {
      flex-shrink: 1;
      @include text-overflow-ellipsis(1);
    }
    .tags {
      flex: 1 0 auto;
      display: flex;
      align-items: center;
    }
    .tag-popover {
      line-height: 1;
    }
  }

  .icon {
    fill: #56565c;
  }

  &:hover {
    background: rgba(221, 216, 251, 0.7);
  }

  &:active,
  &.selected {
    background: #ddd8fb;
  }
}
.tag-popover-wrapper {
  left: 50px !important;
}
.tag-popover-content {
  display: flex;
  width: 210px;
  padding: 6px 8px;
  border-radius: 4px;
  background: var(--Color, #fff);

  /* Shadow 1 */
  box-shadow: 0px 0.2px 1px 0px rgba(3, 4, 71, 0.08),
    0px 1.5px 3px 0px rgba(3, 4, 71, 0.1);

  color: #1f1f1f;
  font-size: 12px;
  font-weight: 400;
  line-height: 18px; /* 150% */
}
