// Copyright 2024 The SeedV Lab (Beijing SeedV Technology Co., Ltd.)
// All Rights Reserved.
@import 'scss/abstracts/mixins';
@import 'scss/abstracts/animates';

.container {
  height: 756px;
  max-height: calc(var(--safeAreaHeight) - 60px);
  width: calc(100vw - 48px);
  max-width: 1288px;
  display: flex;
  position: relative;
  gap: 12px;

  .left {
    flex: 0 0 auto;
    width: 332px;
    padding: 24px;
    border-radius: 16px;
    background: #fff;
    box-shadow: 0px 2px 6px 0px rgba(0, 0, 0, 0.25);
    height: 100%;

    &.loading {
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .title {
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: 24px;
      color: #56565c;
      padding: 10px 12px;
      display: flex;
      align-items: center;

      .icon {
        width: 32px;
        height: 32px;
        fill: #606060;
        margin-right: 6px;
      }
    }

    .history-list {
      height: calc(100% - 52px);
      overflow: hidden;
      overflow-y: auto;

      .list {
        margin: 0 12px;

        .history-item {
          height: 76px;
          border-radius: 4px;
          background: #f7f7f7;
          border: 2px solid transparent;

          cursor: pointer;
          margin-bottom: 8px;
          padding: 4px;
          display: flex;

          .thumbnail {
            flex: 0 0 auto;
            width: 64px;
            height: 64px;
            border-radius: 4px;
            background: #efeff4;
            margin-right: 12px;
          }

          .info {
            flex: 1;
            overflow: hidden;
            display: flex;
            flex-direction: column;
            justify-content: space-between;

            .project-title {
              color: #1f1f1f;
              font-size: 16px;
              font-weight: 500;
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
            }

            .time {
              display: flex;
              justify-content: space-between;
              color: #1f1f1f;
              font-size: 14px;
              font-weight: 500;
              line-height: 32px;
              align-items: center;
              white-space: nowrap;
            }
          }

          &.selected {
            border-color: #4906d7;
          }
        }
      }
    }
  }

  .previewer {
    flex: 1 1 994px;
    padding: 24px;
    padding-top: 80px;
    background: #fff;
    border-radius: 16px;
    box-shadow: 0px 2px 6px 0px rgba(0, 0, 0, 0.25);
    overflow: hidden;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 16px;
    position: relative;

    .left-arrow {
      position: absolute;
      left: 24px;
      top: 24px;
      width: 24px;
      height: 24px;
      fill: #1f1f1f;
      display: none;
    }

    .result {
      flex: 1;
      overflow: auto;
    }

    &.loading {
      padding-top: 24px;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }

  .button-box {
    display: flex;
    justify-content: flex-end;
    gap: 16px;
    flex: 0 0 auto;
    flex-wrap: wrap;

    .buttonBoxHeader {
      color: #000;
      text-align: center;
      font-size: 20px;
      font-weight: 400;
      line-height: 18px;
    }

    .button {
      height: 44px;
      flex: 0 0 auto;
      white-space: nowrap;

      .icon {
        fill: currentColor;
        margin-right: 4px;
        width: 24px;
      }
    }
  }

  @include md {
    .left {
      width: 308px;
    }
  }

  @include xs-sm() {
    .previewer {
      display: none !important;
      &.active {
        display: flex !important;
      }
      .left-arrow {
        display: block;
      }
    }
    .left {
      display: flex;
      flex-direction: column;
      &.hide {
        display: none;
      }
      .title {
        padding: 0 0 12px;
        flex: 0 0 auto;
      }
      .history-list {
        flex: 1;
        height: auto;
        .list .history-item.selected {
          border-color: transparent;
        }
      }
    }
  }

  @include sm {
    height: calc(var(--safeAreaHeight) - 143px);
    width: auto;
    .previewer {
      width: 528px;
    }
    .button-box {
      justify-content: flex-start;
      .button {
        width: 230px;
      }
    }
  }

  @include xs {
    height: calc(var(--safeAreaHeight) - 60px);
    width: 100vw;
    .left {
      width: 100%;
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
      padding: 24px 16px;

      .history-list {
        .list {
          margin: 0;
        }
      }
    }
    .previewer {
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
      padding: 80px 16px 16px;
      animation: popupFromBottom 0.3s;
    }
    .button-box {
      flex-direction: column;
      .button {
        width: 100%;
        height: 40px;
        font-size: 16px;
      }
    }
  }
}

.toast {
  width: 530px;
  .toastContent {
    display: flex;
    height: 180px;
    justify-content: center;
    align-items: center;
    gap: 12px;
    .text {
      color: #7d7d85;
      font-family: Poppins, 'Noto Sans';
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: 24px;
    }
  }
}

.delete-title {
  display: flex;
  gap: 10px;
  align-items: center;
}

.downloadPopover {
  flex-direction: column;
  padding: 16px;
  gap: 16px;
  overflow-y: auto;
  border-radius: 12px;
  z-index: 1001;
  border: 2px solid #3021d9;
}

.textButton {
  height: 36px;
  padding: 0 24px;
  color: #3021d9;
  font-size: 16px;
  justify-content: flex-start;

  .icon {
    fill: currentColor;
    margin-right: 4px;
    width: 20px;
    height: 20px;
  }
}

.sharePopover {
  z-index: 1001;
  border: 2px solid #3021d9;
}

.sharePopoverContent {
  display: flex;
  padding: 16px;
  gap: 16px;
  .item {
    border-radius: 12px;
    background: #f7f7f7;
    display: flex;
    padding: 18px 21px;
    flex-direction: column;
    align-items: center;
    gap: 12px;
    cursor: pointer;
    width: 108px;
    .icon {
      background: #fff;
      border-radius: 50%;
      width: 64px;
      height: 64px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .text {
      width: 61px;
      word-break: break-all;
      color: #000;
      text-align: center;
      font-size: 14px;
      font-weight: 400;
      line-height: 18px;
    }
    &:not(.disabled):hover,
    &:not(.disabled):active {
      box-shadow: 0px 0.4px 2px 0px rgba(3, 4, 71, 0.08),
        0px 3px 6px 0px rgba(3, 4, 71, 0.1);
    }
    &.disabled {
      cursor: default;
      .text {
        color: #aaaaad;
      }
    }
  }
}

.mobileShareButtons {
  width: 100%;
  .sharePopoverContent {
    width: 100%;
    overflow: auto;
    padding: 14px 0;
    gap: 12px;
    .item {
      width: 112px;
      padding: 12px;
    }
  }
}

.xsHide {
  @include xs() {
    display: none !important;
  }
}

.xsShow {
  @include not-xs() {
    display: none !important;
  }
}
