// Copyright 2024 The SeedV Lab (Beijing SeedV Technology Co., Ltd.)
// All Rights Reserved.

.container {
  width: 100%;
  .box {
    display: flex;
    &.column {
      flex-direction: column;
      .label {
        margin-bottom: 12px;
      }
    }

    &.row {
      flex-direction: row;
      align-items: center;
      gap: 12px;

      .label {
        white-space: nowrap;
        &:after {
          content: ':';
          margin-inline-start: 2px;
        }
      }
    }

    .label {
      color: #1f1f1f;
      font-size: 18px;
      font-weight: 500;
      line-height: 24px;
    }

    .input-outer {
      position: relative;
      height: 38px;
      padding: 8px 2px 0px;
      width: 100%;
      overflow: hidden;
      .mask {
        overflow: hidden;
        position: relative;
        height: 100%;
      }
      // border 用伪类实现 防止 content 区域因为 border-width 变化而出现抖动
      &::after {
        content: '';
        border-radius: 6px;
        border: 1px solid rgba(0, 0, 0, 0.12);
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        pointer-events: none;
      }
      &.focused {
        background-color: #f1f4f9;
        &::after {
          border-color: #3021d9;
          border-width: 2px;
        }
      }

      &.medium {
        height: 60px;
      }
      &.large {
        height: 120px;
      }

      .prefixContainer {
        position: absolute;
        top: 0px;
        left: 0px;
        line-height: 20px;
        margin-right: 4px;
        user-select: none;
        border-radius: 2px;
        background: #e8e8eb;
        padding: 0px 4px;
        color: #1f1f1f;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px;
        max-width: 60%;
        display: flex;
        .prefix {
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
        &::after {
          content: ':';
          margin-inline-start: 2px;
          margin-inline-end: 2px;
        }
      }
      .input {
        height: calc(100% - 1px);
      }
      &.focused {
        .input {
          height: calc(100% - 2px);
        }
      }
      &.disabled {
        background-color: inherit;
      }
    }

    .input {
      display: block;
      width: 100%;
      height: 100%;
      padding: 0 10px;
      color: #1f1f1f;
      font-size: 14px;
      font-weight: 400;
      line-height: 20px;
      outline: none;
      resize: none;
      border: none;
      background: none;
      &:disabled {
        background: none;
      }
      &::-webkit-scrollbar {
        width: 4px !important;
      }
      //placeholder颜色改变
      &::placeholder {
        color: var(--Object-Outline-Base, #a7a8ae);
      }
    }
  }

  .warning {
    color: #aa1337;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    display: flex;
    justify-content: flex-end;
    margin-top: 2px;

    .warning-icon {
      width: 20px;
      height: 20px;
      fill: #f54f4a;
      margin-right: 4px;
    }

    .text {
      width: calc(100% - 24px);
      display: inline-block;
    }
  }
}
