// Copyright 2024 The SeedV Lab (Beijing SeedV Technology Co., Ltd.)
// All Rights Reserved.
@import 'scss/abstracts/mixins';

.tutorial-guide {
  position: absolute;
  padding: 10px 10px 16px 10px;
  border-radius: 10px;
  background: #000;
  top: 31.5px;
  left: 304px;

  color: #fff;
  font-family: Outfit, 'Noto Sans' !important;
  font-size: 36px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  height: 71px;
  width: 370px;
  z-index: 1;
  svg {
    position: absolute;
    left: -128px;
    top: 65px;
  }
  @include xs-sm {
    font-size: 16px;
    height: 30px;
    width: 176px;
    top: 191px;
    left: 174px;
    padding: 4px 10px 6px 10px;
    border-radius: 4px;
    svg {
      transform: scaleY(-1) rotate(-9deg);
      left: -17px;
      top: -84px;
      width: 120px;
      height: 85px;
    }
  }
}
