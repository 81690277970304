// Copyright 2024 The SeedV Lab (Beijing SeedV Technology Co., Ltd.)
// All Rights Reserved.

@import 'scss/abstracts/mixins';

.container {
  background: linear-gradient(180deg, #fff 0%, #f9f9f9 100%);
  padding: 64px 0 24px;
  align-self: stretch;
  flex: 0 0 auto;
  display: flex;
  align-items: flex-end;
  justify-content: center;
  .image {
    display: flex;
    width: 640px;
    height: 180px;
    user-select: none;
    justify-content: center;
    align-items: center;
    cursor: default;
    img {
      max-height: 100%;
      max-width: 100%;
      pointer-events: all;
      cursor: pointer;
    }
    @include xs {
      width: 100%;
      height: 0;
      padding-top: calc(100% * 180 / 640);
      position: relative;
      img {
        height: auto;
        width: auto;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }
    }
  }
  @include sm {
    padding: 16px;
  }
  @include xs {
    padding: 16px;
  }
}
