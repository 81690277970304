.image_wrap_outer {
  width: 100%;
  padding-bottom: 100%;
  position: relative;
}
.image_wrap {
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  padding: 6px;
  flex-shrink: 0;
  border-radius: 8px;
  border: 1px solid var(--neutral-color-divider-12, rgba(0, 0, 0, 0.12));
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  user-select: none;
  transition: transform 0.3s ease-in-out;
  background: #fff;
  &.uploader {
    padding: 0;
  }
}
.image_wrap_mask_content {
  position: absolute;
  display: flex;
  gap: 4px;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  color: #000;
  font-family: Poppins;
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
}
.image {
  border-radius: 4px;
  box-shadow: 0px 1.5px 3px 0px rgba(3, 4, 71, 0.1),
    0px 0.2px 1px 0px rgba(3, 4, 71, 0.08);
  max-height: 100%;
  max-width: 100%;
  &.image_uploading {
    transition: filter 0.5s ease; /* 平滑过渡效果 */
    filter: opacity(20%);
  }
}
