// Copyright 2023 The SeedV Lab (Beijing SeedV Technology Co., Ltd.)
// All Rights Reserved.

.container {
  width: 34px;
  height: 20px;
  border-radius: 14px;
  background: #a7a8ae;
  padding: 4px;
  position: relative;
  transition: 0.2s;
  cursor: pointer;

  .checkbox {
    display: none;
  }

  .content {
    position: absolute;
    width: 14px;
    height: 14px;
    border-radius: 50%;
    background: #fff;
    top: 3px;
    left: 3px;
    transition: 0.5s;
  }

  &.checked {
    background: #3021d9;

    .content {
      left: 17px;
    }
  }

  &::after {
    content: '';
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    z-index: 2;
    border-radius: 14px;
  }
}
