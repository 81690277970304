.renameInput {
  border-radius: 3px;
  border: 1px solid #3021d9;
  height: 24px;
  padding: 4px;
  width: 100%;
  overflow: hidden;
  color: var(--Text-Color-Primary-Text-Black, #15151f);
  font-size: 14px;
  font-weight: 400;
  line-height: 1.5;
  &:focus-visible {
    outline: none;
  }
}
