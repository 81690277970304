// Copyright 2024 The SeedV Lab (Beijing SeedV Technology Co., Ltd.)
// All Rights Reserved.

.container {
  display: flex;
  align-items: center;
  position: absolute;
  right: 12px;
  bottom: 4px;
  border-radius: 4px;
  background: rgba(241, 244, 249, 0.6);

  .circle {
    width: 12px;
    height: 12px;
    border-radius: 50px;
    background: #d9d9d9;
    margin-right: 4px;

    &.focused {
      background: #43aa13;
    }

    &.error {
      background: #aa1337;
    }
  }

  .residue {
    color: #56565c;
    text-align: right;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
  }
}
