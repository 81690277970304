.container {
  position: relative;
  margin: 0 auto;
  max-width: 648px;
  .mask {
    display: none;
    position: absolute;
    inset: 0;
    background: #f1f4f9;
    border-radius: 16px;

    .drop-area {
      border-radius: 8px;
      border: 1px dashed #3021d9;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      width: 100%;
      height: 100%;
    }
    .no-drop-area {
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: 16px;
      :first-child {
        width: 88px;
        height: 88px;
        fill: #aaaaad;
      }
      :last-child {
        color: #1f1f1f;
        font-size: 16px;
        font-weight: 500;
        width: 293px;
        text-align: center;
      }
    }
  }
  &.dragging {
    .mask {
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}
.uploader {
  padding: 24px 24px 20px 24px;
}
