// Copyright 2024 The SeedV Lab (Beijing SeedV Technology Co., Ltd.)
// All Rights Reserved.

.container {
  display: flex;
  align-items: flex-start;
  cursor: pointer;
  color: #1f1f1f;

  .radioWrapper {
    width: 20px;
    height: 20px;
    margin-right: 8px;
    position: relative;
    transform: translateY(2px);

    .input {
      width: 20px;
      height: 20px;
      border: none;
      cursor: pointer;
      position: relative;
      outline: none;
      visibility: hidden;
    }

    .radio {
      position: absolute;
      left: 0;
      top: 0;
      width: 20px;
      height: 20px;
      background: #fff;
      border-radius: 50%;
      border: 2px solid #e0e0e0;
      pointer-events: none;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    &.checked {
      .radio {
        border-color: #3021d9;
        &::after {
          content: '';
          width: 12px;
          height: 12px;
          background: #3021d9;
          border-radius: 50%;
        }
      }
    }
  }

  .label {
    display: inline-flex;
    align-items: center;
    user-select: none;
  }

  &.isDisabled {
    cursor: not-allowed;
    .radio {
      background: #f0f0f0;
      border-color: #d3d3d3;
    }
  }
}
