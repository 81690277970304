// Copyright 2024 The SeedV Lab (Beijing SeedV Technology Co., Ltd.)
// All Rights Reserved.

.container {
  width: 352px;
  .text {
    font-size: 14px;
    font-weight: 400;
    line-height: 21px;
    color: #000000a6;
  }
  .footer {
    margin-top: 24px;
    display: flex;
    gap: 12px;
    button {
      flex: 1;
    }
  }
}

.mb16 {
  margin-bottom: 16px;
}

.mb8 {
  margin-bottom: 8px;
}
