.modalOverlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.01); // 半透明遮罩层
  display: flex;
  justify-content: center;
  align-items: center;
  animation: fadeIn 0.5s;
}
.container {
  display: flex;
  width: 480px;
  height: 402px;
  padding: 48px 36px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 80px;
  position: relative;

  border-radius: 24px;
  border: 1px solid var(--neutral-color-divider-12, rgba(0, 0, 0, 0.12));
  background: linear-gradient(154deg, #ffcffa 16.53%, #ddd5ff 98.1%);

  box-shadow: 0px 6px 12px 0px rgba(3, 4, 71, 0.1),
    0px 0.8px 4px 0px rgba(3, 4, 71, 0.08);
  backdrop-filter: blur(50px);

  animation: scaleUp 0.3s ease-in-out;
  .title,
  .sub_title {
    color: #1f1f1f;
    text-align: center;
    font-size: 32px;
    font-weight: 600;
    line-height: 48px;
  }
  .btn {
    display: flex;
    height: 44px;
    padding: 12px 24px 14px 24px;
    justify-content: center;
    align-items: center;
    gap: 4px;

    border-radius: 36px;
    background: #3021d9;

    cursor: pointer;
    color: #f7f3f2;
    text-shadow: 0px 1px 0px rgba(0, 0, 0, 0.25);
    font-size: 18px;
    font-weight: 600;
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes scaleUp {
  from {
    transform: scale(0.8);
    opacity: 0;
  }
  to {
    transform: scale(1);
    opacity: 1;
  }
}
