.searchInputWrapper {
  display: flex;
  align-items: center;
  gap: 9px;
  border: 1px solid #d9d9d9;
  padding: 0 12px;
  transition: border-color 0.3s;
  border: 1px solid rgba(0, 0, 0, 0.12);
  border-radius: 6px;
  height: 48px;
  &:focus-within {
    border-color: #3021d9;
  }
  &:focus {
    border-color: #3021d9;
  }
  &.error {
    border-color: #f5222d;
  }

  &.warning {
    border-color: #faad14;
  }
}

.input {
  flex: 1;
  width: 100%;
  border: none;
  outline: none;

  color: #1f1f1f;
  font-size: 16px;
  font-weight: 500;
  &::placeholder {
    color: #999;
    font-size: 16px;
    font-weight: 500;
    line-height: 3.555;
  }
  &:focus {
    border: none;
  }
}

.clearIcon {
  cursor: pointer;
  user-select: none;
  font-size: 0px;
  & > svg {
    width: 24px;
    height: 24px;
  }
}

.suffix {
  flex: 0 0 auto;
}
