// Copyright 2024 The SeedV Lab (Beijing SeedV Technology Co., Ltd.)
// All Rights Reserved.

@import 'scss/abstracts/mixins';

.container {
  height: 100%;
  display: flex;
  flex-direction: column;
  background: #fff;

  .body {
    display: flex;
    overflow: hidden;
    flex: 1;
    flex-direction: row-reverse;
    gap: 12px;
    @include xs-sm() {
      flex-direction: column;
    }
  }

  .preview-box {
    flex: 1 1 580px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #f7f7f7;
    border-radius: 12px;
    @include xs-sm() {
      flex: 0 0 auto;
      padding: 12px;
    }
  }

  .button-box {
    flex: 0 0 auto;
    width: 100%;
    display: flex;
    justify-content: flex-end;
    padding-top: 16px;
    flex-wrap: wrap;
    gap: 8px;

    @include xs {
      gap: 12px;
    }

    .buttonBoxHeader {
      color: #000;
      text-align: center;
      font-size: 20px;
      font-weight: 400;
      line-height: 18px;
    }

    .button {
      padding: 12px 24px 14px 20px;
      font-size: 16px;

      .icon {
        fill: currentColor;
        margin-right: 4px;
        width: 20px;
        height: 20px;
      }
    }

    @include sm {
      justify-content: space-between;
    }

    @include xs {
      flex-direction: column;
      position: sticky;
      left: 0;
      right: 0;
      bottom: 0;
      background: #fff;
      padding-bottom: 24px;
      .button {
        width: 100%;
      }
    }
  }
}
.toast {
  width: 530px;
  .toastContent {
    display: flex;
    height: 180px;
    justify-content: center;
    align-items: center;
    gap: 12px;
    .text {
      color: #7d7d85;
      font-family: Poppins, 'Noto Sans';
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: 24px;
    }
  }
}

.downloadPopover {
  flex-direction: column;
  padding: 16px;
  gap: 16px;
  overflow-y: auto;
  border-radius: 12px;
  border: 2px solid #3021d9;
}

.sharePopover {
  border: 2px solid #3021d9;
}

.textButton {
  height: 36px;
  padding: 0 24px;
  color: #3021d9;
  font-size: 16px;
  justify-content: flex-start;

  .icon {
    fill: currentColor;
    margin-right: 4px;
    width: 20px;
    height: 20px;
  }
}

.sharePopoverContent {
  display: flex;
  padding: 16px;
  gap: 16px;
  .item {
    border-radius: 12px;
    background: #f7f7f7;
    display: flex;
    padding: 18px 21px;
    flex-direction: column;
    align-items: center;
    gap: 12px;
    cursor: pointer;
    width: 108px;
    .icon {
      background: #fff;
      border-radius: 50%;
      width: 64px;
      height: 64px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .text {
      width: 61px;
      word-break: break-all;
      color: #000;
      text-align: center;
      font-size: 14px;
      font-weight: 400;
      line-height: 18px;
    }
    &:not(.disabled):hover,
    &:not(.disabled):active {
      box-shadow: 0px 0.4px 2px 0px rgba(3, 4, 71, 0.08),
        0px 3px 6px 0px rgba(3, 4, 71, 0.1);
    }
    &.disabled {
      cursor: default;
      .text {
        color: #aaaaad;
      }
    }
  }
}

.mobileShareButtons {
  width: 100%;
  .sharePopoverContent {
    width: 100%;
    overflow: auto;
    padding: 14px 0;
    gap: 12px;
    .item {
      width: 112px;
      padding: 12px;
    }
  }
}

.xsHide {
  @include xs() {
    display: none !important;
  }
}

.xsShow {
  @include not-xs() {
    display: none !important;
  }
}

.footerButton {
  min-width: 182px;
}
