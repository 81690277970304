// Copyright 2025 The SeedV Lab (Beijing SeedV Technology Co., Ltd.)
// All Rights Reserved.

.container {
  display: flex;
  align-items: center;
  gap: 12px;
  margin-bottom: 16px;

  .icon {
    width: 24px;
    height: 24px;
    fill: #56565c;
  }

  .group {
    display: flex;
    gap: 8px;
    align-items: center;
    .item {
      cursor: pointer;
      display: flex;
      min-width: 88px;
      height: 36px;
      padding: 8px;
      justify-content: center;
      align-items: center;
      border-radius: 4px;
      border: 1px solid rgba(0, 0, 0, 0.12);
      color: #1f1f1f;
      font-size: 14px;
      font-weight: 500;
      &.active {
        border-radius: 4px;
        border: 2px solid #3021d9;
      }
    }
  }
}

.tooltip {
  border-radius: 4px;
  padding: 6px 8px;
}
