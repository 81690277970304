// Copyright 2024 The SeedV Lab (Beijing SeedV Technology Co., Ltd.)
// All Rights Reserved.

.object-editor {
  user-select: none;
  z-index: 1;
  touch-action: none;
  pointer-events: none;
  &.active {
    z-index: 2;
  }
}

.object-editor-mask {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 3;
  &.dragging {
    cursor: move;
  }
  &.tl {
    cursor: nwse-resize;
  }
  &.tr {
    cursor: nesw-resize;
  }
  &.bl {
    cursor: nesw-resize;
  }
  &.br {
    cursor: nwse-resize;
  }
}

.imageResizer {
  position: relative;
  z-index: 2;
  &.active:not(.disabled) {
    cursor: move;
  }
  &.active {
    outline: 2px solid #745cff;
    .handler {
      display: block;
    }
  }
  .handler {
    display: none;
    touch-action: none;
    position: absolute;
    width: 16px;
    height: 16px;
    background-color: #fff;
    border-radius: 50%;
    border: 2px solid #745cff;
    pointer-events: all;
    &.hide {
      display: none !important;
    }
    &.tl {
      top: -8px;
      left: -8px;
      cursor: nwse-resize;
    }
    &.tr {
      top: -8px;
      right: -8px;
      cursor: nesw-resize;
    }
    &.bl {
      bottom: -8px;
      left: -8px;
      cursor: nesw-resize;
    }
    &.br {
      bottom: -8px;
      right: -8px;
      cursor: nwse-resize;
    }
  }
}
