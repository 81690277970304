// Copyright 2024 The SeedV Lab (Beijing SeedV Technology Co., Ltd.)
// All Rights Reserved.
@import 'scss/abstracts/mixins';
.box {
  position: relative;
  border-radius: 6px;
  border: 1px solid rgba(0, 0, 0, 0.12);
  background: #f1f4f9;
  padding: 12px 2px 0;
  display: flex;
  flex-direction: column;
  transition: height 0.3s;

  &.focused {
    border: 1px solid #3021d9;
  }

  &.small {
    height: 118px;
  }
  &.is-extra-small {
    &.small {
      height: 80px;
      @include xs {
        height: 118px;
      }
    }
  }
  &.large {
    height: 480px;
  }

  .children {
    align-items: center;
    display: flex;
    flex: 0 0 auto;
    height: 20px;
    line-height: 20px;
    margin: 0 10px 6px;
  }
}

.container {
  width: 100%;
  outline: none;
  overflow: hidden;
  overflow-y: auto;
  position: relative;
  padding: 0 6px 8px;
  flex: 1;
}

.placeholder {
  position: absolute;
  left: 12px;
  top: 12px;
  user-select: none;
  pointer-events: none;
  color: rgba(14, 29, 71, 0.38);
  width: calc(100% - 24px);
}

.box.is-extra-small .placeholder {
  // left: 0;
  @include xs {
    // font-size: 11px;
  }
}
