// Copyright 2024 The SeedV Lab (Beijing SeedV Technology Co., Ltd.)
// All Rights Reserved.

@import 'scss/abstracts/mixins';

.container {
  padding: 24px;
  border-radius: 16px;
  background: #fff;
  box-shadow: 0px 1.2px 6px 0px rgba(3, 4, 71, 0.1),
    0px 8px 16px 0px rgba(3, 4, 71, 0.12);
  display: flex;
  flex-direction: column;
  gap: 16px;
  max-height: min(calc(var(--safeAreaHeight) - 48px), 748px);
  max-width: min(100vw - 48px, 1128px);

  .head {
    flex: 0 0 36px;
    color: #56565c;
    font-size: 24px;
    font-weight: 600;
    line-height: 36px;
    display: flex;
    align-items: center;

    .icon {
      fill: #3c3c4b;
      margin-right: 6px;
    }
  }
  .body {
    flex: 1 1 auto;
    display: flex;
    overflow: auto scroll;

    .rightPanel.isHidden {
      width: 0;
      height: 0;
      flex-basis: 0;
      overflow: hidden;
      opacity: 0;
    }
    .leftPanel .isHidden,
    .bottomPanel .isHidden {
      height: 0;
      flex-basis: 0;
      overflow: hidden;
      opacity: 0;
      margin: 0;
      padding: 0;
      border: 0;
    }
    .promptTipIcon {
      rect {
        fill: var(--Background-Color-Background, #f9f9fa);
      }
      path {
        fill: #56565c;
      }
      &:hover,
      &.active {
        rect {
          fill: #fcf4d3;
        }
        path {
          fill: #3021d9;
        }
      }
    }
    .leftPanel,
    .rightPanel {
      transition: transform 0s, width 0.2s, height 0.2s, flex-basis 0.2s,
        margin-left 0.2s;
      flex-shrink: 0;
      overflow-x: hidden;
      overflow-y: auto;
    }
    .leftPanel {
      width: 520px;
      max-width: 100%;
      display: flex;
      gap: 24px;
      flex-direction: column;
    }
    .rightPanel {
      width: 512px;
      max-width: 100%;
      .label {
        display: none;
      }
      .posePreviewLabel {
        display: flex;
      }
    }
    .bottomPanel {
      display: none;
    }
    .title {
      color: #1f1f1f;
      font-size: 18px;
      font-weight: 600;
      line-height: 24px;
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
    .promptTipsPopover {
      height: 28px;
    }
    .textarea-box {
      width: 100%;
      height: 96px;
      border-radius: 6px;
      background: #f1f4f9;
      position: relative;
      display: flex;
      flex-direction: column;
      &::after {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        border-radius: 6px;
        border: 1px solid rgba(0, 0, 0, 0.12);
        pointer-events: none;
      }
      &.focus::after {
        border: 2px solid #3021d9;
      }
      .textarea {
        border: none;
        width: 100%;
        flex: 1;
        padding: 8px 12px 0;
        background: transparent;
        resize: none;
        outline: none;
        overflow: hidden;
        overflow-y: auto;
        color: #1f1f1f;
        font-size: 14px;
        font-weight: 500;
        line-height: 22px;
      }
    }
    .prompt-length {
      padding: 0 8px 2px 0;
      display: flex;
      align-items: center;
      justify-content: flex-end;
      height: 16px;
      .circle {
        width: 10px;
        height: 10px;
        background: #43aa13;
        border-radius: 50%;
        margin-right: 4px;

        &.error {
          background: #aa1337;
        }
      }

      span {
        color: #56565c;
        text-align: right;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 18px;
      }
    }
    .label {
      display: flex;
      justify-content: space-between;
      align-items: center;
      color: #1f1f1f;
      font-size: 20px;
      font-weight: 600;
      line-height: 24px;
      height: 28px;
      margin-bottom: 12px;
      &.sub {
        font-size: 18px;
        font-weight: 500;
      }
      &.sceneSettingLabel {
        margin-top: 14px;
      }
      span {
        color: #737373;
        font-size: 16px;
        font-weight: 500;
      }
    }

    .tips {
      color: rgba(0, 0, 0, 0.38);
      font-size: 14px;
      font-weight: 400;
      line-height: 24px;
    }
    .searchInput {
      margin-bottom: 12px;
    }

    .suffixButton {
      padding: 0 12px;
      .arrowIcon {
        fill: #fff;
      }
      .credits {
        margin-left: 0;
        margin-top: 0px;
        padding: 0 4px;
        line-height: 19px;
      }
    }
    .poseWrap {
      display: grid;
      grid-template-columns: repeat(auto-fill, 90px);
      gap: 12px;
      justify-content: center;
      width: 100%;
      padding: 0 1px 1px 1px;
      .poseItem {
        flex: 0 0 88px;
        border: 0.962px solid #efefef;
        border-radius: 4px;
        border: 1px solid #efefef;
        transition: border-color 0.3s, box-shadow 0.3s;
        cursor: pointer;
        .poseImg {
          width: 88px;
          height: 88px;
          user-select: none;
        }

        &.selected {
          border: 1px solid #3021d9;
          box-shadow: 0 0 0 1px #3021d9;
        }
        &:hover {
          /* Shadow 3 */
          box-shadow: 0px 0.8px 4px 0px rgba(3, 4, 71, 0.08),
            0px 6px 12px 0px rgba(3, 4, 71, 0.1);
        }
        &.selected:hover {
          box-shadow: 0 0 0 1px #3021d9, 0px 0.8px 4px 0px rgba(3, 4, 71, 0.08),
            0px 6px 12px 0px rgba(3, 4, 71, 0.1);
        }
      }
    }
    .canvasWrap {
      --canvasWidth: 100%;
      --canvasHeight: 100%;
      width: 100%;
      aspect-ratio: 1/1;
      display: flex;
      flex-direction: column;
      border-radius: 2px;
      border: 1px solid
        var(--neutral-color-outline-light-24, rgba(0, 0, 0, 0.24));
      .canvas {
        width: var(--canvasWidth);
        height: var(--canvasHeight);
      }
      .canvasControl {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        gap: 4px;
        line-height: 1.25;
        font-size: 16px;
        color: #1f1f1f;
        height: 36px;
        padding: 0 8px;
        .canvasControlLabel {
          font-weight: 400;
        }
        .canvasControlValue {
          font-weight: 600;
        }
        .split {
          width: 2px;
          height: 20px;
          padding-left: 1px;
          margin: 0 9px;
          background-color: #cbcbcb;
          background-clip: padding-box;
        }
      }
    }
    .sceneSetting {
      margin-top: 8px;
      display: flex;
      gap: 16px;
      & > div {
        flex: 1 1 0;
      }
    }
  }

  .button-box {
    flex: 0 0 36px;
    display: flex;
    justify-content: flex-end;
    gap: 16px;
    .button {
      border-radius: 36px;
      padding: 12px 24px 14px 24px;
      cursor: pointer;
      display: flex;
      justify-content: center;
      align-items: center;
      height: 44px;

      &.cancel {
        width: 108px;
        height: 44px;
        border: 1px solid #3021d9;
        background: #fff;
        color: #3021d9;
        font-size: 18px;
        font-weight: 600;
      }

      &.regenerate {
        width: 308px;
        background: #3021d9;
        color: #f7f3f2;
        text-shadow: 0px 1px 0px rgba(0, 0, 0, 0.25);
        font-size: 18px;
        font-weight: 600;

        &:disabled {
          cursor: default;
          background: #ababab;
          color: #f7f3f2;
          text-shadow: 0px 1px 0px rgba(0, 0, 0, 0.25);
        }
      }
    }
  }
  .credits {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 4px;
    font-size: 14px;
    font-weight: 500;
    line-height: 21px;
    color: #e8e8eb;
    padding: 0 6px;
    border-radius: 4px;
    background: rgba(0, 0, 0, 0.16);
    margin-top: 2px;

    .icon {
      margin-right: 2px;
      margin-top: -1px;
      fill: #e7e7e7;
    }
  }
  // 展开 pose preview 时：
  &.isLarge {
    padding: 24px 36px;
    .body {
      .leftPanel {
        flex: 1;
      }
      .rightPanel {
        flex: 1;
        display: flex;
        flex-direction: column;
      }
    }
  }
}
:global {
  .selectWrap.isPopOverOpen {
    border: 1px solid #4c33ff;
  }
}
.selectWrap {
  display: flex;
  height: 36px;
  padding: 0px 8px;
  align-items: center;
  border-radius: 4px;
  background: #f1f4f9;
  user-select: none;
  border: 1px solid transparent;
  .selectWrapLabel {
    color: var(--Support-Color-Disable, #aaaaad);
    font-size: 14px;
    font-weight: 500;
    line-height: 1.42;
  }
  .selectValueWrap {
    flex-grow: 1;
    justify-content: flex-end;
    display: flex;
    padding: 0px 8px;
    align-items: center;
    gap: 4px;
    .selectValue {
      color: #1f1f1f;
      font-size: 14px;
      font-weight: 500;
      line-height: 1.42;
    }
    .selectValueIcon {
      fill: #aaaaad;
    }
  }
}
.radioContent {
  display: flex;
  padding: 16px 8px;
  flex-direction: column;
  align-items: flex-start;
  gap: 4px;

  border-radius: 8px;
  border: 2px solid #3021d9;
  background: #fff;
  width: 100%;
  /* Shadow 3 */
  box-shadow: 0px 0.8px 4px 0px rgba(3, 4, 71, 0.08),
    0px 6px 12px 0px rgba(3, 4, 71, 0.1);
  .radioItem {
    user-select: none;
    height: 40px;
    padding: 0 8px;
    align-items: center;
    gap: 8px;
    align-self: stretch;
    display: flex;
    border-radius: 8px;

    input {
      --outerWidth: 20px;
      width: var(--outerWidth);
      height: var(--outerWidth);

      border: none;
      cursor: pointer;
      position: relative;
      outline: none;
      &::after {
        content: '';
        position: absolute;
        left: -1px;
        top: -1px;
        width: var(--outerWidth);
        height: var(--outerWidth);
        background: #fff;
        border-radius: 50%;
        border: 2px solid rgba(0, 0, 0, 0.12);
        pointer-events: none;
      }

      &:checked::after {
        border-color: #3021d9;
      }

      &:checked::before {
        content: '';
        width: calc(var(--outerWidth) - 5px);
        height: calc(var(--outerWidth) - 5px);
        position: absolute;
        z-index: 2;
        left: 2px;
        top: 2px;
        right: 0;
        bottom: 0;
        margin: auto;
        background: #3021d9;
        border-radius: 50%;
      }
    }
    .label {
      color: var(--Text-Color-Primary-Text-Black, #15151f);
      font-size: 14px;
      font-weight: 500;
      line-height: 1.71429;
      flex: 1 1 0;
      cursor: pointer;
      line-height: 40px;
    }
    &:hover {
      background: #e2dfff;
    }
    &.selected {
      background: #e2dfff;
    }
    &.selected .label {
      color: #3021d9;
    }
  }
}
.promptTipsContentWrap {
  // width: 460px;
  padding: 12px;
  border-radius: 16px;
  border: 1px solid var(--neutral-color-divider-12, rgba(0, 0, 0, 0.12));
  background: #fffbea;
  display: flex;
  flex-direction: column;
  /* Shadow 2 */
  box-shadow: 0px 0.4px 2px 0px rgba(3, 4, 71, 0.08),
    0px 3px 6px 0px rgba(3, 4, 71, 0.1);
  @include xs-sm() {
    width: 285px;
  }
  .title {
    color: #000;

    font-size: 18px;
    font-weight: 500;
    line-height: 1.56;
    display: flex;
    justify-content: space-between;
    .tipIcon {
      rect {
        fill: transparent;
      }
      path {
        fill: #56565c;
      }
    }
  }
  .subTitle {
    color: var(--Text-Color-Secondary-Text-Black, #56565c);

    font-size: 12px;
    font-weight: 400;
    line-height: 2.33;
    margin-top: -4px;
  }
  .prompt {
    margin-top: 4px;
    padding: 4px;
    display: flex;
    justify-content: flex-start;
    gap: 6px;
    flex-wrap: wrap;

    color: var(--Text-Color-Primary-Text-Black, #15151f);
    font-size: 14px;
    font-weight: 500;
    line-height: 1.143;
    .promptItem {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: center;
      gap: 3px;
      --first-color: #a209af;
      --second-color: #307d0e;
      --third-color: #0060b9;
      .text {
        padding: 2px 4px;
        position: relative;
      }
      .desc {
        display: flex;
        flex-direction: column;
        align-items: center;
        svg {
          fill: black;
          fill-opacity: 0.24;
        }
        .textBox {
          display: flex;
          max-width: 88px;
          height: 36px;
          align-items: center;
          text-align: center;
          padding: 2px 4px;
          font-size: 12px;
          font-weight: 500;
          line-height: 1.17;
          border-radius: 4px;
          border: 1px solid var(--neutral-color-divider-12, rgba(0, 0, 0, 0.12));
        }
      }
      &:nth-child(1) {
        .text {
          border-bottom: 1px solid var(--first-color);
        }
        .textBox {
          color: var(--first-color);
        }
      }
      &:nth-child(2) {
        .text {
          border-bottom: 1px solid var(--second-color);
        }
        .textBox {
          color: var(--second-color);
        }
      }
      &:nth-child(3) {
        .text {
          border-bottom: 1px solid var(--third-color);
        }
        .textBox {
          color: var(--third-color);
        }
      }
    }
  }
  .footerBtn {
    padding: 0 12px;
    align-self: flex-end;
    margin-top: 12px;
  }
}

@include xs-sm {
  .container {
    max-height: calc(var(--safeAreaHeight) - 48px);
    .body {
      flex-direction: column;
      .leftPanel {
        flex: 0 0 auto;
        min-height: auto;
      }
    }
    &.isLarge {
      .body {
        gap: 24px;
        .leftPanel {
          flex: 0 0 auto;
          .poseContainer {
            display: none;
          }
        }
        .rightPanel {
          flex: 0 0 auto;
          width: 100%;
          .label {
            display: flex;
          }
          .posePreviewLabel {
            display: none;
          }
        }
        .bottomPanel {
          display: block;
        }
      }
    }
  }
}

@include sm {
  .container {
    &.isLarge {
      padding: 24px;
      .bottomPanel {
        display: block;
      }
    }
  }
}

@include xs {
  .container {
    width: 100vw;
    max-width: 100vw;
    height: calc(var(--safeAreaHeight) - 60px);
    padding: 24px 0;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    flex-direction: column;
    .head,
    .body,
    .button-box {
      padding: 0 16px;
    }
    .body {
      .leftPanel {
        width: 100%;
        transition: none;
      }
      .rightPanel {
        transition: none;
      }
    }
    .button-box {
      flex-direction: column-reverse;
    }
    &.isLarge {
      padding: 24px 0;
      .body {
        .sceneSetting {
          flex-direction: column;
        }
      }
    }
  }
}
