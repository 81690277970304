@import 'scss/abstracts/mixins';

.action-wrap {
  display: flex;
  height: 400px;
  flex: 1;
  width: 260px;
  padding: 16px;
  flex-direction: column;
  gap: 8px;
  flex-shrink: 0;
  border-radius: 8px;
  background: var(--Background-Color-Surface-Neutral-1, #f2f2f2);
  overflow: hidden;
  @include xs {
    height: auto;
    .action-wrap-btn {
      display: none;
    }
  }
  .action-wrap-title {
    color: #1f1f1f;

    /* medium title */
    font-size: 18px;
    font-weight: 500;
    line-height: 1.556;
  }
  .action-wrap-subTitle {
    color: #1f1f1f;
    font-size: 14px;
    font-weight: 400;
    line-height: 1.71; /* 171.429% */
  }
  .action-wrap-items-color {
    display: flex;
    flex-wrap: wrap;
    gap: 4px;
    .action-wrap-item-color {
      width: 40px;
      height: 40px;
      border-radius: 4px;
      box-sizing: border-box;
      background-color: var(--bgColor);
      cursor: pointer;
      &.action-wrap-item-color-active {
        border: 2px solid #3021d9;
      }
      &.action-wrap-item-color-transparent {
        background: repeating-conic-gradient(
          from 0deg,
          #e5e5e5 0deg 90deg,
          #d6d6d6 90deg 180deg
        );
        background-size: 20px 20px;
        background-origin: border-box;
      }
    }
  }
  .action-wrap-items-styleTransfer {
    display: flex;
    flex-wrap: wrap;
    gap: 6px;
    overflow-y: auto;
    margin-right: -10px;
    .action-wrap-item-styleTransfer {
      display: flex;
      flex-direction: column;
      cursor: pointer;
      width: 72px;
      position: relative;

      .action-wrap-item-styleTransfer-image {
        height: 72px;
        border-radius: 3px;
        overflow: hidden;
        box-sizing: border-box;
        background: var(--style-transfer-image) no-repeat center/contain
          border-box border-box;
        position: relative;
      }
      .action-wrap-item-styleTransfer-label {
        height: 20px;
        line-height: 20px;
        color: #1f1f1f;
        text-align: center;
        font-size: 10px;
        font-weight: 500;
      }
      &.action-wrap-item-styleTransfer-active {
        .action-wrap-item-styleTransfer-image {
          border: 2px solid #4906d7;
          box-shadow: inset 0px 0px 0px 1px #fff;
        }
      }
      &.action-wrap-item-styleTransfer-disabled {
        .action-wrap-item-styleTransfer-image::after {
          content: '';
          width: 100%;
          height: 100%;
          position: absolute;
          left: 0;
          top: 0;
          border-radius: 4px;
          z-index: 2;
          background: rgba(0, 0, 0, 0.3);
        }
      }
    }
    .locked {
      width: 16px;
      height: 16px;
      z-index: 3;
      position: absolute;
      left: 4px;
      top: 4px;
    }
  }
  .action-wrap-items-crop {
    display: flex;
    flex-wrap: wrap;
    gap: 6px;
    .action-wrap-item-crop {
      display: flex;
      width: 52px;
      padding: 4px 8px 0px 8px;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      border-radius: 6px;
      border: 1px solid var(--neutral-color-divider-12, rgba(0, 0, 0, 0.12));
      box-sizing: border-box;

      color: #1f1f1f;
      text-align: center;
      font-size: 12px;
      font-weight: 400;
      line-height: 2;
      user-select: no;
      cursor: pointer;
      &.action-wrap-item-crop-active {
        border: 2px solid #3021d9;
      }
      svg {
        width: 24px;
        height: 24px;
        fill: #56565c;
      }
    }
  }
  .action-wrap-btn {
    margin-top: auto;
    width: 100%;
  }
}

.credits {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 4px;
  font-size: 14px;
  font-weight: 500;
  line-height: 21px;
  color: #e8e8eb;
  padding: 0 6px;
  border-radius: 4px;
  background: rgba(0, 0, 0, 0.16);
  margin-top: 2px;

  .icon {
    margin-right: 2px;
    margin-top: -1px;
    fill: #e7e7e7;
  }
}
