// Copyright 2024 The SeedV Lab (Beijing SeedV Technology Co., Ltd.)
// All Rights Reserved.

.container {
  height: 28px;
  border-radius: 4px;
  background: #f1f4f9;
  display: inline-flex;
  align-items: center;

  .content {
    display: inline-flex;
    align-items: center;
    cursor: pointer;
    color: var(--Text-Color-Secondary-Text-Black, #56565c);
  }

  .icon {
    margin-right: 2px;
    width: 18px;
    height: 18px;
    fill: #56565c;
  }
  .checked {
    fill: #3021d9;
  }
  .disabled {
    fill: #a7a8ae;
    cursor: not-allowed;
  }

  .text {
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    font-family: Poppins, 'Noto Sans';
    user-select: none;
  }
}
