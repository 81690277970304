// Copyright 2024 The SeedV Lab (Beijing SeedV Technology Co., Ltd.)
// All Rights Reserved.

@import 'scss/abstracts/mixins';

.box {
  width: 100%;
  height: 100%;
  overflow-y: auto;
}

.header {
  width: 100%;
  height: 80px;
  position: absolute;
  top: 0;
  left: 0;

  .next {
    border-radius: 36px;
    background: #3021d9;
    color: #f7f3f2;
    display: flex;
    align-items: center;
    height: 44px;
    cursor: pointer;
    padding: 0 24px;
    text-shadow: 0px 1px 0px rgba(0, 0, 0, 0.25);
    font-size: 18px;
    font-weight: 600;

    .icon {
      fill: #f7f3f2;
      margin-left: 4px;
      width: 24px;
      height: 24px;
    }

    &:disabled {
      background: #ababab;
      cursor: default;
    }
  }
}

.container {
  max-width: 1220px;
  margin: 0 auto;
  padding: 104px 24px 0;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex-direction: row-reverse;
  gap: 12px;
  margin-bottom: 120px;
  .container-left {
    flex: 1 1 580px;
    border-radius: 16px;

    &.processing {
      background: rgba(255, 255, 255, 0.64);
    }
    &.processing-no-scenes {
      background: rgba(255, 255, 255, 0.16);
      padding: 0px;
      .titleBox {
        display: none;
      }
    }
    .titleBox {
      display: flex;
      flex-direction: column;
      gap: 4px;
      margin-bottom: 24px;
    }
    .container-left-box {
      border-radius: 16px;
      background: #fff;
      padding: 24px 24px 60px;
      &.scroll {
        padding-bottom: 24px;
      }
      @include xs {
        padding: 16px 16px 48px;
        &.scroll {
          padding-bottom: 16px;
        }
      }
      &.processing-no-scenes {
        padding: 0px;
      }
      &.processing {
        background: transparent;
      }
    }
  }
  @include xs-sm() {
    flex-direction: column;
    align-items: center;
    .container-left {
      flex: 0 0 auto;
      width: 100%;
      max-width: 640px;
    }
  }
  @include xs {
    padding: 126px 0 0;
  }
}
.credits {
  // width: 32px;
  display: flex;
  margin-left: 4px;
  font-size: 14px;
  font-weight: 400;
  line-height: 21px;
  color: var(--Text-Color-Secondary-Text-White, #e8e8eb);
  padding: 0 6px;
  border-radius: 4px;
  background: rgba(0, 0, 0, 0.16);
  .abandon {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    overflow: hidden;
    padding-right: 4px;

    .icon {
      margin-right: 0px;
      margin-top: -2px;
      fill: #e7e7e7;
      flex-shrink: 0;
      opacity: 0.7;
    }
    .abandonCost {
      opacity: 0.7;
    }
    &::after {
      content: '';
      position: absolute;
      top: 8px;
      left: 3px;
      right: 0;
      height: 2px;
      background-color: #fff;
      transform: rotate(28deg);
      opacity: 0.8;
    }
  }

  .cost {
    margin-left: 4px;
    color: var(--Text-Color-Secondary-Text-White, #e8e8eb);
  }
}
.back-to-top {
  margin-top: 28px;
  height: 68px;
}
.beta {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  height: 22px;
  padding: 0px 6px;
  border-radius: 4px;
  background: rgba(175, 168, 255, 0.5);

  color: #3021d9;
  font-size: 14px;
  font-weight: 500;
  line-height: 150%;

  margin-left: 4px;
  &.lightBg {
    background: #e8e8eb;
    color: #030447;
  }
}

:global {
  .new-tag {
    display: flex;
    height: 18px;
    padding: 0px 6px 1px;
    justify-content: center;
    align-items: center;
    gap: 2px;
    border-radius: 2px;
    background: rgba(175, 168, 255, 0.5);
    color: #3021d9;
    font-feature-settings: 'dlig' on;
    font-family: Outfit;
    font-size: 12px;
    font-weight: 500;
    line-height: 21px; /* 175% */
  }
  .new-tag-ml4 {
    margin-left: 4px;
  }
  .credit-tag {
    display: inline-flex;
    height: 19px;
    padding: 0px 4px 1px 4px;
    align-items: center;
    border-radius: 4px;
    background: rgba(0, 0, 0, 0.08);

    color: var(--Text-Color-Secondary-Text-Black, #56565c);
    font-feature-settings: 'dlig' on;
    font-family: Outfit;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;

    user-select: none;
    .credit-tag-slash {
      font-family: Outfit;
      font-size: 14px;
      font-weight: 400;
      line-height: 21px;
      width: 9px;
      text-align: center;
    }
    svg {
      fill: black;
      fill-opacity: 0.68;
      width: 14px;
    }
  }
  .credit-tag-ml2 {
    margin-left: 2px;
  }
}
