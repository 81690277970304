@import 'scss/abstracts/_mixins';

.projectContainer {
  width: 100%;
  border-radius: 6px;
  background: #fff;
  box-shadow: 0px 0.2px 1px 0px rgba(3, 4, 71, 0.08),
    0px 1.5px 3px 0px rgba(3, 4, 71, 0.1);
  cursor: pointer;
  overflow: hidden;
  transition: transform 0.3s ease-in-out;
  &:hover {
    box-shadow: 0px 0.8px 4px 0px rgba(3, 4, 71, 0.08),
      0px 6px 12px 0px rgba(3, 4, 71, 0.1);
    transform: scale(1.03);
  }
  .projectCard {
    display: flex;
    flex-direction: column;
    gap: 4px;
    position: relative;
    .cover {
      width: 100%;
      padding-bottom: 100%;
      flex-shrink: 0;
      overflow: hidden;
      position: relative;
      display: flex;
      justify-content: center;
      align-items: center;
      &.noImage {
        background-color: #efeff4;
      }
      .img {
        position: absolute;
        left: 0;
        top: 0;
        height: 100%;
        width: 100%;
        min-width: 100%;
        object-fit: cover;
        object-position: center;
        background: #fff;
      }
      .noImageSvg {
        fill: #a5a9ad;
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
      }
    }

    .badge {
      display: flex;
      height: 24px;
      padding: 0 8px;
      justify-content: center;
      align-items: center;
      gap: 10px;
      flex-shrink: 0;

      position: absolute;
      top: 4px;
      left: 4px;

      border-radius: 4px;
      background: #fde462;
    }
    .titleAndSubInfo {
      display: flex;
      flex-direction: column;
      padding: 6px 6px 6px 8px;
      gap: 2px;
    }
    .title_wrap {
      display: flex;
      align-items: flex-start;
      gap: 4px;
      cursor: pointer;
      max-width: 100%;
      height: 24px;
      .title {
        align-items: center;
        flex: 1 0 0;
        color: #1f1f1f;
        font-size: 14px;
        font-weight: 600;
        line-height: 1.714;
        @include text-overflow-ellipsis(1);
      }
      &.isRename {
        .more {
          display: none;
        }
      }
    }

    .prompt {
      color: var(--Text-Color-Secondary-Text-Black, #56565c);
      font-size: 14px;
      font-weight: 300;
      line-height: 1.42857;
      word-break: break-all;
      cursor: pointer;
    }
    .more {
      flex: 0 0 22px;
      fill: #333333;
      width: 22px;
      height: 22px;
      &:hover {
        fill: #554ed9;
      }
    }
    &.isGallery {
      .cover .img {
        border-radius: 6px;
      }
      .title_wrap {
        .title {
          color: #fff;
          font-size: 16px;
          font-weight: 600;
          line-height: 1.5;
        }
      }
      .prompt {
        height: 40px;
        color: var(--Text-Color-Secondary-Text-White, #e8e8eb);
        font-size: 14.42px;
        font-weight: 300;
        line-height: 1.42857;
        @include text-overflow-ellipsis(2);
      }
      .titleAndSubInfo {
        display: flex;
        flex-direction: column;
        position: absolute;
        padding: 4px 8px 0px 8px;
        bottom: 0px;
        width: 100%;
        max-width: 100%;
        height: 36px;
        overflow: hidden;
        border-radius: 4px;
        background: linear-gradient(
          180deg,
          rgba(0, 0, 0, 0) 0%,
          rgba(0, 0, 0, 0.57) 87.93%
        );
        transition: height 0.3s ease-in-out;
        & > div {
          max-width: 100%;
        }
        .prompt {
          opacity: 0;
        }
      }
      .titleMorePopoverContainer {
        position: absolute;
        right: 8.24px;
        top: 8.24px;
        z-index: 99;
        border-radius: 3px;
        visibility: hidden;
      }
      &:hover {
        box-shadow: 0px 0.8px 4px 0px rgba(3, 4, 71, 0.08),
          0px 6px 12px 0px rgba(3, 4, 71, 0.1);
        .titleAndSubInfo {
          height: 78px;
          .prompt {
            opacity: 1;
          }
        }
        .titleMorePopoverContainer {
          visibility: visible;
        }

        .more {
          background-color: rgba(255, 255, 255, 0.2);
          fill: white;
          border-radius: 4px;
          &:hover {
            fill: #554ed9;
          }
        }
      }
    }
  }
}

.project-title-popover {
  width: 240px;
  padding: 4px 6px;
  overflow: hidden;
  overflow-wrap: break-word;

  border-radius: 4px;
  background: #fff;

  /* Shadow 2 */
  box-shadow: 0px 0.4px 2px 0px rgba(3, 4, 71, 0.08),
    0px 3px 6px 0px rgba(3, 4, 71, 0.1);

  color: #1f1f1f;
  font-size: 16px;
  font-weight: 500;
  line-height: 1.25; /* 125% */
}
