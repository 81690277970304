@import 'scss/abstracts/_animates.scss';
$handleResizeColor: #60cb2f;
.container {
  display: flex;
  gap: 24px;
  .image {
    max-width: 100%;
    max-height: 100%;
  }
}
:global {
  .cropper-modal {
    background-color: #fff;
  }
}

.leftPanel {
  display: flex;
  width: 600px;
  height: 480px;
  border: 1px solid #f9f9f9;
  flex-grow: 1;
  position: relative;
  border-radius: 2px;
  border: 1px solid var(--neutral-color-divider-12, rgba(0, 0, 0, 0.12));
  padding: 15px 24px;
  overflow: hidden;
  background: rgba(0, 0, 0, 0.8);

  .sourceImg,
  .dragImage {
    max-width: 100%;
    max-height: 100%;
    height: 100%;
    width: 100%;
    object-fit: contain;
    user-select: none;
  }
  .dragContainer {
    position: absolute;
    opacity: 0;
    // border: 1px solid #fff;
    & > div {
      display: inline-block;
      border: 1px dashed $handleResizeColor;
      box-sizing: content-box;
      user-select: none;
    }
    &::before {
      content: '';
      position: absolute;
      inset: 0;
      border: 1px solid #fff;
      // z-index: 10;
    }
  }
  .dragImageWrap {
    position: absolute;
    overflow: hidden;
    .mask {
      position: absolute;
      width: 600px;
      height: 500px;
      opacity: 0;
      background: rgba(0, 0, 0, 0.6);
    }
  }
  .overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    // background: rgba(0, 0, 0, 0.8);
  }
}

.rightPanel {
  display: flex;
  width: 223px;
  flex-direction: column;
  justify-content: space-between;
  .top {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 24px;
    align-self: stretch;
  }
  .title {
    color: #1f1f1f;
    font-size: 18px;
    font-weight: 500;
    line-height: 28px;
    text-transform: capitalize;
  }
  .crop {
    display: flex;
    flex-direction: column;
    gap: 8px;
    align-self: stretch;
    color: #1f1f1f;
    width: 198px;
    font-size: 18px;
    font-weight: 500;
    line-height: 1.56;
    .options {
      color: #1f1f1f;
      font-size: 16px;
      font-weight: 400;
      line-height: 1.5;
      height: 32px;
      display: flex;
      align-items: center;
      gap: 8px;
      cursor: pointer;
      input {
        width: 24px;
        height: 24px;
      }
      label {
        cursor: pointer;
        flex-grow: 1;
      }
    }
  }
  .backgroundWrap {
    display: flex;
    flex-direction: column;
    gap: 8px;
    align-self: stretch;
    .btn {
      align-self: stretch;
      display: flex;
      justify-content: space-between;
      .btnRemove {
        width: 147px;
        & > svg {
          display: none;
        }
        &.btnRemove_removing,
        &.btnRemove_removed {
          background: #ababab;
          cursor: not-allowed;
          color: #f2f2f2;
          & > svg {
            display: block;
            width: 16px;
            margin-right: 4px;
            fill: #fff;
            animation: spin 2s linear infinite;
          }
        }
      }
    }
  }
  .sketchPickerWrap {
    margin-left: 38px;
    .colorInfoWrap {
      display: flex;
      align-items: center;
      gap: 4px;
      align-self: stretch;
      margin-bottom: 8px;
      .colorBlock {
        display: inline-block;
        width: 28px;
        height: 28px;
        border-radius: 4px;
      }
      .colorText {
        display: inline-flex;
        height: 28px;
        width: 96px;
        padding: 4px 8px;
        align-items: center;
        gap: 4px;
        border-radius: 3px;
        border: 1px solid
          var(--functional-color-divider-12, rgba(0, 0, 0, 0.12));
        color: var(--text-color-primary-text-87, rgba(0, 0, 0, 0.87));
        font-size: 14px;
        font-weight: 400;
        line-height: 1.142;
        .icon {
          color: var(--Text-Color-Secondary-Text-Black, #56565c);
        }
      }
    }

    & > div {
      box-shadow: none !important;
      padding: 0 !important;
      box-sizing: border-box !important;
      & > div:nth-child(3),
      & > div:nth-child(4) {
        display: none !important;
      }
      & > div:nth-child(1) {
        border-radius: 4px;
        padding-bottom: 100% !important;
      }
      & > div:nth-child(2) {
        background-color: #fff !important;
        margin-top: 8px;

        & > div:nth-child(1) {
          padding: 0 !important;
          & > div:nth-child(1) {
            height: 14px !important;
            border-radius: 5px;
          }
        }
      }
    }
    :global {
      .hue-horizontal > div:nth-child(2) > div {
        height: 14px !important;
        width: 14px !important;
        border-radius: 14px !important;
        border: 2px solid #fff !important;
        background: transparent !important;
        box-shadow: none !important;
        margin-top: 0px !important;
      }
    }
  }
}

.buttonGroup {
  display: flex;
  justify-content: space-between;
  & > button {
    width: 103.5px;
  }
}
:global {
  .handleResize,
  .react-resizable-handle {
    width: 12px;
    height: 12px;
    border-radius: 2px;
    background-color: $handleResizeColor;
    position: absolute;
    z-index: 2;
  }

  .handleResize-nw,
  .react-resizable-handle-nw {
    left: -6px;
    top: -6px;
    cursor: nw-resize;
  }

  .handleResize-ne,
  .react-resizable-handle-ne {
    right: -6px;
    top: -6px;
    cursor: ne-resize;
  }

  .handleResize-sw,
  .react-resizable-handle-sw {
    left: -6px;
    bottom: -6px;
    cursor: sw-resize;
  }

  .handleResize-se,
  .react-resizable-handle-se {
    right: -6px;
    bottom: -6px;
    cursor: se-resize;
  }
}
