.container {
  display: flex;
  flex-direction: column;
  gap: 4px;
  flex: 1 1 452px;
  .editorBox {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    .mask {
      content: '';
      position: absolute;
      background: rgba(255, 255, 255, 0.8);
      top: 8px;
      left: 4px;
      width: calc(100% - 8px);
      height: 51px;
      border-radius: 6px;
      &.focused {
        height: 50px;
      }
    }
  }
  .warning {
    color: #aa1337;
    font-size: 14px;
    font-weight: 400;
    line-height: 18px;
    display: flex;
    justify-content: flex-end;

    .warning-icon {
      width: 20px;
      height: 20px;
      fill: #f54f4a;
      margin-right: 4px;
    }

    .text {
      width: calc(100% - 24px);
      display: inline-block;
    }
  }
}
