.container {
  display: flex;
  flex-direction: column;
  border-radius: 16px;
  overflow: hidden;
  border: 1px solid var(--neutral-color-divider-12, rgba(0, 0, 0, 0.12));
  background: #fff;
  width: 280px;
  /* Shadow 1 */
  box-shadow: 0px 0.2px 1px 0px rgba(3, 4, 71, 0.08),
    0px 1.5px 3px 0px rgba(3, 4, 71, 0.1);
  cursor: pointer;
  .image-wrap {
    height: 140px;
    background: var(--image-url) no-repeat left / contain;
    position: relative;
    .icon {
      position: absolute;
      left: 16px;
      bottom: 11px;
      width: 48px;
      height: 48px;
      border-radius: 50%;
      background-color: #fff;
      display: flex;
      place-content: center;
    }
    &::before {
      content: '';
      width: 1px;
      background-color: #fff;
      height: 100%;
      position: absolute;
      left: 50%;
      top: 0;
      transform: translateX(-50%);
    }
    &::after {
      content: '';
      width: 6px;
      background-color: #fff;
      border-radius: 5px;
      height: 20px;
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translateX(-50%) translateY(-50%);
    }
  }
  .content {
    padding: 16px;
    display: flex;
    flex-direction: column;
    gap: 16px;
    .info {
      display: flex;
      flex-direction: column;
      gap: 4px;
      .title {
        color: var(--Text-Color-Primary-Text-Black, #15151f);
        font-size: 20px;
        font-weight: 600;
      }
      .sub-title {
        color: var(--neutral-color-overlay-68, rgba(0, 0, 0, 0.68));
        font-size: 12px;
        font-weight: 400;
      }
    }
  }
  &:hover {
    box-shadow: 0px 0.8px 4px 0px rgba(3, 4, 71, 0.08),
      0px 6px 12px 0px rgba(3, 4, 71, 0.1);
  }
}
