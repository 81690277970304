// Copyright 2024 The SeedV Lab (Beijing SeedV Technology Co., Ltd.)
// All Rights Reserved.

.preview-box {
  position: relative;
  overflow: hidden;
  border-radius: 4px;
  .preview {
    width: 100%;
    height: 100%;
  }
}
.layout-preview-avatar {
  position: absolute;
  // Fallback for Firefox
  width: var(--imgBoxWidth);
  // Modern browsers
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  img {
    width: 100%;
    height: auto;
  }
  &.figure-left,
  &.circle-left {
    left: 0;
  }
  &.figure-right,
  &.circle-right {
    right: 0;
  }
  &.circle-left,
  &.circle-right {
    border-radius: 50%;
    background: var(
        --avatar-back-color,
        linear-gradient(180deg, #ede8fd 0%, #dfd6ff 100%)
      )
      no-repeat center center;
    background-clip: content-box;
    padding: var(--paddingWidth);
    img {
      border-radius: 50%;
    }
  }
}
