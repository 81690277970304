// Copyright 2024 The SeedV Lab (Beijing SeedV Technology Co., Ltd.)
// All Rights Reserved.

.description {
  color: #56565c;
  font-size: 12px;
  font-weight: 500;
  line-height: 20px;

  &.disabled {
    color: #aaaaad;
  }
}

.link {
  color: #3021d9;

  &:hover,
  &:active,
  &:focus {
    color: #3021d9;
  }
}

.list {
  display: flex;
  justify-content: space-between;
  width: 100%;
  gap: 24px;
  margin-top: -2px;

  .item {
    // width: 89px;
    flex: 1;
    border-radius: 6px;
    background: #f1f4f9;
    padding: 4px 6px;
    border: 1px solid transparent;
    cursor: pointer;
    display: flex;
    justify-content: space-between;

    .left,
    .right {
      color: #030447;
      font-family: Outfit;
      font-size: 14px;
      font-weight: 400;
      line-height: 21px;
    }

    .left {
      display: flex;
      align-items: center;
    }

    &:hover {
      box-shadow: 0 0.2px 1px 0 #03044714, 0 1.5px 3px 0 #0304471a;
    }

    &.selected {
      border-color: #3021d9;
      box-shadow: none;
      cursor: default;
    }

    &.disabled {
      cursor: not-allowed;
      color: #aaaaad;

      .left {
        .icon {
          fill: #aaaaad;
        }
      }

      &:hover {
        box-shadow: none;
      }
    }
  }
  @media screen and (max-width: 710px) {
    flex-direction: column;

    .item {
      width: auto;
      flex: auto;
    }
  }
}

.button {
  height: 14px;
  font-size: 12px !important;
  font-weight: 500;
  padding: 6px 12px;
}

.tips {
  width: 100%;
  line-height: 1;
  margin-top: 12px;
  color: rgba(0, 0, 0, 0.68);
  font-size: 12px;
  font-weight: 500;
  padding: 6px 12px;
}
