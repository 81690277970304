// Copyright 2024 The SeedV Lab (Beijing SeedV Technology Co., Ltd.)
// All Rights Reserved.

@use 'sass:map';
@import 'scss/abstracts/mixins';
@import 'scss/abstracts/animates';
.container {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  @include sm {
    align-items: start;
    .dialog {
      top: 36px;
    }
  }

  @include xs {
    align-items: start;
    .dialog {
      animation: popupFromBottomToTop 0.3s;
      top: 60px;
    }
  }
  .dialog {
    position: relative;
    z-index: 1000;

    .close-button {
      position: absolute;
      z-index: 3;
      cursor: pointer;

      &.inside {
        top: 24px;
        right: 24px;
      }

      .close {
        width: 24px;
        height: 24px;
        fill: #1f1f1f;
      }
    }
  }
}
