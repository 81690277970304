// Copyright 2024 The SeedV Lab (Beijing SeedV Technology Co., Ltd.)
// All Rights Reserved.

.comingSoon {
  width: 30px;
  height: 68px;
  background: transparent;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #7d7d7d;
  font-family: Poppins;
  font-size: 12px;
  text-align: center;
  flex-shrink: 0;
  user-select: none;
  cursor: not-allowed;
  pointer-events: none;
}

.avatarWrapper {
  position: relative;
  width: 68px;
  height: 68px;
  flex-shrink: 0;
  cursor: pointer;
  transition: transform 0.4s ease;
  border-radius: 50%;
  background: var(
    --avatar-back-color,
    linear-gradient(180deg, #ede8fd 0%, #dfd6ff 100%)
  );
  overflow: hidden;

  &.none {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .icon {
    width: 24px;
    height: 24px;
    fill: rgba(255, 255, 255, 0.4);
  }
}
.selected {
  outline: 2px solid #3021d9;
  outline-offset: -2px;
}
.avatarImage {
  width: 100%;
  height: 100%;
  object-fit: cover;
  user-select: none;
  pointer-events: none;
}
