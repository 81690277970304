// Copyright 2024 The SeedV Lab (Beijing SeedV Technology Co., Ltd.)
// All Rights Reserved.
@use 'scss/abstracts/_animates.scss';
@import 'scss/abstracts/mixins';

.container {
  flex: 1 1 580px;
  position: sticky;
  top: 104px;
  right: 0;
  background: #fff;
  padding: 24px;
  border-radius: 16px;
  display: flex;
  flex-direction: column;
  align-items: center;
  z-index: 10;
  overflow: hidden;
  .previewContainer {
    display: flex;
    justify-content: center;
    width: calc(100% + 48px);
    margin-left: -24px;
    margin-right: -24px;
    position: relative;
    .arrowBtn {
      display: flex;
      width: 36px;
      height: 36px;
      justify-content: center;
      align-items: center;
      border-radius: 50%;
      border: 1px solid #3021d9;
      background: rgba(255, 255, 255, 0.6);
      position: absolute;
      top: calc(50% - 18px);
      user-select: none;
      cursor: pointer;
      // 需要在 image editor 之上，image editor z-index 为 2
      z-index: 3;
      &.left {
        left: 8px;
      }
      &.right {
        right: 8px;
      }
      &.disabled {
        cursor: not-allowed;
        border-color: #aaaaad;
        svg {
          fill: #aaaaad;
        }
      }
      &.hidden {
        display: none;
      }
      svg {
        fill: #3021d9;
        width: 20px;
        height: 20px;
      }
    }
  }

  @include xs-sm() {
    width: 100%;
    position: initial;
    max-width: 640px;
    flex: 0 0 auto;
    z-index: 0;
  }

  @include xs {
    padding: 16px;
    .previewContainer {
      .arrowBtn {
        &.left {
          left: 16px;
        }
        &.right {
          right: 16px;
        }
      }
    }
  }

  .title {
    margin-bottom: 12px;
    color: #1f1f1f;
    text-align: center;
    font-size: 18px;
    font-weight: 500;
    line-height: 24px;
  }

  .button-box {
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin-bottom: 12px;

    .button {
      height: 36px;
      border-radius: 36px;
      padding: 0;
      border: 1px solid #3021d9;
      background: transparent;
      color: #3021d9;
      font-size: 14px;
      font-weight: 500;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      font-family: Poppins;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 24px;

      .icon {
        fill: #3021d9;
        width: 20px;
        height: 20px;
        margin-right: 4px;
      }

      &.download,
      &.animate,
      &.regenerate,
      &.asset {
        width: calc((100% - 16px) / 2);
      }

      @include xs {
        font-size: 14px;
        font-weight: 500;
        height: 32px;
        &.download,
        &.animate,
        &.regenerate,
        &.asset {
          width: calc((100% - 12px) / 2);
          white-space: nowrap;
        }
      }

      &:disabled {
        border-color: #ababab;
        color: #ababab;
        cursor: default;
        .icon {
          fill: #ababab;
        }
        .beta {
          background: #e8e8eb;
          color: #ababab;
        }
        :global {
          .new-tag {
            background-color: #e8e8eb;
            color: #ababab;
          }
        }
      }

      &:not(:disabled):hover {
        border-color: #554ed9;
        color: #554ed9;
      }

      &:not(:disabled):active {
        border-color: #3021d9;
        color: #3021d9;
      }
    }

    &.first {
      margin-top: 24px;
    }

    &:last-of-type {
      margin-bottom: 0;
    }
  }

  &.processing {
    background: rgba(255, 255, 255, 0.64);
  }
}

.placeholder-container {
  flex: 1 1 580px;
  border-radius: 16px;
  background: rgba(255, 255, 255, 0.16);
  padding: 24px;

  .preview {
    width: 100%;
    height: 552px;
    border-radius: 4px;
    background: rgba(255, 255, 255, 0.54);
    margin-bottom: 16px;
  }

  .buttons {
    display: flex;
    justify-content: space-between;

    .button {
      flex: 1;
      height: 48px;
      border-radius: 2px;
      background: rgba(255, 255, 255, 0.54);
      margin-right: 16px;

      &:last-of-type {
        margin-right: 0;
      }
    }
  }

  @include xs-sm() {
    flex: 0 0 auto;
    width: 100%;
    max-width: 640px;
  }

  @include sm {
    .preview {
      height: 360px;
    }
  }

  @include xs {
    .preview {
      height: 280px;
    }
  }
}
.rotateLoading {
  display: block;
  width: 20px;
  margin-right: 4px;
  fill: none;
  --stroke: rgba(171, 171, 171, 1);
  animation: spin 2s linear infinite;
}
.credits {
  // width: 32px;
  display: flex;
  margin-left: 4px;
  font-size: 14px;
  font-weight: 400;
  line-height: 21px;
  color: var(--Text-Color-Secondary-Text-White, #e8e8eb);
  padding: 0 6px;
  border-radius: 4px;
  background: rgba(0, 0, 0, 0.16);
  .abandon {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    overflow: hidden;
    padding-right: 4px;

    .icon {
      margin-right: 2px;
      margin-top: -1px;
      fill: #e7e7e7;
      flex-shrink: 0;
    }
  }

  .cost {
    margin-left: 4px;
    color: var(--Text-Color-Secondary-Text-White, #e8e8eb);
  }
}
.beta {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  height: 22px;
  padding: 0px 6px;
  border-radius: 4px;
  background: rgba(175, 168, 255, 0.5);

  color: #3021d9;
  font-size: 14px;
  font-weight: 500;
  line-height: 150%;

  margin-left: 4px;
  &.lightBg {
    background: #e8e8eb;
    color: #030447;
  }
}
.regenerate-title {
  display: flex;
  gap: 6px;
  align-items: center;
  .regenerate-icon {
    fill: #56565c;
    width: 24px;
    height: 24px;
  }
}
.regenerate-content {
  width: 520px;
  @include xs {
    width: 100%;
  }
}
.regenerate-button-box {
  flex: 0 0 36px;
  display: flex;
  justify-content: flex-end;
  gap: 16px;
  .button {
    border-radius: 36px;
    padding: 12px 24px 14px 24px;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 44px;

    &.cancel {
      width: 108px;
      height: 44px;
      border: 1px solid #3021d9;
      background: #fff;
      color: #3021d9;
      font-size: 18px;
      font-weight: 600;
    }

    &.regenerate {
      width: 308px;
      background: #3021d9;
      color: #f7f3f2;
      text-shadow: 0px 1px 0px rgba(0, 0, 0, 0.25);
      font-size: 18px;
      font-weight: 600;

      &:disabled {
        cursor: default;
        background: #ababab;
        color: #f7f3f2;
        text-shadow: 0px 1px 0px rgba(0, 0, 0, 0.25);
      }
    }
  }
}
.credits {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 4px;
  font-size: 14px;
  font-weight: 500;
  line-height: 21px;
  color: #e8e8eb;
  padding: 0 6px;
  border-radius: 4px;
  background: rgba(0, 0, 0, 0.16);
  margin-top: 2px;

  .icon {
    margin-right: 2px;
    margin-top: -1px;
    fill: #e7e7e7;
  }
}
