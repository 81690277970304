// Copyright 2024 The SeedV Lab (Beijing SeedV Technology Co., Ltd.)
// All Rights Reserved.

.container {
  width: 380px;
  padding: 24px;
  border-radius: 16px;
  border: 4px solid #3021d9;
  background: #fff;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);

  .title {
    color: #1f1f1f;
    font-size: 18px;
    font-weight: 600;
    line-height: 24px;
  }

  .content {
    color: #1f1f1f;
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    margin: 16px 0;
  }

  .button {
    width: 100%;
    height: 44px;
    border-radius: 36px;
    background: #3021d9;
    color: #f7f3f2;
    text-shadow: 0px 1px 0px rgba(0, 0, 0, 0.25);
    font-size: 18px;
    font-weight: 600;
    cursor: pointer;
  }
}
