// Copyright 2024 The SeedV Lab (Beijing SeedV Technology Co., Ltd.)
// All Rights Reserved.

.collapse {
  background-color: #fff;
  border-radius: 16px;
  position: relative;
  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 16px;
    .title {
      display: flex;
      align-items: center;
      color: #1f1f1f;
      font-size: 20px;
      font-weight: 600;
      line-height: 28px;
    }
    .handler {
      width: 24px;
      height: 24px;
      display: flex;
      justify-content: center;
      align-items: center;
      .icon {
        transition: transform 0.3s;
        transform: rotate(90deg);
        &.open {
          transform: rotate(180deg);
        }
      }
    }
  }
  .hidden {
    display: none;
  }
  .content {
    padding: 3px 16px 16px;
  }
}
