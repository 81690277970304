// Copyright 2024 The SeedV Lab (Beijing SeedV Technology Co., Ltd.)
// All Rights Reserved.

.container {
  width: 380px;
  border-radius: 16px;
  border: 4px solid #3021d9;
  background: #fff;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  padding: 24px;

  .title {
    display: flex;
    align-items: center;

    .icon {
      fill: #1f1f1f;
      width: 24px;
      height: 24px;
      margin-right: 10px;
    }

    span {
      color: #1f1f1f;
      font-size: 18px;
      font-weight: 600;
      line-height: 24px;
    }
  }

  .content {
    margin: 16px 0;
    color: #1f1f1f;
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
  }

  .buttons {
    display: flex;
    justify-content: space-between;

    .button {
      height: 44px;
      border-radius: 36px;
      cursor: pointer;
    }

    .cancel {
      width: 108px;
      border: 1px solid #3021d9;
      background: #fff;
      color: #3021d9;
      font-size: 18px;
      font-weight: 600;
    }

    .yes {
      width: 208px;
      background: #3021d9;
      color: #f7f3f2;
      text-shadow: 0px 1px 0px rgba(0, 0, 0, 0.25);
      font-size: 18px;
      font-weight: 600;

      &.delete {
        background: #c90d40;
      }
    }
  }
}
