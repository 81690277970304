// Copyright 2025 The SeedV Lab (Beijing SeedV Technology Co., Ltd.)
// All Rights Reserved.

.signIn {
  display: flex;
  height: 54px;
  padding: 4px 16px;
  align-items: center;
  gap: 4px;
  align-self: stretch;
  border-radius: 6px;
  background: #ddd8fb;
  color: #1f1f1f;
  font-size: 14px;
  font-weight: 500;
  line-height: 28px;
  cursor: pointer;
}

.popover {
  width: 100%;
}
.select {
  display: flex;
  height: 54px;
  padding: 4px 16px;
  gap: 6px;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
  border-radius: 6px;
  background: #f7f7f7;
  border: 2px solid transparent;
  &.active {
    border-color: #3021d9;
  }
  .icon {
    width: 24px;
    height: 24px;
    flex-shrink: 0;
    stroke: #000000ad;
  }
}

.user {
  display: flex;
  overflow: hidden;
  align-items: center;
  gap: 12px;
  .avatar {
    flex: 0 0 auto;
    width: 36px;
    height: 36px;
    border-radius: 50%;
    object-fit: cover;
  }
  .name {
    color: #1f1f1f;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 28px;
    flex: 1;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}

.popoverContent {
  z-index: 1001;
  padding: 12px;
  flex-direction: column;
  gap: 6px;
  border-radius: 6px;
  border: 2px solid #3021d9;
}

.signOut {
  display: flex;
  height: 36px;
  align-items: center;
  gap: 12px;
  align-self: stretch;
  cursor: pointer;
  .icon {
    margin: 6px;
    width: 24px;
    height: 24px;
    flex-shrink: 0;
    fill: #000000ad;
  }
  .text {
    color: #1f1f1f;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 28px;
  }
}

.disabled {
  cursor: not-allowed;
}
