// Copyright 2024 The SeedV Lab (Beijing SeedV Technology Co., Ltd.)
// All Rights Reserved.

.container {
  position: relative;
  overflow: overlay;
  width: 100%;

  &::-webkit-scrollbar-corner {
    background-color: transparent;
  }

  > canvas,
  > .fill {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 2;
  }

  > .fill {
    z-index: 1;
  }
}
