// Copyright 2024 The SeedV Lab (Beijing SeedV Technology Co., Ltd.)
// All Rights Reserved.

@use 'scss/abstracts/_mixins' as mixins;

.container {
  height: 100%;
  background: linear-gradient(176deg, #fffcff 7.49%, #bdadff 95.07%);
  overflow-x: hidden;
  overflow-y: auto;
  padding-bottom: 106px;
  --color: #56565c;
  &.unLogin {
    background: var(
      --purple-pink,
      linear-gradient(180deg, #f0e7ff 0%, #d1b6fb 100%)
    );
  }
  &::-webkit-scrollbar-corner {
    background: transparent;
  }

  .body {
    padding: 0 40px;
    @include mixins.sm {
      padding: 0 24px;
    }
    @include mixins.xs() {
      padding: 0;
    }
  }

  .title {
    margin: 100px 0 52px;
    text-align: center;
    font-size: 36px;
    font-style: normal;
    font-weight: 600;
    line-height: 40px;
  }

  .selects {
    display: flex;
    margin-top: 12px;
    margin-bottom: 24px;
    width: unset;
    justify-content: space-between;
    .left {
      display: flex;
      flex-wrap: wrap;
      gap: 12px;
    }
  }

  .errors {
    &:empty {
      display: none;
    }
    .error {
      margin-bottom: 8px;
      font-size: 14px;
      font-weight: 400;
      line-height: 24px;
      color: #aa1337;
      overflow-wrap: break-word;

      .icon {
        width: 20px;
        height: 20px;
        fill: #f54f4a;
        margin-right: 4px;
        vertical-align: text-bottom;
      }
    }
  }

  .navigate {
    width: 648px;
    margin: auto;
    color: rgba(14, 29, 71, 0.68);
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    margin-bottom: 40px;
    margin-top: 16px;
    span {
      color: #3021d9;
      font-size: 14px;
      font-weight: 500;
      line-height: 20px;
      cursor: pointer;
      display: inline-block;
      align-items: center;
      position: relative;

      svg {
        width: 16px;
        height: 16px;
        fill: #3021d9;
        transform: translateY(3px);
      }
    }
  }
  .tabs {
    display: flex;
    gap: 24px;
    .tab-label {
      color: rgba(31, 31, 31, 0.4);
      font-size: 14px;
      font-weight: 600;
      line-height: 24px;
      cursor: pointer;
    }
    .tab-label-active {
      color: #1f1f1f;
    }
    .tab-label-default {
      cursor: default;
    }
  }
  .flex-container {
    display: flex;
    gap: 6px;
    @include mixins.xs {
      flex-direction: column;
    }
  }
  .flex-container-label {
    display: flex;
    gap: 4px;
    align-items: flex-start;
  }
  .flex-column-container {
    display: flex;
    flex-direction: column;
    gap: 6px;
  }
  .select-wrap {
    display: flex;
    flex-direction: column;
    gap: 4px;
    flex: 1 1 0;
    width: 100%;
    overflow: hidden;
    .select-label {
      color: #1f1f1f;
      font-size: 14px;
      font-weight: 600;
      line-height: 2;
      display: flex;
      align-items: center;
      gap: 4px;
    }
    .select-label-optional {
      color: var(--Text-Color-Secondary-Text-Black, var(--color));
      font-size: 14px;
      font-weight: 400;
      line-height: 2;
      display: inline-flex;
      align-items: center;
      .select-label-optional-icon {
        width: 16px;
        fill: #56565c;
      }
    }
    .upload-from-assets-library {
      position: absolute;
      right: 0px;
      display: flex;
      align-items: center;
      gap: 4px;
      cursor: pointer;
      color: var(--Text-Color-Secondary-Text-Black, #56565c);
      font-size: 14px;
      font-weight: 500;
    }
  }
  .style-title {
    height: 36px;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  &.content {
    background: linear-gradient(176deg, #fffcff 7.49%, #bdadff 95.07%);
  }
}

.quick-pace-span {
  display: inline-flex;
  line-height: 20px;
  height: 20px;
  cursor: pointer;
}
.quick-pace-span-icon {
  cursor: pointer;
  fill: #56565c;
  margin-left: 4px;
  width: 16px;
  height: 16px;
  position: relative;
  top: 2px;
}
.tip-content {
  width: 205px;
  padding: 6px 8px;
  border-radius: 4px;
  background: var(--Color, #fff);

  /* Shadow 1 */
  box-shadow: 0px 0.2px 1px 0px rgba(3, 4, 71, 0.08),
    0px 1.5px 3px 0px rgba(3, 4, 71, 0.1);

  color: #1f1f1f;
  font-size: 12px;
  font-weight: 400;
  line-height: 1.67;
}
.quick-pace-span-tip-content {
  @extend .tip-content;
  width: 280px;
}
.tip-content-vocabulary {
  @extend .tip-content;
  width: 280px;
}
.no-header {
  background-color: transparent;
  padding: 0px;
  border-top: 60px solid transparent;
  & > .no-header-wrapper {
    background-color: #fff;
    padding: 6px 24px 20px 24px;
    border-radius: 0 0 16px 16px;
  }
  .no-header-header {
    position: absolute;
    top: 0;
    left: 0px;
    width: 100%;
    transform: translateY(-100%);
    span {
      padding: 12px 0 4px 0;
    }
  }
  .tabs {
    gap: 0;
    .tab-label {
      padding: 0 24px;
      flex: 1 1 0;
    }
    .tab-label-active {
      background-color: #fff;
      border-radius: 16px 16px 0 0;
    }
  }
}
%respond-promptPage {
  .config-card .prompt-editor-container textarea {
    font-size: 18px;
  }
  .prompt-page-container .back-to-top {
    margin-top: 32px;
    margin-bottom: 0px;
    user-select: none;
  }
  @include mixins.respond-to-preGeneratePage('medium') {
    .prompt-box-container {
      width: 351px;
      .prompt-container-box {
        height: 236px;
        width: 294px;
        overflow: hidden;
      }
      .prompts-container {
        width: 294px;
      }
    }
  }
  @include mixins.respond-to-preGeneratePage('small') {
    .prompt-page-container {
      .page-title {
        font-size: 24px;
        margin-bottom: 32px;
        line-height: 28px;
      }
      .header-container {
        height: 80px;
        svg path:not(:last-child) {
          fill: none;
        }
      }
      .navigate {
        padding: 0 24px;
        display: flex;
        flex-direction: column;
        gap: 8px;
        margin-bottom: 0;
        & > span {
          color: #3021d9;
          svg {
            fill: #3021d9;
          }
        }
      }
    }
    .config-card {
      width: unset;
      .prompt-editor-container {
        textarea {
          font-size: 16px;
        }
      }
      .style-list .style-name {
        font-size: 12px;
      }
    }
    .prompt-box-container {
      width: 375px;
      .prompt-container-box {
        height: 236px;
        width: 294px;
      }
      .prompts-container {
        width: 294px;
      }
    }
    .select-container {
      flex-direction: column;
      align-items: flex-start;
    }
    .select-item {
      height: 44px;
      .ratio-list {
        display: flex;
        gap: 10px;
        .ratio-list-item {
          width: 80px;
          height: 150px;
          .ratio-list-preview {
            height: 90px;
            transform: scale(0.64);
            transform-origin: left;
          }
        }
      }
    }
  }
}
:global {
  @extend %respond-promptPage;
}
.fromAsset {
  user-select: none;
}
