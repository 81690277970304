@import 'scss/abstracts/mixins';

.container {
  display: flex;
  gap: 8px;
  align-self: stretch;
  flex-direction: row-reverse;
  .tool-container {
    display: flex;
    flex-direction: column;
    flex: 0 0 48px;
    .tool-item {
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 8px;
      cursor: pointer;
      height: 48px;
      width: 48px;
      svg {
        fill: #56565c;
        width: 25px;
      }
      &.active {
        background: var(--Primary-Primary-Container, #f4f0ff);
        svg {
          fill: #3021d9;
        }
      }
    }
  }
  &.isDisabled > div:nth-child(2) {
    filter: opacity(0.5);
    pointer-events: none;
  }
  @include xs-sm() {
    flex-direction: column;
    flex: 1;
    overflow: hidden;
    overflow-y: auto;
  }
}
.footer {
  display: flex;
  align-self: stretch;
  gap: 4px;
  padding-top: 16px;
  border-top: 1px solid rgba(0, 0, 0, 0.12);
  justify-content: flex-end;
  @include xs {
    flex-direction: column;
    border-top: none;
    gap: 12px;
    padding-top: 12px;
  }
}
.footer-reset {
  margin-right: auto;
}
.footer-Download {
}
%image-wrap {
  flex-shrink: 0;
  display: flex;
  width: 400px;
  height: 400px;
  padding: 24px;
  border-radius: 8px;
  background: var(--Background-Color-Surface-Neutral-1, #f2f2f2);
  overflow: hidden;
  justify-content: center;
  align-items: center;
  @include xs {
    width: 100%;
    height: 332px;
  }
  img {
    max-width: 100%;
    max-height: 100%;
  }
  .crop-image-wrap {
    max-width: 100%;
    max-height: 100%;
  }
}
.image-wrap {
  @extend %image-wrap;
}

$cropper-point-color: #745cff;
:global {
  .image-wrap {
    flex-shrink: 0;
    display: flex;
    width: 400px;
    height: 400px;
    padding: 24px;
    border-radius: 8px;
    background: var(--Background-Color-Surface-Neutral-1, #f2f2f2);
    overflow: hidden;
    justify-content: center;
    align-items: center;
    @include xs {
      width: 100%;
      height: 332px;
    }
  }
  .cropper-face {
    background-color: transparent;
  }
  .cropper-canvas > img {
    transition: filter 0.5s ease; /* 平滑过渡效果 */
    filter: brightness(0.3);
  }
  .cropper-modal {
    background-color: #fff;
    opacity: 0;
  }
  .cropper-view-box {
    outline: 2px solid $cropper-point-color;
  }
  .cropper-point.point-e,
  .cropper-point.point-w,
  .cropper-point.point-s,
  .cropper-point.point-n {
    background-color: #fff;
    border: 2px solid $cropper-point-color;
  }
  .cropper-point {
    opacity: 1 !important;
    border-radius: 3px;
    &.point-e {
      width: 8px !important;
      height: 20px !important;
      right: -5px;
      margin-top: -10px;
    }
    &.point-w {
      width: 8px !important;
      height: 20px !important;
      left: -5px;
      margin-top: -10px;
    }
    &.point-n {
      width: 20px !important;
      height: 8px !important;
      top: -5px;
      margin-left: -10px;
    }
    &.point-s {
      width: 20px !important;
      height: 8px !important;
      bottom: -5px;
      margin-left: -10px;
    }
  }
  .cropper-point.point-ne,
  .cropper-point.point-sw,
  .cropper-point.point-se,
  .cropper-point.point-nw {
    width: 24px !important;
    height: 24px !important;
  }
  .cropper-point.point-se {
    bottom: -4px !important;
    right: -5px !important;
    background: url(../../assets/svg/outline/Cropper-se.svg) no-repeat right
      bottom;
  }
  .cropper-point.point-ne {
    top: -4px !important;
    right: -5px !important;
    background: url(../../assets/svg/outline/Cropper-ne.svg) no-repeat right top;
  }
  .cropper-point.point-sw {
    bottom: -4px !important;
    left: -4px !important;
    background: url(../../assets/svg/outline/Cropper-sw.svg) no-repeat left
      bottom;
  }
  .cropper-point.point-nw {
    top: -4px !important;
    left: -4px !important;
    background: url(../../assets/svg/outline/Cropper-nw.svg) no-repeat left top;
  }
  .cropper-dashed {
    border: none !important;
  }
}

.btn {
  display: none;
  @include xs {
    display: inline-flex;
  }
}

.credits {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 4px;
  font-size: 14px;
  font-weight: 500;
  line-height: 21px;
  color: #3021d9;
  padding: 0 6px;
  border-radius: 4px;
  background: rgba(175, 168, 255, 0.5);
  margin-top: 2px;

  .icon {
    margin-right: 2px;
    margin-top: -1px;
    fill: #3021d9;
  }
}
