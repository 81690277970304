// Copyright 2024 The SeedV Lab (Beijing SeedV Technology Co., Ltd.)
// All Rights Reserved.

.container {
  padding: 16px 24px;
  background: #fff;
  border-radius: 16px;
  margin: 0 auto;
  box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.16);
  margin-bottom: 6px;
  max-width: 648px;
}
