// Copyright 2024 The SeedV Lab (Beijing SeedV Technology Co., Ltd.)
// All Rights Reserved.

#prompt-editor {
  .base-text {
    color: #1f1f1f;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
  }

  .limited-paragraph {
    padding: 0 4px;
    direction: inherit;
  }

  .error-text {
    color: #aa1337;
  }

  .error-paragraph {
    background: #aa133726;
    margin-top: 6px;
    border-radius: 4px;
  }
}
