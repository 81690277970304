// Copyright 2024 The SeedV Lab (Beijing SeedV Technology Co., Ltd.)
// All Rights Reserved.

@use 'scss/abstracts/_animates.scss';
@import 'scss/abstracts/mixins';

.container {
  position: relative;

  @include xs {
    padding: 4px;
  }

  .list-box {
    height: 100%;
    display: flex;
    flex-direction: column;
    gap: 12px;

    .animate-all {
      font-size: 14px;
      color: #56565c;
      display: flex;
      justify-content: space-between;
      align-items: center;
      .animate {
        display: flex;
        align-items: center;
        cursor: pointer;
        padding: 0 16px 0 12px;
        height: 32px;
        line-height: 32px;
        border-radius: 32px;
        border: 1px solid #3021d9;
        background: none;
        color: #3021d9;
        font-weight: 500;
        .beta {
          height: 18px;
          line-height: 18px;
          font-size: 12px;
          padding: 0 4px;
          background: #afa8ff80;
          border-radius: 4px;
          margin-left: 4px;
        }
        .icon {
          fill: #3021d9;
          width: 20px;
          height: 20px;
          margin-right: 2px;
        }
        &.gray {
          border-color: #ababab;
          color: #ababab;
          cursor: default;
          .icon {
            fill: #ababab;
          }
          .beta {
            background: #e8e8eb;
            color: #ababab;
          }
          :global {
            .new-tag {
              background-color: #e8e8eb;
              color: #ababab;
            }
          }
        }
        &:not(.gray):hover {
          border-color: #554ed9;
          color: #554ed9;
        }

        &:not(.gray):active {
          border-color: #3021d9;
          color: #3021d9;
        }
        .rotateLoading {
          display: block;
          width: 20px;
          margin-right: 4px;
          fill: none;
          --stroke: rgba(171, 171, 171, 1);
          animation: spin 2s linear infinite;
        }
      }
    }

    .button {
      margin-top: 48px;
      width: 103px;
      color: #3021d9;
      font-size: 18px;
      font-weight: 500;
      line-height: 24px;
      background: none;
      outline: none;
      border: none;
      cursor: pointer;
      margin-left: 50%;
      transform: translateX(-50%);
    }

    &.center {
      position: absolute;
      left: 0;
      top: 0;
      right: 0;
      bottom: 0;
      margin: auto;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }

  &.empty {
    margin-bottom: 48px;
  }
}

.placeholder-container {
  height: 664px;
  padding: 24px;
  border-radius: 16px;
  background: #ffffff29;

  .placeholder-scene {
    width: 100%;
    display: flex;
    margin-bottom: 34px;
  }

  .preview {
    width: 88px;
    height: 88px;
    margin-right: 12px;
    background: #ffffffa3;
  }

  .labels {
    width: calc(100% - 88px - 12px);

    li {
      width: 100%;
      margin-bottom: 12px;
      background: #ffffffa3;
      border-radius: 2px;
      height: 14px;

      &:last-child {
        width: 108px;
        margin-bottom: 0;
      }
    }
  }
}

.back-to-top {
  margin-top: 20px;
}
.beta {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  height: 22px;
  padding: 0px 6px;
  border-radius: 4px;
  background: rgba(175, 168, 255, 0.5);

  color: #3021d9;
  font-size: 14px;
  font-weight: 500;
  line-height: 150%;

  margin-left: 4px;
  &.lightBg {
    background: #e8e8eb;
    color: #030447;
  }
}
.credits {
  // width: 32px;
  display: flex;
  margin-left: 4px;
  font-size: 14px;
  font-weight: 400;
  line-height: 21px;
  color: var(--Text-Color-Secondary-Text-White, #e8e8eb);
  padding: 0 6px;
  border-radius: 4px;
  background: rgba(0, 0, 0, 0.16);
  .abandon {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    overflow: hidden;
    padding-right: 4px;

    .icon {
      margin-right: 2px;
      margin-top: -1px;
      fill: #e7e7e7;
      flex-shrink: 0;
    }
  }

  .cost {
    margin-left: 4px;
    color: var(--Text-Color-Secondary-Text-White, #e8e8eb);
  }
}
