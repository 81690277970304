// Copyright 2024 The SeedV Lab (Beijing SeedV Technology Co., Ltd.)
// All Rights Reserved.

@use 'scss/abstracts/_animates.scss';
@import 'scss/abstracts/mixins';

.container {
  width: 480px;
  @include xs {
    width: 300px;
  }

  .toastContent {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 12px;
    @include xs {
      flex-direction: column;
    }

    .text {
      color: #7d7d85;
      font-family: Poppins, 'Noto Sans';
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: 24px;
    }

    .right {
      .checkbox {
        background: transparent;
        margin-right: 4px;
        display: flex;
        transform: translateY(8px);
        color: #1f1f1f;

        span {
          font-size: 14px;
          font-weight: 500;
        }

        .content {
          align-items: start;
          color: #1f1f1f;
        }
      }
    }

    .button {
      width: 100%;
      z-index: 1;
      font-size: 14px;
      padding: 12px;
    }

    .load-box {
      transform: translateY(-20px);

      .loading {
        width: 30px;
        height: 30px;
        stroke: #a7a8ae;
        animation: spin 2s linear infinite;
      }
    }
  }
}
