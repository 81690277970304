// Copyright 2024 The SeedV Lab (Beijing SeedV Technology Co., Ltd.)
// All Rights Reserved.

.container {
  position: relative;

  .input {
    cursor: pointer;
    width: 16px;
    height: 16px;
    opacity: 0;
  }

  .icon {
    width: 24px;
    height: 24px;
    position: absolute;
    left: -4px;
    top: -4px;
    cursor: pointer;
    pointer-events: none;
  }
}
