// Copyright 2024 The SeedV Lab (Beijing SeedV Technology Co., Ltd.)
// All Rights Reserved.
@import 'scss/abstracts/_mixins';

.prompt-tag {
  display: flex;
  gap: 4px;
  height: 28px;
  .prompt-tag-box {
    flex: 1 1 0;
    position: relative;
    overflow: hidden;
    .prompt-tag-wrap {
      display: grid;
      gap: 4px;
      grid-template-columns: repeat(3, minmax(0, 1fr));
      @include xs {
        grid-template-columns: repeat(2, minmax(0, 1fr));
      }
      position: absolute;
      left: 0;
      right: 0;
      transition: transform 0.4s ease;
      .tag {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 28px;
        width: 100%;
        padding: 0px 8px 0px 10px;
        border-radius: 16px;
        background: var(--Background-Color-Background, #f9f9fa);
        cursor: pointer;
        &:hover {
          color: #3021d9;
        }
        .tag-content {
          max-height: 28px;
          color: var(--Text-Color-Secondary-Text-Black, #56565c);
          font-size: 12px;
          font-weight: 400;
          line-height: 14px;
          @include text-overflow-ellipsis(1);
        }
      }
    }
  }

  .prompt-tag-button {
    color: #3021d9;
    height: 28px;
    align-self: flex-end;
    padding: 6px 8px;
    flex-shrink: 0;
    font-size: 12px;
    font-weight: 500;
    &.rotate {
      svg {
        transition: transform 0.4s ease;
        transform: rotate(360deg);
      }
    }
  }
}
