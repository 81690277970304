// Copyright 2024 The SeedV Lab (Beijing SeedV Technology Co., Ltd.)
// All Rights Reserved.

.container {
  width: 380px;
  height: 236px;
  border-radius: 16px;
  border: 4px solid #3021d9;
  background: #fff;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  padding: 24px;

  .title {
    color: #1f1f1f;
    font-size: 18px;
    font-weight: 600;
    line-height: 24px;
  }

  .content {
    color: #1f1f1f;
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    margin: 16px 0;
  }

  .label {
    display: flex;
    cursor: pointer;

    .input {
      margin-left: 8px;
    }

    span {
      color: #1f1f1f;
      font-size: 16px;
      font-weight: 500;
      line-height: 24px;
      transform: translateY(-4px);
    }
  }

  .buttons {
    display: flex;
    justify-content: space-between;
    height: 44px;
    margin-top: 16px;

    .button {
      height: 44px;
      outline: none;
      border-radius: 36px;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;

      &.cancel {
        width: 108px;
        border: 1px solid #3021d9;
        background: #fff;
        color: #3021d9;
        font-size: 18px;
        font-weight: 600;
      }

      &.yes {
        width: 208px;
        background: #3021d9;
        color: #f7f3f2;
        text-shadow: 0px 1px 0px rgba(0, 0, 0, 0.25);
        font-size: 18px;
        font-weight: 600;
      }
    }
  }
}
