@import 'scss/abstracts/_mixins';
.container {
  display: inline-flex;
  height: 28px;
  padding: 0px 8px 0px 10px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 24px;
  cursor: pointer;
  background: transparent;

  color: var(--Text-Color-Secondary-Text-Black, #56565c);
  font-size: 12px;
  font-weight: 400;
  &:hover {
    background: rgba(236, 235, 255, 0.65);
  }
  &:focus {
    border-radius: 16px;
    background: #ecebff;
  }
}
.active {
  background: #ecebff;
  color: #1f1f1f;
}
