.animate-content {
  display: flex;
  flex-direction: column;
  gap: 8px;
  .option-wrap {
    display: flex;
    flex-direction: column;
    gap: 12px;
    align-items: flex-start;
    .disable-option {
      display: flex;
      align-items: center;
      cursor: not-allowed;
      color: #ababab;
      user-select: none;
      & > svg {
        width: 18px;
        height: 18px;
        fill: #ababab;
        margin-left: 1px;
        margin-right: 9px;
      }
    }
  }

  .animate-textarea-container {
    display: flex;
    padding: 8px 3px;
    flex-direction: column;
    gap: 4px;
    border-radius: 6px;
    border: 1px solid rgba(0, 0, 0, 0.12);
    background: #f1f4f9;
    height: 88px;
    .textarea {
      flex-grow: 1;
      border: none;
      background-color: transparent;
      font-size: 18px;
      color: #1f1f1f;
      resize: none;
      outline: none;
      padding: 0 9px;
      height: 52px;
      &::placeholder {
        color: var(--Object-Outline-Base, #a7a8ae);
        font-size: 16px;
        font-weight: 400;
      }
    }
    &:focus-within {
      border: 1px solid #3021d9;
    }
    .counter {
      margin-left: 8px;
      display: flex;
      align-items: center;
      align-self: flex-end;
      height: 16px;
    }
    .circle {
      width: 12px;
      height: 12px;
      border-radius: 50%;
      background-color: #43aa13;
      &.red {
        background-color: #aa1337;
      }
    }
    .counterText {
      color: var(--Text-Color-Secondary-Text-Black, #56565c);
      font-family: Outfit, 'Noto Sans';
      font-size: 14px;
      font-weight: 400;
      line-height: 24px; /* 171.429% */
      margin-left: 4px;
    }
  }
  .tip-icon {
    font-size: 0;
    line-height: 1;
    margin-left: 2px;
    svg {
      width: 16px;
      height: 16px;
      fill: #56565c;
    }
  }
}

.tip-content {
  display: flex;
  padding: 6px 8px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 4px;
  background: #fff;

  max-width: 290px;
  /* Shadow 1 */
  box-shadow: 0px 0.2px 1px 0px rgba(3, 4, 71, 0.08),
    0px 1.5px 3px 0px rgba(3, 4, 71, 0.1);
  color: #1f1f1f;
  font-size: 12px;
  font-weight: 400;
  line-height: 20px; /* 166.667% */
}
.disabled {
  color: #ababab;
  .disabled-credit-tag {
    color: var(--neutral-color-outline-38, rgba(0, 0, 0, 0.38));
  }
}
