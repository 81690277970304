// Copyright 2024 The SeedV Lab (Beijing SeedV Technology Co., Ltd.)
// All Rights Reserved.

@import 'scss/abstracts/mixins';

.header {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 80px;
  background: transparent;
  z-index: 11;

  .home {
    width: 44px;
    height: 44px;
    border-radius: 8px;
    background: #fff;
    position: relative;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;

    .icon {
      width: 26px;
      height: 26px;
      fill: #56565c;
    }
  }

  .progress {
    position: absolute;
    height: 12px;
    left: 0;
    top: 0;
    border-radius: 20px;
    background: #3021d9;
  }

  .title {
    height: 80px;
    font-size: 32px;
    font-weight: 600;
    line-height: 80px;
    color: #3021d9;
    text-align: center;
    @include text-overflow-ellipsis(1);
    padding: 0 170px;
  }

  .current {
    position: absolute;
    right: 57px;
    top: -10px;
    color: #3021d9;

    .number {
      font-size: 88px;
      font-weight: 500;
      margin-right: 8px;
      font-family: 'Outfit';
    }

    .unit {
      font-size: 42px;
      font-weight: 500;
      position: absolute;
      top: 12px;
    }

    .hint {
      position: absolute;
      top: 114px;
      right: -40px;
      width: 220px;
      padding: 12px;
      border-radius: 8px;
      background: #f1f4f9;
      color: #1f1f1f;
      font-size: 14px;
      font-weight: 400;
      filter: drop-shadow(0px 3px 6px rgba(3, 4, 71, 0.1))
        drop-shadow(0px 0.4px 2px rgba(3, 4, 71, 0.08));

      .icon {
        width: 20px;
        height: 12px;
        fill: #f1f4f9;
        position: absolute;
        left: 60%;
        top: -10px;
      }

      .bottom {
        text-align: center;
        margin-top: 6px;

        .got {
          color: #3021d9;
          font-size: 18px;
          font-weight: 500;
          line-height: 24px;
          cursor: pointer;

          &:hover {
            text-decoration: underline;
          }
        }
      }
    }
  }

  &.background {
    background: rgba(247, 247, 247, 0.92);
    box-shadow: 0px 0.2px 1px 0px rgba(3, 4, 71, 0.08),
      0px 1.5px 3px 0px rgba(3, 4, 71, 0.1);
  }
}
.popover {
  position: absolute;
  left: 40px;
  top: 18px;
}
.popover-container {
  z-index: 1002;
  background: none;
  box-shadow: none;
}
.popover-content {
  display: inline-flex;
  padding: 6px 8px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 4px;
  background: var(--Color, #fff);

  /* Shadow 1 */
  box-shadow: 0px 0.2px 1px 0px rgba(3, 4, 71, 0.08),
    0px 1.5px 3px 0px rgba(3, 4, 71, 0.1);
  color: #1f1f1f;
  font-family: Poppins;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 166.667% */
  position: relative;
  left: 40px;
}

@include xs {
  .header {
    .popover {
      left: 16px;
    }
    .title {
      font-size: 24px;
      padding: 0 76px;
    }
    .current {
      top: 15px;
      right: 28px;
      .number {
        font-size: 40px;
        margin-right: 1px;
      }
      .unit {
        font-size: 24px;
        top: 3px;
      }
    }
  }
}
.popover-container {
  display: inline;
}
