// Copyright 2024 The SeedV Lab (Beijing SeedV Technology Co., Ltd.)
// All Rights Reserved.

.label-icon {
  width: 24px;
  height: 24px;

  &.fill {
    fill: #aaaaad;
  }

  &.stroke {
    stroke: #aaaaad;
  }
}

.list {
  display: flex;
  flex-wrap: wrap;
  margin: -3px;
}
