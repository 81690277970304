// Copyright 2024 The SeedV Lab (Beijing SeedV Technology Co., Ltd.)
// All Rights Reserved.

.container {
  width: 100%;
  height: 100%;
  background: #f7f7f7;
  overflow: hidden;
  position: relative;

  .children {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 2;
  }

  &.processing {
    background: linear-gradient(90deg, #e5dfff 0%, #f9dcff 100%);
  }
}
