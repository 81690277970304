// Copyright 2024 The SeedV Lab (Beijing SeedV Technology Co., Ltd.)
// All Rights Reserved.

@use 'scss/abstracts/_mixins' as mixins;

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0px 60px 64px 40px;
  gap: 36px;
  flex-grow: 1;
  overflow-y: auto;

  @include mixins.xs {
    padding: 0 16px 64px;
  }

  .head {
    width: 100%;
    max-width: 1556px;
    margin: 0 auto;
    display: flex;
    gap: 24px;

    @include mixins.respond-to('medium') {
      flex-direction: column;
      max-width: 507px;
    }
  }
}

.tip-content {
  width: 240px;
  color: #1f1f1f;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
}

.info-icon {
  width: 16px;
  height: 16px;
  fill: #56565c;
  margin-left: 4px;
}

.tip-content {
  width: 240px;
  color: #1f1f1f;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
}

.containerTitle {
  color: #333;
  font-size: 36px;
  font-weight: 600;
  line-height: 1.11;
  padding-top: 80px;
}
.toggleSwitch {
  flex-shrink: 0;
}
.planOptionWrap {
  display: flex;
  justify-content: center;
  gap: 24px;
  padding: 0;
  align-self: stretch;
  grid-template-columns: repeat(auto-fit, 330px);
  .planItem {
    display: flex;
    width: 330px;
    padding: 36px 34px;
    flex-direction: column;
    gap: 36px;
    border-radius: 16px;
    background: rgba(255, 255, 255, 0.8);
    /* Shadow 1 */
    box-shadow: 0px 0.2px 1px 0px rgba(3, 4, 71, 0.08),
      0px 1.5px 3px 0px rgba(3, 4, 71, 0.1);
    border: 2px solid transparent;
    box-sizing: padding-box;
    min-width: 330px;
    max-width: 504px;
    flex: 1 1 330px;
    .top {
      display: flex;
      flex-direction: column;
      gap: 16px;
      .title {
        color: var(--Text-Color-Primary-Text-Black, #15151f);
        font-size: 20px;
        font-weight: 600;
        line-height: 1.4;
        display: flex;
        justify-content: space-between;
        .flag {
          display: flex;
          height: 28px;
          padding: 6px 12px;
          justify-content: center;
          align-items: center;
          border-radius: 2px;
          background: #43aa13;

          color: var(--Primary-Primary-Container, #f4f0ff);
          text-align: center;
          font-size: 16px;
          font-weight: 500;
          line-height: 1.75;
        }
      }

      .price {
        .priceText {
          display: flex;
          align-items: center;
          gap: 8px;
          .perMonthCharge {
            color: #333;
            font-size: 46px;
            font-weight: 600;
            line-height: 0.87;
            font-family: Outfit, 'Noto Sans';
          }
          .append {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            color: var(--Text-Color-Secondary-Text-Black, #56565c);
            text-align: center;

            font-size: 14px;
            font-weight: 500;
            line-height: 2;
            span:last-child {
              margin-top: -6px;
            }
            .sourcePrice {
              font-size: 21px;
              color: #aaa;
              font-family: Outfit, 'Noto Sans';
              font-weight: 600;
              line-height: 1;
              text-decoration: line-through;
            }
          }
        }
      }

      .creditsInfo {
        .creditsPerMonth {
          color: #3021d9;
          font-size: 14px;
          font-weight: 500;
          line-height: 2;
        }
        .tip {
          color: var(--Text-Color-Secondary-Text-Black, #56565c);
          font-size: 12px;
          font-weight: 400;
          line-height: 2.33;
          margin-top: -8px;
          display: flex;
          align-items: center;
          gap: 4px;
        }
      }
    }
    .bottomTips {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: 16px;
      align-self: stretch;
      .tipItem {
        display: flex;
        align-items: center;
        gap: 4px;
        align-self: stretch;
        line-height: 2;
        height: 28px;
        .icon {
          fill: #21bf7a;
          flex-shrink: 0;
          &.notAvailable {
            fill: #a90001;
          }
        }
        .textWrap {
          display: flex;
          align-items: center;

          .icon-box {
            line-height: 1;
            transform: translateY(2px);
          }

          .info-icon {
            width: 16px;
            height: 16px;
            fill: #56565c;
            margin-left: 4px;
          }
        }
        .text {
          color: var(--Text-Color-Primary-Text-Black, #15151f);
          font-size: 14px;
          font-weight: 500;
          display: flex;
          align-items: center;
        }
        .strongText {
          color: var(--Text-Color-Primary-Text-Black, #15151f);
          font-size: 14px;
          font-weight: 700;
          margin: 0 3px;
          white-space: nowrap;
        }
        .thinText {
          color: var(--Text-Color-Secondary-Text-Black, #56565c);
          font-size: 12px;
          font-weight: 400;
        }
        .colorText {
          color: #3021d9;
          font-size: 14px;
          font-weight: 500;
          margin-right: 3px;
        }
        .tipIcon {
          fill: #9e9e9e;
          margin-left: 8px;
        }
      }
    }
    &:hover {
      background: rgba(255, 255, 255, 1);
      box-shadow: 0px 0.8px 4px 0px rgba(3, 4, 71, 0.08),
        0px 6px 12px 0px rgba(3, 4, 71, 0.1);
    }
    &.active {
      background: linear-gradient(
        to bottom,
        #b133ff 0%,
        #4232ff 30%,
        #55008a 100%
      );
      box-shadow: 0px 0.8px 4px 0px rgba(3, 4, 71, 0.08),
        0px 6px 12px 0px rgba(3, 4, 71, 0.1);
      position: relative;
      border: none;

      > * {
        position: relative;
        z-index: 2;
      }

      &::after {
        content: '';
        width: calc(100% - 10px);
        height: calc(100% - 10px);
        position: absolute;
        left: 5px;
        top: 5px;
        background: #fff;
        border-radius: 11px;
        z-index: 0;
      }
    }
  }
  @include mixins.respond-to('medium') {
    flex-direction: column;
    align-items: center;
    .planItem {
      width: 100%;
      margin: 0 auto;
    }
  }
}

.tipContent {
  display: flex;
  padding: 16px 6px;
  flex-direction: column;
  gap: 6px;
  border-radius: 8px;
  background: #f1f4f9;
  .tipItem {
    display: flex;
    align-items: center;
    gap: 4px;
    color: var(--Text-Color-Primary-Text-Black, #15151f);
    font-size: 14px;
    font-weight: 400;
    line-height: 1.428;
    position: relative;
    .dotIcon {
      padding: 0 10px;
    }
  }
}
.downgradeToast {
  width: 396px;

  button {
    max-width: 203px;
    white-space: nowrap;
  }
}
