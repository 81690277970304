// Copyright 2023 The SeedV Lab (Beijing SeedV Technology Co., Ltd.)
// All Rights Reserved.

@use 'sass:map';

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  font-family: $default-font-family;

  &::-webkit-scrollbar {
    width: 6px;
    height: 6px;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 10px;
    box-shadow: inset 0 0 5px transparent;
    background: transparent;
    cursor: default;
  }

  &:hover {
    &::-webkit-scrollbar-thumb {
      background: #a5a9ad;
    }
  }
}

@-moz-document url-prefix() {
  * {
    scrollbar-width: thin;
  }
}

button {
  user-select: none;
}

html,
body,
#root {
  height: 100%;
  overflow: hidden;
  // background-color: $primary-container;
}

body,
button,
input,
select,
textarea {
  font-family: $default-font-family;
}

li {
  list-style: none;
}

img {
  display: block;
}

a {
  text-decoration: none;
}

input {
  border-style: solid;
}

button {
  border: none;
}
