// Copyright 2023 The SeedV Lab (Beijing SeedV Technology Co., Ltd.)
// All Rights Reserved.

@use 'scss/abstracts/_variables';
@import 'scss/abstracts/_mixins';

.title {
  color: #000;
  font-size: 28px;
  font-weight: 600;
}

.content-container {
  width: 300px;
  display: flex;
  flex-direction: column;
  .formContainer {
    display: flex;
    flex-direction: column;
    gap: 12px;
    .btnContainer {
      user-select: none;
      display: flex;
      height: 40px;
      padding: 8px 16px 8px 12px;
      justify-content: center;
      align-items: center;
      gap: 6px;
      align-self: stretch;
      border-radius: 36px;
      border: 1px solid var(--Text-Color-Secondary-Text-Black, #56565c);
      cursor: pointer;

      &.disabled {
        border: 1px solid var(--Text-Color-Secondary-Text-Black, #56565c) !important;
        cursor: not-allowed;
        .iconDiscord {
          fill: #ababab;
        }
        .text {
          color: #ababab;
        }
      }

      .icon {
        width: 16.7px;
        height: 16.7px;
      }
      .iconDiscord {
        width: 20px;
        height: 20px;
        fill: #4906d7;
      }
      .text {
        color: #1f1f1f;
        font-size: 14px;
        font-weight: 500;
        line-height: 20px;
      }
      &:hover,
      &:active {
        border: 1px solid #3021d9;
      }
    }
    .splitLine {
      display: flex;
      height: 48px;
      align-items: center;
      gap: 8px;
      align-self: stretch;
      color: var(--Text-Color-Secondary-Text-Black, #56565c);

      .line {
        height: 1px;
        flex: 1 0 0;
        background: #d9d9d9;
      }
    }
  }
  .to_login,
  .to_signup,
  .reset_email {
    color: #1f1f1f;
    font-size: 14px;
    font-weight: 400;
    display: flex;
    gap: 10px;
    margin-top: 16px;

    .link {
      color: var(--Primary-Primary-Color, #4c33ff);
    }
    .disabled {
      color: #ababab;
    }
  }
  .check-email {
    width: 100%;
  }
  .confirm-info {
    font-size: 14px;
    font-weight: 400;
    span {
      margin-right: 10px;
    }
  }
}

.auth_form_container {
  display: flex;
  flex-direction: column;
  gap: 12px;

  .agree {
    color: var(--Text-Color-Secondary-Text-Black, #56565c);
    font-size: 14px;
    font-weight: 400;
    line-height: 22px;
    margin-bottom: 24px;

    a {
      text-decoration-line: underline;
    }
  }

  .error_message {
    color: #d41549;
    font-size: 14px;
    font-weight: 400;
    line-height: 24px;
    align-self: stretch;
    @include text-overflow-ellipsis(2);
  }
  .forgotPassword {
    color: var(--Text-Color-Secondary-Text-Black, #56565c);
    font-size: 12px;
    font-weight: 500;
    line-height: 16px;
    cursor: pointer;
  }
  .signupBox,
  .loginBox,
  .resetPasswordBox,
  .doResetPasswordBox {
    display: flex;
    flex-direction: column;
    padding: 36px 0 0 0;
  }

  .signupBox {
    padding: 0;
  }
}
.input {
  width: 100%;
  height: 32px;
  border: none;
  border-radius: variables.$border-radius-6;
}

.error_message {
  color: variables.$error;
}

.check-email-content {
  color: #1f1f1f;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  margin-bottom: 64px;
}
