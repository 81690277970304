// Copyright 2024 The SeedV Lab (Beijing SeedV Technology Co., Ltd.)
// All Rights Reserved.

.container {
  border-radius: 6px;
  border: 2px solid #3021d9;
  background: #fff;

  padding: 24px;
  width: 100%;
  max-height: inherit;
  overflow-y: auto;
}
