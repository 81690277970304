// Copyright 2024 The SeedV Lab (Beijing SeedV Technology Co., Ltd.)
// All Rights Reserved.

@use 'scss/abstracts/_animates.scss';

.loading-info {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 36px;

  color: #1f1f1f;
  text-align: center;
  font-size: 16px;
  font-weight: 500;
  line-height: 1.5;
  justify-content: center;
  width: 100%;
}
.rotateLoading {
  display: block;
  width: 24px;
  fill: none;
  --stroke: #15151f;
  animation: spin 2s linear infinite;
}
