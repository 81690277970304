.option-list {
  font-size: 16px;
  .option-item {
    margin-bottom: 12px;
    position: relative;
    &:last-of-type {
      margin-bottom: 0;
    }
  }
}

.customizeOption {
  margin-top: 6px;
  display: flex;
  flex-direction: column;
  gap: 6px;
  .item {
    display: flex;
    height: 32px;
    padding: 8px 12px 8px 36px;
    align-items: center;
    gap: 6px;
    align-self: stretch;
    .prefix {
      color: #666;
      font-family: Poppins;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 20px; /* 142.857% */
    }
    .input {
      flex: 1;
      border: none;
      border-bottom: 1px solid #0000003d;
      outline: none;
      caret-color: #4c33ff;
      border-radius: 0;

      &:focus {
        border-color: #4c33ff;
      }

      &:disabled {
        background: none;
      }
    }
  }
}
