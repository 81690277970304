// Copyright 2024 The SeedV Lab (Beijing SeedV Technology Co., Ltd.)
// All Rights Reserved.

.input {
  display: block;
  height: 33px;
  line-height: 33px;
  border: 1px solid #0000003d;
  border-radius: 4px;
  font-size: 14px;
  font-weight: 400;
  overflow: hidden;
  background: none;
  color: #000000;

  &.empty:not(.focus, .error) {
    background: #f2f2f2;
    color: #aaaaad;
    border-color: transparent;
    input::placeholder {
      color: #aaaaad;
    }
  }

  &.error {
    border-color: #d44747;
  }

  &.focus {
    border-color: #3021d9;
  }

  input {
    width: 100%;
    height: 100%;
    border: none;
    outline: none;
    appearance: none;
    display: block;
    padding: 0 12px;
    background: none;
  }
}

.error-msg {
  font-size: 12px;
  font-weight: 500;
  line-height: 18px;
  color: #d44747;
}
