// Copyright 2024 The SeedV Lab (Beijing SeedV Technology Co., Ltd.)
// All Rights Reserved.

@use 'scss/abstracts/_mixins' as mixins;

.flex-container {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(98px, 1fr));
  gap: 12px;
  & > div {
    width: 100%;
  }
}
.vocabulary-input {
  height: 32px;
  width: 100%;
  padding: 6px 8px 6px 2px;
  display: flex;
  align-items: center;
  flex: 1 1 0;
  min-width: 0;
  font-size: 12px;
  font-weight: 400;
  color: #aaaaad;
  border-radius: 0;
  &::placeholder {
    color: rgba(0, 0, 0, 0.24);
  }
  line-height: 2;
  border: none;
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
  background: transparent;
  @include mixins.text-overflow-ellipsis(1);
  appearance: none;
  outline: none;
  &:focus {
    border-bottom: 1px solid #3021d9;
    color: #1f1f1f;
  }
  &.with-value {
    color: #1f1f1f;
    font-size: 14px;
  }
}
.tip-container {
  width: 205px;
  padding: 6px 8px;
  border-radius: 4px;
  background: var(--Color, #fff);
  word-break: break-all;

  /* Shadow 1 */
  box-shadow: 0px 0.2px 1px 0px rgba(3, 4, 71, 0.08),
    0px 1.5px 3px 0px rgba(3, 4, 71, 0.1);

  color: #1f1f1f;
  font-size: 12px;
  font-weight: 400;
  line-height: 1.67;
}
