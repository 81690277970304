@use 'scss/abstracts/_mixins' as mixins;
@import 'scss/abstracts/_animates';

.container {
  height: 100%;
  width: 100%;
  background: var(
    --purple-pink,
    linear-gradient(180deg, #f0e7ff 0%, #d1b6fb 100%)
  );
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.25);
  overflow: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  .header {
    display: flex;
    justify-content: space-between;
    height: 72px;
    align-self: stretch;
    padding: 12px 24px;
    .logo {
      height: 48px;
      width: auto;
    }
    .right {
      display: flex;
      gap: 10px;
      align-items: center;
      .login {
        padding: 12px 16px 12px 12px;
        font-weight: 500;
        font-family: Outfit, 'Noto Sans';
      }
      .signUp {
        font-family: Outfit, 'Noto Sans';
        font-size: 14px;
        font-weight: 600;
        color: #1f1f1f;
        border-color: #1f1f1f;
        padding-top: 10px;
        @include mixins.mixin-ghostBtn-withStatus(#3021d9, #3021d9);
      }
    }
  }

  .content-box {
    margin-top: 48px;
    display: flex;
    flex-direction: column;
    gap: 60px;
    align-items: center;
    .content-box-title {
      font-family: Outfit, 'Noto Sans';
      color: #1f1f1f;
      text-align: center;
      font-size: 60px;
      font-weight: 400;
    }
    .content-tasks-box-list {
      display: flex;
      flex-direction: column;
      gap: 15px;
      .content-tasks-box-list-item {
        display: flex;
        gap: 8px;
        align-items: center;
        color: #717171;

        .content-tasks-box-list-item-icon {
          width: 16px;
          height: 16px;
          fill: #717171;
        }
        .content-tasks-box-list-item-text {
          font-size: 20px;
          font-weight: 400;
          font-family: Outfit, 'Noto Sans';
        }
        &.done {
          color: #1f1f1f;
          .content-tasks-box-list-item-icon {
            fill: #1f1f1f;
          }
        }
        &.active {
          color: #4c33ff;
          .content-tasks-box-list-item-icon {
            fill: #4c33ff;
          }
        }
      }
    }
  }
  .check-button {
    box-shadow: 0px 0.8px 4px 0px rgba(3, 4, 71, 0.08),
      0px 6px 12px 0px rgba(3, 4, 71, 0.1);
    backdrop-filter: blur(50px);
    font-weight: 600;
    font-family: Outfit, 'Noto Sans';
    padding-top: 10px;
  }
  @include mixins.respond-to-preGeneratePage('medium') {
    .header .right {
      .login {
        display: none;
      }
      .menu {
        display: block;
        fill: rgba(81, 81, 81, 1);
        margin-left: -1px;
        cursor: pointer;
      }
    }
  }
  @include mixins.respond-to-preGeneratePage('small') {
    .header .right {
      .login {
        display: none;
      }
    }
    svg.logo {
      path:not(:last-child) {
        fill: none;
      }
    }
  }
}
.rotateLoading {
  display: block;
  width: 16px;
  fill: #4c33ff;
  --stroke: #4c33ff;
  animation: spin 2s linear infinite;
}
