// Copyright 2024 The SeedV Lab (Beijing SeedV Technology Co., Ltd.)
// All Rights Reserved.

@import 'scss/abstracts/mixins';

.container {
  display: flex;
  height: 100%;
  gap: 12px;
  flex-direction: row-reverse;

  .left {
    padding: 16px 0;
    display: flex;
    flex-direction: column;
    gap: 12px;
    background: #f7f7f7;
    border-radius: 4px;
    flex: 1;
    .left-scroll {
      overflow: auto;
      height: 100%;
      padding: 0 16px;
    }
    .title-box {
      color: #1f1f1f;
      width: 100%;
      height: 36px;
      display: flex;
      justify-content: space-between;

      .title {
        width: calc(100% - 24px - 12px);
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        color: #1f1f1f;
        font-size: 18px;
        font-weight: 600;
        line-height: 28px;
      }

      .icon {
        width: 24px;
        height: 24px;
        fill: #56565c;
        cursor: pointer;
      }
    }

    .infos {
      display: flex;
      align-items: center;
      margin-bottom: 8px;

      li {
        color: #1f1f1f;
        font-size: 16px;
        font-weight: 400;
        line-height: 20px;
        padding-right: 7px;
        margin-right: 7px;
        border-right: 2px solid #cbcbcb;

        &:last-of-type {
          padding-right: 0px;
          margin-right: 0px;
          border: none;
        }
      }
    }

    .box {
      margin-bottom: 16px;
      clear: both;
      display: flex;
      flex-direction: column;
      gap: 4px;

      .label {
        color: #1f1f1f;
        font-size: 20px;
        font-weight: 600;
        line-height: 28px;
      }

      .content {
        color: #1f1f1f;
        font-size: 14px;
        font-weight: 400;
        line-height: 20px;

        &.script {
          white-space: pre-line;
        }
      }

      .bottom {
        display: flex;
        justify-content: flex-end;

        .icon {
          fill: #56565c;
          cursor: pointer;
        }
      }
    }
  }

  .right {
    width: 600px;
    max-height: 600px;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #f7f7f7;
    overflow: hidden;
    border-radius: 4px;
  }

  @include xs-sm {
    flex-direction: column;
    height: auto;

    .left {
      width: 100% !important;
      height: auto;
    }

    .right {
      width: 100%;
      height: auto;
      padding: 16px;
    }
  }
}
