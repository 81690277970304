// Copyright 2024 The SeedV Lab (Beijing SeedV Technology Co., Ltd.)
// All Rights Reserved.

.language-list {
  .language-item {
    margin-bottom: 8px;
    height: 28px;

    .language-label {
      position: relative;
      font-size: 16px;
      font-weight: 500;
      line-height: 28px;
      background: transparent;
      cursor: pointer;
    }

    &:last-of-type {
      margin-bottom: 0;
    }
  }
}
.popover-content {
  white-space: nowrap;
  background: #f1f4f9;
  color: #1f1f1f;
  font-size: 14px;
  font-weight: 400;
  padding: 8px 12px;
  border-radius: 6px;
  filter: drop-shadow(0px 1.5px 3px rgba(3, 4, 71, 0.1))
    drop-shadow(0px 0.2px 1px rgba(3, 4, 71, 0.08));

  .icon {
    width: 14px;
    height: 10px;
    fill: #f1f4f9;
    left: -10px;
    transform: translateY(6px) rotate(270deg);
    position: absolute;
  }
}
