// Copyright 2024 The SeedV Lab (Beijing SeedV Technology Co., Ltd.)
// All Rights Reserved.

.container {
  flex: 1;
  border-radius: 16px;
  border: 1px solid rgba(0, 0, 0, 0.12);
  background: rgba(255, 255, 255, 0.9);
  padding: 24px;
  max-width: 504px;
  min-width: 330px;

  .link {
    color: rgba(48, 33, 217, 1);

    &:hover,
    &:active,
    &:focus {
      color: rgba(48, 33, 217, 1);
    }
  }

  .title {
    &.row {
      .label {
        color: #15151f;
        font-size: 18px;
        font-style: normal;
        font-weight: 500;
        line-height: 28px;
      }
    }
  }

  .row {
    display: flex;
    justify-content: space-between;
    margin-bottom: 12px;

    .label {
      color: #56565c;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 28px;
    }

    .value {
      color: #030447;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 28px;
      display: flex;
      align-items: center;

      &.fill {
        flex: 1;
      }
    }

    &:last-child {
      margin-bottom: 0;
    }
  }

  .next {
    color: #43aa13;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }
}
