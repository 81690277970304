// Copyright 2024 The SeedV Lab (Beijing SeedV Technology Co., Ltd.)
// All Rights Reserved.

.container {
  height: 46px;
  border: 1px solid rgba(0, 0, 0, 0.12);
  border-radius: 6px;
  display: flex;
  justify-content: space-between;
  gap: 8px;
  align-items: center;
  overflow: hidden;
  margin-top: 13px;
  position: relative;

  .text {
    font-size: 14px;
    font-weight: 500;
    display: inline-block;
    color: #3021d9;
    margin: 10px 0;
    cursor: pointer;
  }

  .input {
    flex: 1;
    border: none;
    outline: none;
    height: 100%;
    caret-color: #3021d9;
    padding: 0 12px;
    font-size: 14px;
    font-weight: 600;

    &.error {
      color: #f54f4a;
    }

    &:disabled {
      background: none;
      cursor: not-allowed;
    }
  }

  .apply {
    position: absolute;
    right: 12px;
    top: 10px;
    color: #3021d9;

    &.loading {
      top: 12px;
    }

    &:disabled {
      color: rgba(0, 0, 0, 0.38);
      pointer-events: none;
    }
  }

  .tips {
    position: absolute;
    right: 12px;
    top: 10px;
    font-size: 14px;
    font-weight: 600;
    line-height: 24px;
    display: flex;
    align-items: center;
    pointer-events: none;

    .icon {
      width: 20px;
      height: 20px;
      margin-right: 4px;
    }

    &.error {
      color: #f54f4a;

      .icon {
        fill: #f54f4a;
      }
    }

    &.success {
      color: #21bf7a;

      .icon {
        fill: #21bf7a;
      }
    }
  }

  &.focus {
    border-color: #3021d9;
  }

  &.empty {
    padding: 0;
    border-color: transparent;
  }
}
