.toastContainer {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1000;
}

.toastContent {
  display: flex;
  width: 380px;
  padding: 24px;
  flex-direction: column;
  gap: 16px;

  border-radius: 16px;
  background: #fff;
  box-shadow: 0 0 0px 4px #3021d9, 4px 4px 0px rgba(0, 0, 0, 0.25);
  .header {
    color: #1f1f1f;
    font-size: 18px;
    font-weight: 600;
    line-height: 24px;
    display: flex;
    height: 24px;
    .title {
      flex: 1 1 auto;
      display: flex;
      align-items: center;
    }
    .close_btn {
      cursor: pointer;
      flex: 0 0 24px;
    }
  }
  .content {
    color: #1f1f1f;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
  }

  .footer {
    display: flex;
    align-items: flex-start;
    gap: 16px;
    align-self: stretch;
    .footerBtn {
      flex: 1 0 auto;
      &.large {
        flex: 10 0 auto;
      }
      &.cancelBtn {
        flex: 0 0 auto;
      }
    }
  }
}
