// Copyright 2024 The SeedV Lab (Beijing SeedV Technology Co., Ltd.)
// All Rights Reserved.

@import 'scss/abstracts/mixins';

.ratio-icon {
  margin-right: 4px;
}

.ratio-container {
  left: auto;
  right: 0;
}

.ratio-icon-label {
  margin-right: 4px;
}

.ratio-list {
  display: flex;
  gap: 15px;

  .ratio-item {
    display: flex;
    width: 108px;
    height: 32px;
    justify-content: center;
    align-items: center;
    gap: 4px;
    cursor: pointer;
    border-radius: 6px;
    border: 1px solid rgba(0, 0, 0, 0.12);
    &:hover,
    &.active {
      border-color: #3021d9;
      border-width: 2px;
    }
    @include xs {
      width: 72.75px;
    }
  }
}
