// Copyright 2024 The SeedV Lab (Beijing SeedV Technology Co., Ltd.)
// All Rights Reserved.

@import 'scss/abstracts/mixins';

.container {
  height: 100%;
  overflow: auto;
  padding: 104px 0 60px;
  @include xs {
    padding: 126px 0 0;
  }

  .right {
    display: flex;
    align-items: center;
    gap: 12px;

    @include xs {
      gap: 6px;
      padding-top: 4px;
    }

    .button {
      height: 36px;
      border-radius: 36px;
      border: 1px solid #3021d9;
      color: #3021d9;
      font-size: 14px;
      font-weight: 500;
      cursor: pointer;
      background-color: #f7f7f7;

      &.generate {
        padding: 0 24px 0 18px;
      }

      &.history {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 0 24px 0 18px;

        .icon {
          width: 20px;
          height: 20px;
          margin-right: 4px;
          fill: #3021d9;
        }
      }
    }
  }

  .content-wrapper {
    padding: 0 24px;
    max-width: 1268px;
    margin: 0 auto;
    height: 100%;
    @include xs-sm() {
      max-width: 640px;
      padding: 0;
      height: auto;
    }
  }
  .content {
    height: 100%;
    max-height: 708px;
    border-radius: 16px;
    background: #fff;
    padding: 24px;
    position: relative;
    @include xs-sm() {
      max-height: none;
      height: auto;
    }
    @include xs {
      padding: 16px;
      padding-bottom: 0;
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
    }
  }
}
