@import 'scss/abstracts/_mixins';
@import 'scss/abstracts/animates';

.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 100;
  @include xs {
    align-items: flex-end;
    .modalContent {
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
      display: flex;
      height: calc(100% - 60px);
      width: 100%;
      animation: popupFromBottom 0.3s;
      padding: 24px 16px;
    }
  }
}

.modalContent {
  display: inline-flex;
  padding: 24px;
  flex-direction: column;
  align-items: center;
  gap: 16px;
  border-radius: 16px;
  background: #fff;
  max-height: 100%;
  max-width: 100%;
  min-width: 288px;
  // min-height: 400px;
  /* Shadow 4 */
  box-shadow: 0px 1.2px 6px 0px rgba(3, 4, 71, 0.1),
    0px 8px 16px 0px rgba(3, 4, 71, 0.12);

  z-index: 1001;
  position: relative;
  .modalHeader {
    display: flex;
    align-items: center;
    gap: 62px;
    align-self: stretch;
    .modalTitle {
      color: #1f1f1f;
      font-size: 24px;
      font-weight: 600;
      @include text-overflow-ellipsis(1);
      flex-grow: 1;
    }
    .closeButton {
      margin-left: auto;
      width: 24px;
      height: 24px;
      flex-shrink: 0;
      cursor: pointer;
      background-color: transparent;
      svg {
        fill: #1f1f1f;
      }
      &:disabled {
        cursor: not-allowed;
        svg {
          fill: #aaaaad;
        }
      }
    }
  }
}

.footer {
  display: flex;
  justify-content: flex-end;
  margin-top: 20px;
  align-self: stretch;

  button {
    margin-left: 10px;
  }
}
