// Copyright 2024 The SeedV Lab (Beijing SeedV Technology Co., Ltd.)
// All Rights Reserved.

.trigger {
  cursor: pointer;
  font-size: 0;
}
.content {
  background: #fff;
  box-shadow: 0px 0.8px 4px 0px rgba(3, 4, 71, 0.08),
    0px 6px 12px 0px rgba(3, 4, 71, 0.1);
}
