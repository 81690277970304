// Copyright 2024 The SeedV Lab (Beijing SeedV Technology Co., Ltd.)
// All Rights Reserved.

@import 'scss/abstracts/mixins';

.container {
  min-width: 236px;
  max-width: calc(100% - 24px - 44px);
  display: flex;
  flex-direction: column;

  @include xs {
    width: 100%;
    flex: 1;
    max-width: none;
  }
}

.selected-container {
  display: flex;
  align-items: center;
  gap: 6px;
}

.list {
  margin: -12px;

  .item {
    height: 32px;
    margin-bottom: 6px;
    font-weight: 500;

    .icon {
      margin-right: 6px;
    }

    .label {
      display: inline-block;
    }

    &:last-child {
      margin-bottom: 0;
    }
  }
}
