@use 'scss/abstracts/_animates.scss';

.container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100%;
  position: relative;
  --background: #e8e8eb;
}
.rotateLoading {
  display: block;
  width: 24px;
  fill: none;
  --stroke: #15151f;
  animation: spin 2s linear infinite;
}
.header {
  height: 90px;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  background: #f7f7f7;
  display: flex;
  gap: 20px;
  align-items: center;
  justify-content: space-between;
  padding: 0 24px;
  .left {
    display: flex;
    gap: 16px;
    flex-shrink: 1;
    .logo {
      width: 44px;
      height: 44px;
      border-radius: 8px;
      background: #fff;
      display: flex;
      width: 44px;
      height: 44px;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      .icon {
        fill: #56565c;
      }
    }
    .title {
      height: 44px;
      width: 320px;
      flex: 1 0 0;
      border-radius: 4px;
      background: var(--background);
    }
  }
  .right {
    flex-shrink: 1;
    height: 44px;
    width: 145px;
    background: var(--background);
    border-radius: 36px;
  }
}
.loading-info {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 36px;

  color: #1f1f1f;
  text-align: center;
  font-size: 16px;
  font-weight: 500;
  line-height: 1.5;
}
