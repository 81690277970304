// Copyright 2024 The SeedV Lab (Beijing SeedV Technology Co., Ltd.)
// All Rights Reserved.

@use 'scss/abstracts/_animates.scss';

.container {
  width: 100%;
  position: relative;
  margin-bottom: 12px;

  .menu-box {
    display: none;
  }

  .header {
    height: 24px;
    display: flex;
    align-items: center;
    margin-bottom: 12px;

    .title {
      color: #1f1f1f;
      font-size: 18px;
      font-weight: 500;
      line-height: 24px;

      &.selected {
        color: #3021d9;
      }
    }
    .animate-icon {
      color: #3021d9;
      font-size: 14px;
      font-weight: 500;
      line-height: 133%;
      display: flex;
      align-items: center;
      svg {
        fill: #3021d9;
        width: 16px;
        height: 16px;
        margin-right: 4px;
        margin-left: 10px;
      }
      span {
        cursor: pointer;
      }
    }
    .new {
      width: 60px;
      height: 22px;
      border-radius: 4px;
      margin-left: 4px;
      text-align: center;
      position: relative;
      opacity: 0;
      transition: 0.3s;

      .icon {
        fill: #3021d9;
        stroke: #3021d9;
        width: 60px;
      }

      span {
        position: absolute;
        color: #fff;
        font-size: 14px;
        font-weight: 500;
        line-height: 22px;
        left: 50%;
        top: 50%;
        transform: translateX(calc(-50% + 3px)) translateY(-50%);
      }

      &.show {
        opacity: 1;
      }
    }
  }

  .info {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;

    .preview-box {
      margin-right: 12px;
      border: 2px solid rgba(0, 0, 0, 0.12);
      border-radius: 6px;
      overflow: hidden;
      position: relative;
      cursor: pointer;
      box-sizing: initial;
      flex: 0 0 auto;

      .preview {
        width: 100%;
        height: 100%;
        pointer-events: none;
      }

      &.selected {
        border-color: #3021d9;
      }
    }
  }

  &.selected {
    .menu-box {
      display: block;
    }
  }

  &:last-of-type {
    margin-bottom: 0;
  }

  &.overlength {
    margin-bottom: 48px;
  }
}
.rotateLoading {
  display: block;
  width: 20px;
  margin-right: 4px;
  --stroke: rgba(48, 33, 217, 1);
  fill: none;
  animation: spin 2s linear infinite;
  margin-left: 10px;
}
