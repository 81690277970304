@import 'scss/abstracts/_mixins';
.blanckDataWrap {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 24px;
  .blanckDataInfo {
    display: flex;
    width: 460px;
    height: 222px;
    padding: 24px;
    flex-direction: column;
    justify-content: center;
    gap: 16px;
    border-radius: 16px;
    background: linear-gradient(
      180deg,
      #fff 6.68%,
      rgba(253, 253, 253, 0.24) 100%
    );
    .quotation {
      display: flex;
      height: 116px;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
      gap: 10px;
      flex-shrink: 0;
      align-self: stretch;
      .text {
        color: #1f1f1f;
        font-size: 16px;
        font-weight: 500;
        line-height: 1.5;
        padding: 0 22px;
        @include text-overflow-ellipsis(2);
      }
      .quotationMark {
        color: #3021d9;
        font-size: 48px;
        font-weight: 500;
        line-height: 0.5;
        &:last-child {
          align-self: flex-end;
        }
      }
    }
    .quotationAuthor {
      color: #1f1f1f;
      text-align: right;
      font-size: 16px;
      font-weight: 500;
      @include text-overflow-ellipsis(1);
    }
    @include xs-sm {
      width: auto;
    }
  }
  .blanckDataFooter {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 12px;
    .msg {
      color: var(--text-color-secondary-text-60, rgba(0, 0, 0, 0.6));
      font-size: 16px;
      font-weight: 400;
      line-height: 1.5;
    }
  }
}
