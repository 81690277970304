// Copyright 2025 The SeedV Lab (Beijing SeedV Technology Co., Ltd.)
// All Rights Reserved.

@import 'scss/abstracts/mixins';

.container {
  height: 756px;
  max-height: calc(var(--safeAreaHeight) - 60px);
  width: calc(100vw - 48px);
  max-width: 1288px;
  display: flex;
  padding: 24px;
  flex-direction: column;
  gap: 16px;
  border-radius: 16px;
  background: #fff;
  .header,
  .footer {
    flex: 0 0 auto;
  }
  .header {
    color: #1f1f1f;
    font-size: 20px;
    font-weight: 600;
    line-height: 40px;
    height: 40px;
  }
  .content {
    flex: 1;
    display: flex;
    gap: 6px;
    overflow: hidden;
    .left,
    .right {
      flex: 1;
    }
    .left {
      height: 100%;
      overflow-y: auto;
      display: flex;
      flex-direction: column;
      gap: 24px;
      padding-right: 6px;
      .secondary {
        color: #56565c;
        font-size: 14px;
        font-weight: 400;
        line-height: 28px;
      }
      .box {
        display: flex;
        flex-direction: column;
        gap: 6px;
        align-items: flex-start;
        .label {
          color: #1f1f1f;
          font-size: 18px;
          font-style: normal;
          font-weight: 600;
          line-height: 28px;
          &.required {
            &::after {
              content: '*';
              color: #aa1337;
              font-size: 20px;
              font-weight: 600;
              line-height: 28px;
              text-transform: capitalize;
            }
          }
        }
        .textarea {
          appearance: none;
          outline: none;
          border-radius: 6px;
          border: 1px solid rgba(0, 0, 0, 0.12);
          height: 64px;
          padding: 8px 12px;
          resize: none;
          width: 100%;
          color: #1f1f1f;
          &:focus {
            border-color: #3021d9;
            background: #f1f4f9;
          }
          &:disabled {
            background: #fff;
            color: #56565c;
            cursor: not-allowed;
          }
        }
        .warning {
          display: flex;
          align-items: center;
          gap: 4px;
          .icon {
            fill: #f54f4a;
            width: 20px;
            height: 20px;
          }
          .text {
            color: #aa1337;
            font-size: 14px;
            font-weight: 400;
            line-height: 24px; /* 171.429% */
          }
        }
        .uploadButton {
          color: #3021d9;
          font-size: 12px;
          padding: 6px 8px;
          font-weight: 500;
          .uploadIcon {
            fill: currentColor;
            width: 16px;
            height: 16px;
          }
        }
        .hidden {
          display: none;
        }
        .popoverContainer {
          width: 100%;
        }
        .select {
          display: flex;
          height: 36px;
          padding: 0px 8px;
          justify-content: space-between;
          align-items: center;
          border-radius: 6px;
          border: 1px solid rgba(0, 0, 0, 0.12);
          width: 100%;
          &.disabled {
            .text {
              color: #56565c;
            }
            cursor: not-allowed;
          }
          &.focused {
            border-width: 2px;
            border-color: #3021d9;
            background: #f1f4f9;
          }
          .text {
            color: #1f1f1f;
            font-size: 14px;
            font-weight: 500;
            line-height: 24px;
            flex: 1;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
          }
          .icon {
            stroke: #aaaaad;
            width: 20px;
            height: 20px;
          }
        }
      }
      .coverBox {
        display: flex;
        .box {
          flex: 1;
        }
        .image {
          flex: 0 0 auto;
          border-radius: 4px;
          overflow: hidden;
          position: relative;
          height: 88px;
          width: 88px;
          cursor: pointer;
          &.disabled {
            cursor: not-allowed;
          }
          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
          }
          .icon {
            display: none;
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
            border-radius: 50%;
            align-items: center;
            justify-content: center;
            background: rgba(255, 255, 255, 0.73);
            width: 36px;
            height: 36px;
            svg {
              fill: #000000;
              width: 20px;
              height: 20px;
            }
          }
          &:hover {
            .icon {
              display: flex;
            }
          }
        }
      }
      &.shared {
        justify-content: center;
        align-items: center;
        gap: 12px;
        .button {
          width: 200px;
          padding: 0 18px;
          font-size: 14px;
          .icon {
            width: 18px;
            height: 18px;
          }
        }
      }
    }
    .right {
      border-radius: 12px;
      background: #f7f7f7;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
  .footer {
    display: flex;
    justify-content: flex-end;
    .button {
      .icon {
        fill: #e8e8eb;
      }
    }
  }
  @include xs {
    height: calc(var(--safeAreaHeight) - 60px);
    width: 100vw;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    .content {
      .right {
        display: none;
      }
    }
    .footer {
      flex-direction: column;
      .button {
        width: 100%;
      }
    }
  }
}

.selectPopover {
  z-index: 1001;
  padding: 12px;
  flex-direction: column;
  gap: 12px;
  border-radius: 6px;
  border: 2px solid #3021d9;
  background: #fff;
  box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.25);
  .option {
    cursor: pointer;
    .label {
      color: #1f1f1f;
      font-size: 14px;
      font-weight: 500;
      line-height: 24px;
    }
    .content {
      color: #56565c;
      font-size: 14px;
      font-weight: 500;
      line-height: 24px;
    }
  }
}
