// Copyright 2024 The SeedV Lab (Beijing SeedV Technology Co., Ltd.)
// All Rights Reserved.

.footer {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.cancel {
  color: #56565c;
  height: 44px;
}

.text {
  max-width: 348px;
}
