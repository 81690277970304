// Copyright 2024 The SeedV Lab (Beijing SeedV Technology Co., Ltd.)
// All Rights Reserved.

@import 'scss/abstracts/mixins';

.collapse-header {
  padding: 16px 16px 8px !important;
}

.label-icon {
  width: 24px;
  height: 24px;
  fill: #aaaaad;
  position: relative;
}

.tips {
  color: rgba(0, 0, 0, 0.38);
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  transform: translateY(-8px);
  margin-bottom: 16px;
}

.switch {
  position: absolute;
  right: 16px;
  top: 16px;
  display: flex;
  align-items: center;
  color: #1f1f1f;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  justify-content: space-between;

  @include xs {
    position: static;
    margin: 0 0 8px;
  }

  span {
    display: inline-block;
    margin-right: 12px;
  }
}

.list {
  display: flex;
  flex-wrap: wrap;
  margin: -3px;
}
