// Copyright 2024 The SeedV Lab (Beijing SeedV Technology Co., Ltd.)
// All Rights Reserved.

@import 'scss/abstracts/mixins';

.container {
  width: 100%;
  display: flex;
  gap: 12px;
  align-items: flex-start;
  @include xs-sm() {
    flex-direction: column;
  }
}

.scrollSpyController {
  position: sticky;
  top: 104px;
  left: 0;
  width: 48px;
  border-radius: 16px;
  background-color: #fff;
  flex: 0 0 48px;
  z-index: 10;
  &.fixedSpy {
    display: none;
    position: fixed;
    top: 80px;
    left: 0;
    right: 0;
    border-top: 1px solid
      var(--neutral-color-divider-light-6, rgba(0, 0, 0, 0.06));
    background: #f7f7f7;
    box-shadow: 0px 4px 6px 0px rgba(0, 0, 0, 0.08);
    border-radius: 0;
    z-index: 11;
    padding: 8px 24px;
    @include xs {
      padding: 8px 16px;
    }
    @include xs-sm() {
      &.show {
        display: flex;
      }
    }
    :global {
      .icon-container {
        background-color: transparent;
        &.active-scroll-spy,
        &:hover {
          background-color: #f4f0ff;
        }
      }
    }
  }
  @include xs-sm() {
    width: 100%;
    display: flex;
    justify-content: space-between;
    top: 80px;
    position: initial;
  }
}

.backToTop {
  margin: 14px 0;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #3021d9;
  span {
    cursor: pointer;
  }
}

.card {
  padding-bottom: 12px;
}

:global {
  .icon-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 44px;
    width: 44px;
    cursor: pointer;
    background-color: #fff;
    border-radius: 16px;
    margin: 2px;
    svg {
      fill: #56565c;
      color: #56565c;
    }
    .stroke {
      fill: none;
      stroke: #56565c;
    }
    &.active-scroll-spy,
    &:hover {
      background-color: #f4f0ff;
      svg {
        fill: #3021d9;
        color: #3021d9;
      }
      .stroke {
        fill: none;
        stroke: #3021d9;
      }
    }
  }
}
.scroll {
  width: 100%;
}
