// Copyright 2024 The SeedV Lab (Beijing SeedV Technology Co., Ltd.)
// All Rights Reserved.

@import 'scss/abstracts/mixins';

.container {
  width: 100%;
  height: 80px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 24px;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 11;
  overflow: hidden;
  gap: 24px;
  flex-wrap: wrap;
  backdrop-filter: blur(10px);

  .left {
    display: flex;
    align-items: center;
    overflow: hidden;
    flex: 1;

    .button {
      width: 44px;
      height: 44px;
      border-radius: 8px;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      margin-right: 16px;

      .icon {
        width: 26px;
        height: 26px;
        fill: #56565c;
      }
    }

    .back {
      background: #fff;
    }

    .prev {
      background: none;
    }
  }

  .tag {
    display: flex;
    padding: 2px 12px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border-radius: 4px;
    border: 1px solid #1f1f1f;
    margin-right: 16px;
    font-size: 14px;
    font-weight: 600;
    line-height: 24px;
  }

  .title {
    flex: 1;
    color: #1f1f1f;
    text-overflow: ellipsis;
    white-space: nowrap;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    overflow: hidden;
  }

  .bg {
    width: 360px;
    position: absolute;
    right: 12.5%;
    top: 0;
    z-index: -1;
    height: 80px;
    background-size: 100% 100%;
  }

  .xs-title {
    display: none;
  }

  @include xs {
    gap: 16px;
    height: 120px;
    align-items: flex-start;
    padding: 16px;
    column-gap: 0;
    .left .button {
      margin-right: 0;
      flex: 0 0 auto;
    }
    .xs-hide {
      display: none;
    }
    .xs-title {
      display: flex;
      width: 100%;
    }
    &.small {
      height: 80px;
      .xs-title {
        display: none;
      }
    }
  }

  &.background {
    background: #f7f7f7;
    box-shadow: 0px 0.2px 1px 0px rgba(3, 4, 71, 0.08),
      0px 1.5px 3px 0px rgba(3, 4, 71, 0.1);
  }
}
.popover-container {
  z-index: 1002 !important;
  background: none !important;
  box-shadow: none !important;
  .popover-content {
    display: inline-flex;
    padding: 6px 8px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border-radius: 4px;
    background: var(--Color, #fff);

    /* Shadow 1 */
    box-shadow: 0px 0.2px 1px 0px rgba(3, 4, 71, 0.08),
      0px 1.5px 3px 0px rgba(3, 4, 71, 0.1);
    color: #1f1f1f;
    font-family: Poppins;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px; /* 166.667% */

    position: relative;
    left: 40px;
    top: -2px;
  }
}
