// Copyright 2024 The SeedV Lab (Beijing SeedV Technology Co., Ltd.)
// All Rights Reserved.

.image-bar {
  position: fixed;
  transform: translate(-50%, calc(-100% - 10px));
  z-index: 100;
  display: flex;
  align-items: center;
  background: #ffffff;
  border: 1px solid #e8e8eb;
  border-radius: 7px;
  height: 40px;
  .image-bar-item {
    width: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    svg {
      fill: #56565c;
      width: 25px;
    }
    &:hover svg {
      fill: #3021d9;
    }
  }
}
