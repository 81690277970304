@import 'scss/abstracts/mixins';

.uploadContainer {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  cursor: pointer;
}

.file {
  height: 100%;
  width: 100%;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  opacity: 0;
  outline: 0;
  position: absolute;
  .fileInput {
    display: none;
    cursor: pointer;
  }
}

.errorToastContent {
  display: flex;
  flex-direction: column;
  gap: 16px;
  max-height: 100%;
  .errorTitle {
    color: #1f1f1f;
    font-size: 16px;
    font-weight: 500;
    line-height: 1.5;
  }
  .split {
    margin: 10px 0;
    height: 1px;
    background: rgba(0, 0, 0, 0.12);
  }
  .errorContent {
    display: flex;
    flex-direction: column;
    gap: 12px;
    .subtitle {
      color: #1f1f1f;
      font-size: 14px;
      font-weight: 500;
    }
    .subInfo {
      color: var(--Text-Color-Secondary-Text-Black, #56565c);
      font-size: 14px;
      font-weight: 400;
      line-height: 24px;
      display: flex;
      gap: 2px;
      align-items: center;

      svg {
        fill: #f54f4a;
        width: 14px;
        flex-shrink: 0;
      }
      .fileName {
        display: flex;
        min-width: 0;
        .file-name-no-suffix {
          @include text-overflow-ellipsis(1);
          flex: 1;
        }
        .suffix {
          flex-shrink: 0;
        }
      }
    }
  }
}
