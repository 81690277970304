// Copyright 2024 The SeedV Lab (Beijing SeedV Technology Co., Ltd.)
// All Rights Reserved.

.container {
  position: relative;
  width: 100%;

  .textarea {
    width: 100%;
    min-height: 36px;
    font-size: 14px;

    outline: none;
    resize: none;
    background: none;
    cursor: pointer;
    border: none;
    line-height: 22px;

    padding: 8px 12px;
    border-radius: 6px;
    border-radius: 6px;
    border: 2px solid transparent;
    overflow-y: auto !important;

    &:focus {
      border-color: #3021d9;
      background: #f1f4f9;
      border-width: 2px;
      cursor: text;
    }

    &:disabled {
      cursor: default;
    }
  }

  // &::after {
  //   content: '';
  //   position: absolute;
  //   left: 0;
  //   top: 0;
  //   width: 100%;
  //   height: 100%;
  //   border-radius: 6px;
  //   border: 1px solid #d9d9d9;
  //   pointer-events: none;
  // }

  &.focus {
    &::after {
      border-color: #3021d9;
      border-width: 2px;
    }
  }
}
