// Copyright 2023 The SeedV Lab (Beijing SeedV Technology Co., Ltd.)
// All Rights Reserved.

@use 'scss/abstracts/_variables';

.container {
  width: 100%;
  height: 100%;
  position: relative;
  overflow-y: auto;
  display: flex;
  background: linear-gradient(180deg, #e5dfff 0%, #d1b6fb 100%);
  .left {
    flex: 1; /* 占据剩余空间 */
    display: flex;
    position: relative;

    > .video {
      position: absolute;
      left: 0;
      top: 0;
      bottom: 0;
      right: 0;
      margin: auto;
      max-width: calc(100% - 44px * 2);
      max-height: calc(100% - 88px * 2);
      border-radius: 42px;
      aspect-ratio: 16/9;
    }

    .header {
      padding-left: 36px;
      line-height: 120px;
      position: fixed;
      top: 0;
      left: 0;
      height: 88px;
      cursor: pointer;

      svg {
        vertical-align: middle;
        height: 64px;
        width: auto;
      }
    }
  }
  .right {
    min-height: 600px;
    flex: 0 0 512px;
    display: flex;
    gap: 64px;
    padding: 48px 36px;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    border: 1px solid var(--neutral-color-divider-12, rgba(0, 0, 0, 0.12));
    background: #fff;
    box-shadow: 0px 6px 12px 0px rgba(3, 4, 71, 0.1),
      0px 0.8px 4px 0px rgba(3, 4, 71, 0.08);
    backdrop-filter: blur(50px);
  }

  @media (max-width: 1000px) {
    .left {
      display: none;
    }
    .right {
      flex: 1;
    }
  }
}
.errorToastFooter {
  display: flex;
  flex-direction: column;
  align-items: center;
  & > button {
    align-self: stretch;
    margin-top: 16px;
  }
}
