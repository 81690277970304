.setting {
  position: absolute;
  right: 24px;
  top: 16px;
  display: flex;
  align-items: center;
  color: #1f1f1f;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  justify-content: space-between;
}
// .collapse-header {
//   padding-bottom: 9px !important;
//   & > div {
//     height: 36px;
//     line-height: 36px;
//   }
// }
.layoutGrid {
  display: flex;
  gap: 6px;
  flex-wrap: wrap;

  &::-webkit-scrollbar {
    display: none;
  }
  -ms-overflow-style: none;
  scrollbar-width: none;
}
