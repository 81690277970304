// Copyright 2024 The SeedV Lab (Beijing SeedV Technology Co., Ltd.)
// All Rights Reserved.

.back-to-top-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  .back-to-top {
    margin: 22px auto 60px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    .button {
      margin-top: 5px;
      font-size: 16px;
      font-weight: 500;
      line-height: 24px;
      color: #56565c;
    }
  }
}
