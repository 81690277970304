.label-icon {
  width: 24px;
  height: 24px;
  fill: #aaaaad;
}
.content {
  display: flex;
  flex-wrap: wrap;
  gap: 4px;
  min-height: fit-content;
}
.card-design-box {
  width: 100px;
  height: 100px;
  flex-grow: 0;
  background-size: 100% 100%;
  cursor: pointer;
  border-radius: 6px;
  background-color: #d5d5d5;

  &.isSelected {
    box-shadow: inset 0 0 0 2px #3021d9, inset 0 0 0 4px #fff;
  }
  &.none {
    background-size: 24px 24px;
    background-position: center;
    background-repeat: no-repeat;
    background-color: white;
    border-radius: 4px;
    border: 1px solid rgba(0, 0, 0, 0.12);
  }
}
