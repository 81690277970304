// Copyright 2024 The SeedV Lab (Beijing SeedV Technology Co., Ltd.)
// All Rights Reserved.

.label-icon {
  width: 24px;
  height: 24px;
  fill: #aaaaad;
}
.title {
  color: #1f1f1f;
  font-size: 16px;
  font-weight: 600;
  line-height: 28px;
  margin-bottom: 2px;
}
.list {
  display: flex;
  flex-wrap: wrap;
  margin: -3px;
}
