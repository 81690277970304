// Copyright 2024 The SeedV Lab (Beijing SeedV Technology Co., Ltd.)
// All Rights Reserved.

@import 'scss/abstracts/mixins';

.container {
  height: 100%;
  overflow-y: auto;
  background-color: rgb(247, 247, 247);

  .composite {
    color: #f7f3f2;
    text-shadow: 0px 1px 0px rgba(0, 0, 0, 0.25);
    font-size: 18px;
    font-weight: 600;
    height: 44px;
    border-radius: 36px;
    background: #3021d9;
    padding: 12px 24px 14px 24px;
    cursor: pointer;
    display: flex;
    align-items: center;

    .icon {
      width: 24px;
      height: 24px;
      fill: #f7f3f2;
      margin-left: 4px;
    }
  }

  .content {
    max-width: 1280px;
    margin: 0 auto 0;
    padding: 104px 24px 0;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    gap: 12px;
    flex-direction: row-reverse;
    @include xs-sm() {
      flex-direction: column;
      max-width: 640px;
      height: 100%;
      align-items: stretch;
      .preview-box {
        flex: 0 0 auto;
        position: static;
      }
    }
    @include sm {
      padding: 86px 24px 0;
    }
    @include xs {
      padding: 126px 0 0;
      .preview-box {
        border-radius: 0;
      }
    }
  }

  .configs {
    flex: 1 1 640px;
  }

  .preview-box {
    flex: 1 1 580px;
    padding: 12px;
    border-radius: 16px;
    background: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    position: sticky;
    top: 104px;
    right: 0;
    pointer-events: none;
  }
}
