// Copyright 2024 The SeedV Lab (Beijing SeedV Technology Co., Ltd.)
// All Rights Reserved.

.container {
  width: 132px;
  cursor: pointer;
  margin: 3px;

  .preview-box {
    width: 132px;
    height: 56px;
    background: #eeeeee;
    position: relative;
    border-radius: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-size: 20%;

    .preview-mask {
      background: #0000004d;
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      padding: 3px;

      .preview-lock {
        width: 16px;
        height: 16px;
        border-radius: 3px;
        display: flex;
        align-items: center;
        justify-content: center;
        background: #ffffffcc;

        svg {
          fill: #7c7c7c;
          stroke: #7c7c7c;
        }
      }
    }

    .preview {
      max-width: calc(100% - 20px);
      max-height: calc(100% - 4px);
      width: auto;
      height: auto;
    }

    &::after {
      content: '';
      position: absolute;
      left: -1px;
      top: -1px;
      width: 132px;
      height: 56px;
      border-radius: 6px;
      pointer-events: none;
      z-index: 2;
      border: 1px solid rgba(0, 0, 0, 0.24);
    }
  }

  .label {
    width: 100%;
    text-align: center;
    color: #1f1f1f;
    font-size: 14px;
    font-weight: 500;
    line-height: 16px;
    margin-top: 4px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  &.selected {
    .preview-box {
      &:before {
        position: absolute;
        left: 0;
        top: 0;
        width: 130px;
        height: 54px;
        border-radius: 4px;
        pointer-events: none;
        z-index: 1;
        content: '';
        border: 1px solid #fff;
      }
      &:after {
        left: -2px;
        top: -2px;
        border-color: #3021d9;
        border-width: 2px;
      }
    }

    .label {
      margin-top: 10px;
    }
  }
}
