.toggleSwitch {
  position: relative;
  height: 36px;
  background-color: #fff;
  display: flex;
  align-items: center;
  overflow: hidden;
  border-radius: 36px;
  border: 1px solid #3021d9;
}

.switchOption {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: background-color 0.3s;
  color: #3021d9;
  font-size: 18px;
  font-weight: 600;
  z-index: 1;
  border-radius: 36px;
  flex-shrink: 0;
  cursor: pointer;
  &:nth-child(2) {
    position: relative;
  }
  .discount {
    display: flex;
    height: 20px;
    padding: 6px 4px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border-radius: 2px;
    background: rgba(48, 33, 217, 0.2);
    text-align: center;
    font-size: 14px;
    font-weight: 500;
    line-height: 1.42;
    margin-left: 6px;
  }
  &.active {
    color: #f7f3f2;
    .discount {
      background: rgba(255, 255, 255, 0.3);
    }
  }
}

.slider {
  position: absolute;
  height: 32px;
  background-color: #3021d9;
  border-radius: 36px;
  transition: left 0.3s;
  transform: translateX(0%);
  left: 0;
}
