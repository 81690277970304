// Copyright 2024 The SeedV Lab (Beijing SeedV Technology Co., Ltd.)
// All Rights Reserved.

@import 'scss/abstracts/mixins';

.container {
  display: flex;
  gap: 12px;
  margin-bottom: 16px;
  align-items: center;

  .icon {
    stroke: #56565c;
    width: 24px;
    height: 24px;
    @include xs {
      display: none;
    }
  }

  .clear {
    width: 44px;
    line-height: 36px;
    text-align: center;
    color: #3021d9;
    font-size: 14px;

    span {
      cursor: pointer;
    }
  }

  @include xs {
    flex-wrap: wrap;
    flex-direction: column;

    .clear {
      width: 100%;
      line-height: 21px;
    }
  }
}

.tooltip {
  border-radius: 4px;
  padding: 6px 8px;
}
