// Copyright 2023 The SeedV Lab (Beijing SeedV Technology Co., Ltd.)
// All Rights Reserved.

.filled-button {
  background: #6f63fa;
  color: $white-text;
  border-radius: $border-radius-6;
  border: none;
  position: relative;
  outline: none;
  cursor: pointer;
  overflow: hidden;

  &:hover {
    &::after {
      content: '';
      display: block;
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      background: $white-cover1;
    }
  }

  &:active {
    &::after {
      content: '';
      display: block;
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      background: $white-cover2;
    }
  }

  &:disabled {
    background: $disable-container;
    color: $disabled-text;
    cursor: auto;
  }
}

.outlined-button {
  background: transparent;
  color: $primary-text;
  border-radius: $border-radius-3;
  border: 1px solid $outline;
  position: relative;
  outline: none;
  cursor: pointer;
  overflow: hidden;

  &:hover {
    color: $primary;

    &::after {
      content: '';
      display: block;
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      background: $primary-cover1;
    }
  }

  &:active {
    color: $primary;

    &::after {
      content: '';
      display: block;
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      background: $primary-cover2;
    }
  }

  &:disabled {
    border-color: $disabled-text;
    color: $disabled-text;
    cursor: auto;
  }
}

.outlined-error-button {
  background: transparent;
  color: $error;
  border-radius: $border-radius-6;
  border: 1px solid $error;
  position: relative;
  outline: none;
  cursor: pointer;
  overflow: hidden;

  &:hover {
    &::after {
      content: '';
      display: block;
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      background: $error-cover1;
    }
  }

  &:active {
    &::after {
      content: '';
      display: block;
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      background: $error-cover2;
    }
  }

  &:disabled {
    border-color: $disabled-text;
    color: $disabled-text;
    cursor: auto;
  }
}

.filled-error-button {
  background: $error-dark;
  color: $white;
  border-radius: $border-radius-3;
  position: relative;
  outline: none;
  border: 0;
  cursor: pointer;
  overflow: hidden;

  &:hover {
    &::after {
      content: '';
      display: block;
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      background: $white-cover1;
    }
  }

  &:active {
    &::after {
      content: '';
      display: block;
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      background: $white-cover2;
    }
  }

  &:disabled {
    color: $disabled-text;
    background: $disable-container;
    cursor: auto;
  }
}

.icon-button {
  border-radius: $border-radius-3;
  position: relative;

  &:hover {
    &::before {
      content: '';
      background: $primary-cover1;
      border-radius: $border-radius-3;
      width: 100%;
      height: 100%;
      position: absolute;
      left: 0;
      top: 0;
      cursor: pointer;
      pointer-events: none;
    }
  }

  &:active,
  &.active {
    &::before {
      content: '';
      background: $primary-cover2;
      border-radius: $border-radius-3;
      width: 100%;
      height: 100%;
      position: absolute;
      left: 0;
      top: 0;
      cursor: pointer;
      pointer-events: none;
    }
  }
}

.text-button-outlined {
  &:hover,
  &:active,
  &.active {
    color: $primary;

    svg {
      fill: $primary;
    }
  }

  &.disabled {
    color: $disabled-text;

    svg {
      fill: $disabled-text;
    }
  }
}
