// Copyright 2024 The SeedV Lab (Beijing SeedV Technology Co., Ltd.)
// All Rights Reserved.

.objects-editor {
  position: relative;
  .preview {
    width: 100%;
    height: 100%;
    border-radius: 4px;
  }
  .objects-editor-mask {
    width: 100%;
    height: 100%;
    background: rgba(255, 255, 255, 0.6);
    position: absolute;
    left: 0;
    top: 0;
    z-index: 10;
  }
  .line {
    position: absolute;
    background-color: #20ffda;
    z-index: 3;
    display: none;
    &.show {
      display: block;
    }
    &.y {
      width: 100%;
      height: 1px;
      top: 50%;
      left: 0;
      transform: translateY(-50%);
    }
    &.x {
      width: 1px;
      height: 100%;
      top: 0;
      left: 50%;
      transform: translateX(-50%);
    }
  }
}
