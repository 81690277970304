// Copyright 2023 The SeedV Lab (Beijing SeedV Technology Co., Ltd.)
// All Rights Reserved.

@use 'scss/abstracts/variables';

.container {
  padding: 0 12px;
  height: 32px;
  color: variables.$primary-text;
  border-radius: variables.$border-radius-3;
  background: variables.$surface;
  opacity: 1;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  white-space: nowrap;

  > .icon {
    width: 20px;
    height: 20px;
    margin-right: 8px;
  }
}
