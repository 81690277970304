// Copyright 2024 The SeedV Lab (Beijing SeedV Technology Co., Ltd.)
// All Rights Reserved.

.container {
  width: 24px;
  height: 24px;
  border-radius: 4px;
  cursor: pointer;
  background: none;
  display: flex;
  justify-content: center;
  align-items: center;

  .icon {
    width: 100%;
    height: 100%;

    &.fill {
      fill: #7d7d85;
    }

    &.stroke {
      stroke: #7d7d85;
    }
  }

  &:hover {
    background: #e2dfff;

    .icon {
      &.fill {
        fill: #554ed9;
      }

      &.stroke {
        stroke: #554ed9;
      }
    }
  }

  &:active {
    background: #e2dfff;

    .icon {
      &.fill {
        fill: #3021d9;
      }

      &.stroke {
        stroke: #3021d9;
      }
    }
  }

  &:disabled {
    background: none;
    cursor: default;

    .icon {
      &.fill {
        fill: #ddddde;
      }

      &.stroke {
        stroke: #ddddde;
      }
    }
  }
}
