@import 'scss/abstracts/_mixins';
@import 'scss/abstracts/_animates.scss';
.container {
  background: #f9f9f9;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-family: 'Arial', sans-serif, 'Noto Sans';
  flex: 1;
  overflow-y: scroll;
  padding-bottom: 34px;
  padding-top: 174px;
  position: relative;

  .title {
    padding: 80px 0 40px 0;
    font-size: 36px;
    font-weight: 600;
    position: absolute;
    top: 0;
    text-align: center;
    left: 50%;
    transform: translateX(-50%);
    white-space: nowrap;
  }
  .projectGrid {
    align-self: stretch;
    width: 100%;
    max-width: 1398px;
    margin: 0 auto;
    @include project-grid(auto, 24px, flex-start, 5, 24px);
    padding: 0 40px 34px;
    @include lg {
      @include project-grid(auto, 24px, flex-start, 5, 24px);
    }
    @include md {
      @include project-grid(auto, 24px, flex-start, 4, 24px);
    }
    @include sm {
      @include project-grid(auto, 24px, flex-start, 3, 24px);
    }
    @include xs {
      @include project-grid(auto, 24px, flex-start, 2, 24px);
      padding: 0 16px 34px;
    }
  }
  .blankDataSection {
    margin-top: 194px;
  }
  .rotateLoading {
    display: block;
    width: 16px;
    margin-right: 4px;
    fill: rgba(255, 255, 255, 0);
    animation: spin 2s linear infinite;
  }
  .tag-list {
    align-self: stretch;
    padding: 0 40px 36px;
    width: 100%;
    max-width: 1398px;
    margin: 0 auto;
  }
}
:global {
  .loading-info {
    height: 240px;
  }
}
