// Copyright 2024 The SeedV Lab (Beijing SeedV Technology Co., Ltd.)
// All Rights Reserved.

.container {
  display: flex;
  width: 300px;
  padding: 24px;
  flex-direction: column;
  align-items: flex-start;
  gap: 12px;
  border-radius: 16px;
  border: 2px solid #3021d9;
  background: var(--Color, #fff);
  box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.25);
  .title {
    display: flex;
    height: 62px;
    align-items: flex-start;
    gap: 24px;
    align-self: stretch;
  }
  .content {
    width: 100%;
    .buttons {
      width: 100%;
      display: flex;
      flex-direction: column;
      gap: 12px;
      .button {
        cursor: pointer;
        width: 100%;
        display: flex;
        height: 44px;
        padding: 12px 24px 14px 24px;
        justify-content: center;
        align-items: center;
        gap: 4px;
        align-self: stretch;
        border-radius: 36px;
        border: 1px solid #3021d9;
        background: #3021d9;
        color: #f2f2f2;
        text-shadow: 0px 1px 0px rgba(0, 0, 0, 0.15);
        font-family: Poppins;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: 24px; /* 150% */
        &.cancel {
          background: #fff;
          color: #3021d9;
        }
        svg {
          fill: #f2f2f2;
        }
        .icon {
          display: flex;
          align-items: center;
        }
        .text {
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
        }
      }
    }
  }
}
