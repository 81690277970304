// Copyright 2024 The SeedV Lab (Beijing SeedV Technology Co., Ltd.)
// All Rights Reserved.

@import 'scss/abstracts/_mixins';
.style-list {
  width: 100%;
  user-select: none;
  @include project-grid(auto, 18px, center, 5, 8px);
  @include xs {
    @include project-grid(auto, 12px, center, 4, 8px);
  }

  .style {
    width: 100%;
    border-radius: 4px;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    --previewLeft: 4px;
    --previewTop: 4px;
    --previewBorderRadius: 7px;

    .preview-box {
      width: 100%;
      padding-bottom: 100%;
      margin-bottom: 4px;
      position: relative;

      .box {
        position: absolute;
        left: calc(var(--previewLeft) + 4px);
        top: calc(var(--previewTop) + 4px);
        width: 16px;
        height: 16px;
        border-radius: 4px;
        display: none;

        .locked {
          width: 16px;
          height: 16px;
          position: relative;
          z-index: 3;
        }
      }

      .preview {
        position: absolute;
        left: var(--previewLeft);
        top: var(--previewTop);
        width: calc(100% - 2 * var(--previewLeft));
        height: calc(100% - 2 * var(--previewTop));
        border-radius: var(--previewBorderRadius);
      }

      &::after {
        content: '';
        width: 100%;
        height: 100%;
        position: absolute;
        left: 0;
        top: 0;
        border: 3px solid transparent;
        border-radius: 11px;
        pointer-events: none;
        box-sizing: border-box;
      }
    }

    .style-name {
      text-align: center;
      font-size: 14px;
      font-weight: 500;
      line-height: 28px;
      @include text-overflow-ellipsis(1);
    }

    &.active {
      .preview-box {
        &::after {
          border-color: #3021d9;
        }
      }
    }

    &:nth-last-child(2) {
      margin-bottom: 0;
    }

    &.disabled {
      cursor: default;

      .preview-box {
        .box {
          display: flex;
        }

        &::before {
          content: '';
          position: absolute;
          left: var(--previewLeft);
          top: var(--previewTop);
          width: calc(100% - 2 * var(--previewLeft));
          height: calc(100% - 2 * var(--previewTop));
          border-radius: var(--previewBorderRadius);
          z-index: 2;
          background: rgba(#000000, 0.3);
        }
      }
    }
  }
}
.style-name-popover {
  display: inline-flex;
  padding: 6px 8px;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  background: var(--Color, #fff);

  /* Shadow 1 */
  box-shadow: 0px 0.2px 1px 0px rgba(3, 4, 71, 0.08),
    0px 1.5px 3px 0px rgba(3, 4, 71, 0.1);

  color: #1f1f1f;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 1.67; /* 166.667% */
}
