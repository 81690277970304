// Copyright 2024 The SeedV Lab (Beijing SeedV Technology Co., Ltd.)
// All Rights Reserved.

.container {
  display: flex;
  gap: 8px;
  flex-direction: column;
}
.character-voiceover {
  display: flex;
  align-items: center;
  gap: 6px;
  font-size: 16px;
  .character-name {
    color: #1f1f1f;
    font-size: 14px;
    font-weight: 600;
    line-height: 28px;
  }
  .voiceover {
    display: flex;
    align-items: center;
    gap: 4px;
    flex: 1 0 0;
    .voiceover-name {
      color: #1f1f1f;
      font-feature-settings: 'dlig' on;
      font-size: 16px;
      font-weight: 400;
    }
  }
  .label {
    line-height: 20px;
    border-radius: 20px;
    font-size: 14px;
    background: #e8e8eb;
    padding: 0 10px;
    display: flex;
    align-items: center;
    gap: 4px;
    &.standard {
      font-size: 12px;
      background: linear-gradient(180deg, #0a2da9 0%, #09268c 100%);
      color: #f8f0f0;
      svg {
        fill: #f8f0f0;
      }
    }
    &.pro {
      font-size: 12px;
      background: linear-gradient(180deg, #8c23a7 0%, #61078b 100%);
      color: #f8f0f0;
      svg {
        fill: #f8f0f0;
      }
    }
  }
}
