.failToastWrap {
  display: flex;
  gap: 16px;
  flex-direction: column;
}
.successToastWrap {
  display: flex;
  padding-top: 24px;
  flex-direction: column;
  gap: 36px;
  flex: 1 0 0;
  .successIcon {
    width: 48px;
    height: 48px;
    align-self: center;
  }
}
.subscriptionSuccessWrap {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 12px;
  .title {
    color: #1f1f1f;

    font-size: 20px;
    font-weight: 600;
    line-height: 1.4;
  }
  .desc {
    color: var(--Text-Color-Primary-Text-Black, #15151f);
    align-self: stretch;
    /* medium body */
    font-family: Poppins, 'Noto Sans';
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 28px; /* 200% */
  }
}
.successBuyCreditWrap {
  display: flex;
  flex-direction: column;
  gap: 12px;
  align-self: stretch;

  .item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .label {
      color: var(--Text-Color-Secondary-Text-Black, #56565c);
      font-size: 14px;
      font-weight: 500;
      line-height: 2;

      &.strong {
        color: #1f1f1f;
        font-size: 20px;
        font-weight: 600;
        line-height: 1.4;
      }
    }
    .value {
      color: #1f1f1f;
      font-family: Outfit, 'Noto Sans';
      font-size: 14px;
      font-weight: 500;
      line-height: 1.5;
      display: flex;
      align-items: center;
      svg {
        width: 14px;
        height: 14px;
      }
      &.strong {
        font-size: 20px;
        font-weight: 400;
        line-height: 0.875;
        svg {
          width: 20px;
          height: 20px;
        }
      }
    }
  }
}
