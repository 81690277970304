// Copyright 2024 The SeedV Lab (Beijing SeedV Technology Co., Ltd.)
// All Rights Reserved.

.container {
  border-radius: 8px;
  background: #edf3fd;
  padding: 12px;
  position: absolute;
  top: 36px;
  color: #1f1f1f;
  font-size: 14px;
  font-weight: 400;
  left: -50%;
  transform: translateX(calc(-50% + 24px));

  .triangle {
    width: 0;
    height: 0;
    border-bottom: 12px solid #edf3fd;
    border-left: 17px solid transparent;
    border-right: 17px solid transparent;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    top: -12px;
  }
}
