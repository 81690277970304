// Copyright 2024 The SeedV Lab (Beijing SeedV Technology Co., Ltd.)
// All Rights Reserved.
@import 'scss/abstracts/_mixins';
.container {
  width: 100px;
  cursor: pointer;
  margin: 3px;

  .preview-box {
    width: 100px;
    height: 56px;
    position: relative;
    border-radius: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-size: 0;
    background-position: center;
    .preview {
      max-width: 100%;
    }

    &.has-preview-gif,
    &.has-preview-gif:hover,
    &.has-preview-gif.preview-box-selected {
      background-size: cover;
      .preview {
        visibility: hidden;
      }
    }

    &::after {
      content: '';
      position: absolute;
      left: -1px;
      top: -1px;
      width: 100px;
      height: 56px;
      border-radius: 6px;
      pointer-events: none;
      z-index: 2;
      border: 1px solid rgba(0, 0, 0, 0.24);
    }
  }

  .label {
    margin-top: 4px;
    display: inline-flex;
    text-align: center;
    gap: 2px;
    align-items: center;
    justify-content: center;
    height: 20px;
    width: 100px;
    svg {
      flex-shrink: 0;
    }
    .label-text {
      color: #1f1f1f;
      font-size: 14px;
      font-weight: 500;
      @include text-overflow-ellipsis(1);
    }
    :global {
      .label-popover {
        max-width: 100px;
      }
      .label-popover.has-icon {
        max-width: 78px;
      }
    }
  }

  &.selected {
    .preview-box {
      &:before {
        position: absolute;
        left: 0;
        top: 0;
        width: 98px;
        height: 54px;
        border-radius: 4px;
        pointer-events: none;
        z-index: 2;
        border: 1px solid #fff;
        content: '';
        z-index: 1;
      }
      &:after {
        left: -2px;
        top: -2px;
        border-color: #3021d9;
        border-width: 2px;
      }
    }
  }
}
.popover-content {
  display: inline-flex;
  padding: 6px 8px;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  background: var(--Color, #fff);

  /* Shadow 1 */
  box-shadow: 0px 0.2px 1px 0px rgba(3, 4, 71, 0.08),
    0px 1.5px 3px 0px rgba(3, 4, 71, 0.1);

  color: #1f1f1f;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 1.67; /* 166.667% */
}
