// Copyright 2023 The SeedV Lab (Beijing SeedV Technology Co., Ltd.)
// All Rights Reserved.

@use 'scss/abstracts/_variables';

.container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  .input {
    padding: 6px 12px;
    flex: 1 0 0;
    background: var(--Background-Color-Surface-Neutral-1, #f2f2f2);
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    height: 20px;
    border-radius: 4px;
    border: 1px solid transparent;
    &:focus-visible {
      outline: none;
    }
    &:focus {
      border: 1px solid #3021d9;
      background: var(--Background-Color-Surface-Neutral-1, #f2f2f2);
    }
    &:disabled {
      color: #aaaaad;
    }
  }
}
