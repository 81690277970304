// Copyright 2024 The SeedV Lab (Beijing SeedV Technology Co., Ltd.)
// All Rights Reserved.

.container {
  width: 132px;
  height: 80px;
  border-radius: 4px;
  padding: 12px 10px;
  position: relative;
  cursor: pointer;
  margin-right: 6px;
  margin-bottom: 6px;
  background: #eeeeee;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  .preview {
    width: auto;
    max-width: 100%;
    max-height: calc(100% / 2 - 2px);
    margin: 0 auto 2px;

    &:last-child {
      margin-bottom: 0;
    }
  }

  &::after {
    content: '';
    width: calc(100% - 2px);
    height: calc(100% - 2px);
    left: 0px;
    top: 0px;
    border-radius: 4px;
    position: absolute;
    border: 1px solid rgba(0, 0, 0, 0.12);
  }

  &.selected {
    cursor: default;

    &::after {
      width: calc(100% - 4px);
      height: calc(100% - 4px);
      border-width: 2px;
      border-color: #3021d9;
    }
  }

  &:nth-child(4n) {
    margin-right: 0;
  }
}
