@import 'scss/abstracts/_mixins';
.container {
  display: flex;
  flex-wrap: nowrap;
  gap: 6px;
  touch-action: none; /* Prevents scrolling while dragging on touch devices */
  @include xs {
    @include project-grid(auto, 12px, left, 3, 16px);
    .imageItem {
      max-width: 100%;
      width: 100%;
      & > svg {
        position: relative;
        width: 100%;
      }
    }
  }
}

.imageItem {
  position: relative;
  aspect-ratio: 1;
  border-radius: 6px;
  overflow: hidden;
  touch-action: none;
  -webkit-user-select: none;
  user-select: none;
  flex: 0 1 95px;
  border-radius: 6px;
  background: #f1f4f9;
  min-width: 0;
  max-width: 140px;
}

.image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  pointer-events: none; /* Prevents image drag ghost on mobile */
}

.deleteButton {
  position: absolute;
  right: 9.5px;
  top: 4.5px;
  display: flex;
  width: 27px;
  height: 27px;
  padding: 4.5px;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  border-radius: 4.5px;
  background: rgba(0, 0, 0, 0.48);
  svg {
    fill: white;
    width: 18px;
  }
}

.deleteButton:hover {
  background: rgba(0, 0, 0, 0.7);
}

.dragging {
  opacity: 0.8;
  background: #f0f0f0;
}
