// Copyright 2024 The SeedV Lab (Beijing SeedV Technology Co., Ltd.)
// All Rights Reserved.

@import 'scss/abstracts/_mixins';
@import 'scss/abstracts/_animates.scss';

$primary-color: #3021d9;
$danger-color: #c90d40;
$hover-color: #554ed9;
//基础
.button {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  border-radius: 36px;
  padding: 12px 24px;
  border: 1px solid transparent;

  &.middle {
    height: 36px;
    font-size: 16px;
    font-weight: 500;
    color: #f2f2f2;
    &:disabled {
      color: #f2f2f2;
    }
  }
  &.large {
    font-size: 18px;
    height: 44px;
    font-weight: 600;
    color: #f7f3f2;
    &:disabled {
      color: #f7f3f2;
    }
  }
  &.primary {
    @include mixin-backgroundColor-withStatus($primary-color, $hover-color);
  }
  &.danger {
    @include mixin-backgroundColor-withStatus($danger-color);
  }
}
.ghost {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  border-radius: 36px;
  padding: 12px 24px;
  color: $primary-color;
  background-color: transparent;
  &.middle {
    height: 36px;
    font-size: 16px;
    font-weight: 500;
  }
  &.large {
    font-size: 18px;
    height: 44px;
    font-weight: 600;
  }

  &.default {
    color: #56565c;
    border: 1px solid #ababab;
    svg {
      fill: #e2dfff;
    }
  }
  &.primary {
    color: $primary-color;
    border: 1px solid $primary-color;
    svg {
      fill: $primary-color;
    }
  }
  @include mixin-ghostBtn-withStatus(
    $color: $primary-color,
    $hover-color: $hover-color
  );
}
.button,
.ghost,
.link,
.text {
  svg {
    margin-right: 4px;
  }
}
%btn-text {
  color: #1f1f1f;
  font-size: 14px;
  font-weight: 400;
  line-height: 1.142;
  cursor: pointer;
  background-color: transparent;
  display: inline-flex;
  justify-content: center;
  align-items: center;

  @include mixin-textBtn-withStatus($hover-color: $hover-color);

  &.middle {
    font-size: 16px;
    line-height: 1.5;
  }
  &.large {
    font-size: 18px;
    font-weight: 500;
  }
}
.text {
  @extend %btn-text;
}
.link {
  svg {
    fill: $primary-color;
  }
  color: $primary-color;
  @extend %btn-text;
}
.bg {
  fill: transparent;
}

.icon {
  background-color: transparent;
  cursor: pointer;
  font-size: 0px;
  &.disabled {
    cursor: not-allowed;
  }

  .load-icon {
    stroke: #f7f3f2;
    fill: none !important;
    margin-right: 4px;
    transform: translateY(2px);
    animation: spin 2s linear infinite;

    &.center {
      margin-right: 0;
    }
  }

  rect:hover,
  &:hover {
    rect {
      fill: #e2dfff;
    }
  }
  & > svg {
    fill: #7d7d85;
    rect {
      fill: transparent;
    }
    &:hover {
      fill: $hover-color;
    }
    &:active {
      fill: $primary-color;
    }
    &:focus {
      fill: $primary-color;
    }
    &:disabled {
      fill: #aaaaad;
      cursor: not-allowed;
    }
  }
}
//============== 类型 end ==============
