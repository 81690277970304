// Copyright 2024 The SeedV Lab (Beijing SeedV Technology Co., Ltd.)
// All Rights Reserved.

.option-list {
  .item {
    margin-bottom: 12px;
    position: relative;

    .language-label {
      position: relative;
      font-size: 16px;
      font-weight: 500;
      cursor: pointer;
      background: transparent;
    }

    &:hover {
      .bubble {
        display: block;
      }
    }
    &:last-of-type {
      margin-bottom: 0;
    }
  }

  .input {
    width: calc(100% - 36px);
    border: none;
    border-radius: 0;
    border-bottom: 1px solid #0000003d;
    outline: none;
    margin: 6px 0 0 28px;
    caret-color: #4c33ff;
    &::placeholder {
      color: var(--neutral-color-outline-light-24, rgba(0, 0, 0, 0.24));
      font-size: 14px;
      font-weight: 500;
    }
    &:focus {
      border-color: #4c33ff;
    }

    &:disabled {
      background: none;
    }
  }
}
.panel-title-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  svg {
    width: 16px;
    height: 16px;
    fill: #56565c;
    vertical-align: middle;
  }
}
.tip-container {
  padding: 6px 8px;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  background: #fff;

  /* Shadow 1 */
  box-shadow: 0px 0.2px 1px 0px rgba(3, 4, 71, 0.08),
    0px 1.5px 3px 0px rgba(3, 4, 71, 0.1);
}

.popover-content {
  white-space: nowrap;
  background: #f1f4f9;
  color: #1f1f1f;
  font-size: 14px;
  font-weight: 400;
  padding: 8px 12px;
  border-radius: 6px;
  filter: drop-shadow(0px 1.5px 3px rgba(3, 4, 71, 0.1))
    drop-shadow(0px 0.2px 1px rgba(3, 4, 71, 0.08));

  .icon {
    width: 14px;
    height: 10px;
    fill: #f1f4f9;
    left: -10px;
    transform: translateY(6px) rotate(270deg);
    position: absolute;
  }
}
.split {
  padding: 4px 0;
  height: 1px;
  width: 100%;
  background: rgba(0, 0, 0, 0.12);
  background-clip: content-box;
  box-sizing: content-box;
}
