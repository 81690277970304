.dropdown {
  display: flex;
  width: 300px;
  padding: 24px;
  flex-direction: column;
  gap: 12px;
  border-radius: 6px;
  border: 2px solid #3021d9;
  background: #fff;
  overflow-y: auto;
  .header {
    color: #1f1f1f;
    font-size: 20px;
    font-weight: 600;
    line-height: 1.4;
  }
  .body {
    display: flex;
    align-items: center;
    align-content: center;
    gap: 12px;
    align-self: stretch;
    flex-wrap: wrap;

    .item {
      width: 68px;
      height: 68px;
      border-radius: 50%;
      overflow: hidden;
      &.selected {
        box-shadow: 0 0 0 2px #3021d9;
      }
      cursor: pointer;
      background: var(
        --avatar-back-color,
        linear-gradient(180deg, #ede8fd 0%, #dfd6ff 100%)
      );
      img {
        pointer-events: all;
        box-shadow: none;
        width: 100%;
      }
    }
  }
}
