@import 'scss/abstracts/mixins';

.imageGridInModal {
  z-index: 100;
}
.imageGridWrap {
  height: 480px;
  max-height: 100%;
  width: 604px;
  max-width: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  scrollbar-gutter: stable;

  & > div {
    grid-template-columns: repeat(4, 1fr);
    column-gap: 12px;
    row-gap: 12px;
  }
  @include xs {
    height: auto;
    flex: 1;
    & > div {
      grid-template-columns: repeat(2, 1fr);
    }
  }
  :global(.image_container) {
    cursor: pointer;
  }

  @media screen and (max-width: 680px) {
    & > div {
      justify-content: center;
    }
  }
}
.footer {
  align-self: stretch;
  display: flex;
  align-items: flex-end;
  gap: 8px;
  button {
    margin: 0;
    width: 140px;
  }
}
