// Copyright 2023 The SeedV Lab (Beijing SeedV Technology Co., Ltd.)
// All Rights Reserved.

@use 'sass:map';

.link {
  color: $link;
}

img {
  pointer-events: none;
}

@each $size, $style in $headline-text-styles {
  .headline-#{$size} {
    font-size: map.get($style, 'fs');
    font-weight: map.get($style, 'fw');
    line-height: map.get($style, 'lh');
  }
}

@each $size, $style in $title-text-styles {
  .title-#{$size} {
    font-size: map.get($style, 'fs');
    font-weight: map.get($style, 'fw');
    line-height: map.get($style, 'lh');
  }
}

@each $size, $style in $body-text-styles {
  .body-#{$size} {
    font-size: map.get($style, 'fs');
    font-weight: map.get($style, 'fw');
    line-height: map.get($style, 'lh');
  }
}

@each $size, $style in $label-text-styles {
  .label-#{$size} {
    font-size: map.get($style, 'fs');
    font-weight: map.get($style, 'fw');
    line-height: map.get($style, 'lh');
  }
}

// Global property
.cursor-pointer {
  cursor: pointer;
}

.placeholder {
  background: $placeholder;
  cursor: wait;
  border-radius: $border-radius-3;
}

.flex-center {
  display: flex;
  align-items: center;
  justify-content: center;
}

.circle {
  border-radius: 50%;
}

body {
  > canvas[id='view3D-canvas'] {
    width: 0;
    height: 0;
    display: none;
  }
}

.router-nav-item {
  .default-icon {
    display: block;
  }

  .hover-icon,
  .focused-icon {
    display: none;
  }

  &:hover {
    .hover-icon {
      display: block;
    }

    .focused-icon,
    .default-icon {
      display: none;
    }
  }

  &:active,
  &.focused {
    .focused-icon {
      display: block;
    }

    .hover-icon,
    .default-icon {
      display: none;
    }
  }
}
.hidden_scroll {
  overflow: hidden;
}
.rtl {
  .rtl-element {
    direction: rtl;
    text-align: right;
  }
}

.rtl-element-global {
  direction: rtl;
  text-align: right;
}

.rtl-bilingual {
  .rtl-bilingual-element {
    direction: rtl;
    text-align: right;
  }
}
