// Copyright 2024 The SeedV Lab (Beijing SeedV Technology Co., Ltd.)
// All Rights Reserved.

.empty {
  width: 100%;
  height: 100%;
  background: #1f1f1f;
  position: absolute;
  left: 0;
  top: 0;
  border-radius: 6px;
}
.disabled-mask {
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.6);
  position: absolute;
  left: 0;
  top: 0;
  border-radius: 6px;
}
.animate-icon-box {
  position: absolute;
  bottom: 4px;
  left: 4px;
  display: flex;
  width: 20px;
  height: 20px;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  background: rgba(255, 255, 255, 0.8);
  .animate-icon {
    fill: #3021d9;
    width: 14px;
    height: 14px;
  }
}
