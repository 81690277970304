// Copyright 2024 The SeedV Lab (Beijing SeedV Technology Co., Ltd.)
// All Rights Reserved.

.termsServiceAndPolicy {
  margin-top: 4px;
  font-size: 10px;
  font-weight: 400;
  line-height: 20px;
  cursor: pointer;
  color: var(--Text-Color-Secondary-Text-Black, #56565c);
  white-space: nowrap;
  &:hover {
    text-decoration-line: underline;
  }
}
.linkString {
  font-size: 12px;
  font-weight: 400;
  line-height: 20px;
  color: var(--Text-Color-Secondary-Text-Black, #56565c);
  margin: 0 6px;
}
