// Copyright 2024 The SeedV Lab (Beijing SeedV Technology Co., Ltd.)
// All Rights Reserved.

@import 'scss/abstracts/mixins';
.popover {
  background: #fff;
  @include xs-sm {
    display: none;
  }
}
.trigger {
  width: 32px;
  height: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
  @include xs-sm {
    display: none;
  }
}
.container {
  width: 100%;
  display: none;
  @include xs-sm {
    display: block;
  }
}
.language {
  display: flex;
  align-items: center;
  color: #15151f;
  padding: 10px 8px;
  cursor: pointer;
  &:hover {
    background: #e2dfff;
    border-radius: 6px;
  }
  &.active {
    color: #3021d9;
  }
  .icon {
    width: 20px;
    height: 20px;
    stroke: currentColor;
  }
  .label {
    color: inherit;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
    display: block;
    margin-left: 8px;
    white-space: nowrap;
  }
}
.select {
  display: flex;
  width: 180px;
  padding: 23px 24px;
  flex-direction: column;
  align-items: flex-start;
  gap: 12px;
  border-radius: 16px;
  border: 1px solid #3021d9;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  .select-item {
    color: #000;
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    cursor: pointer;
    width: 100%;
    padding: 4px;
    &:hover {
      background: #e2dfff;
      border-radius: 6px;
    }
  }
  @include xs-sm {
    width: 100%;
    padding: 0 34px;
    border: none;
    border-radius: 0;
    box-shadow: none;
    .select-item {
      color: #1f1f1f;
      font-family: 'Noto Sans';
      line-height: normal;
    }
  }
}
