// Copyright 2024 The SeedV Lab (Beijing SeedV Technology Co., Ltd.)
// All Rights Reserved.

.container {
  width: 100%;
  height: 100%;

  .logo {
    margin-top: 16px;
    margin-left: 16px;
    color: #4906d7;
  }
}
