// Copyright 2024 The SeedV Lab (Beijing SeedV Technology Co., Ltd.)
// All Rights Reserved.

@import 'scss/abstracts/mixins';

.container {
  flex: 1 1 580px;
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 16px 0;
  border-radius: 12px;
  background: #f7f7f7;
  color: #1f1f1f;

  @include xs-sm() {
    flex: 1;
    overflow: hidden;
  }

  .scroll {
    height: 100%;
    padding: 0 16px;
    overflow-y: auto;
    display: flex;
    flex-direction: column;
    gap: 16px;
  }
  .item {
    display: flex;
    flex-direction: column;
    gap: 4px;

    .textarea {
      cursor: text;
    }

    .textarea-disabled {
      color: #1f1f1f;
      opacity: 1;
    }

    .scripts-box {
      padding: 8px 0;
    }
  }
  .title {
    display: flex;
    justify-content: space-between;
  }

  .label {
    flex: 1;
    color: #1f1f1f;
    font-size: 18px;
    font-weight: 600;
    line-height: 28px;
    overflow-wrap: anywhere;
    overflow: hidden;
  }

  .copy {
    width: 20px;
    height: 20px;
    cursor: pointer;
    fill: rgba(125, 125, 133, 1);
    margin-top: 4px;
  }

  .copy-line {
    display: flex;
    justify-content: flex-end;
    margin-top: 10px;
    margin-bottom: 22px;
  }

  .infos {
    display: flex;
    padding: 4px 0px;

    li {
      color: #1f1f1f;
      font-size: 14px;
      font-weight: 400;
      line-height: 20px;

      &::after {
        content: '|';
        margin: 0 4px;
        color: #cbcbcb;
        font-size: 20px;
        font-weight: 600;
        font-family: Inter, 'Noto Sans';
        display: inline-block;
        width: 16px;
        text-align: center;
        line-height: 20px;
        vertical-align: middle;
      }

      &:last-of-type {
        &::after {
          content: '';
          display: none;
        }
      }
    }
  }

  .text {
    margin: 12px 0;
    color: #1f1f1f;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
  }

  .copy-box {
    display: flex;
    justify-content: flex-end;
    margin-bottom: 16px;
  }
  :global {
    .text-area-wrap {
      textarea {
        color: #1f1f1f;
        padding: 8px 0;
        &:disabled {
          opacity: 1;
          -webkit-text-fill-color: #1f1f1f;
          color: #1f1f1f;
        }
      }
    }
  }
}
