// Copyright 2024 The SeedV Lab (Beijing SeedV Technology Co., Ltd.)
// All Rights Reserved.

@use 'sass:map';

.dialog {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);

  z-index: 1000;

  .close-button {
    position: absolute;
    z-index: 3;
    cursor: pointer;

    &.inside {
      top: 24px;
      right: 24px;
    }

    .close {
      width: 24px;
      height: 24px;
      fill: #1f1f1f;
    }
  }
}
