@import 'scss/abstracts/_mixins';
.list {
  width: 150px;
  padding: 16px 8px;
  flex-direction: column;
  align-items: flex-start;
  gap: 4px;

  border-radius: 16px;
  background: #fff;
  font-size: 14px;

  .header,
  .footer {
    padding: 8px 0;
  }

  .listContent {
    list-style: none;
    margin: 0;
    padding: 0;
    li,
    a {
      cursor: pointer;
      display: flex;
      height: 40px;
      padding: 10px 8px;
      justify-content: flex-start;
      align-items: center;
      gap: 8px;
      border-radius: 8px;
      background: #fff;
      @include text-overflow-ellipsis(1);
      user-select: none;
      &:hover {
        background: #e2dfff;
      }
      svg {
        fill: #7c7c7c;
        width: 20px;
        flex-shrink: 0;
      }
    }
    :global {
      li.disabled {
        cursor: not-allowed;
        color: #b2b2b2;
        svg {
          fill: #b2b2b2;
        }
        &:hover {
          background: #fff;
        }
      }
    }
  }
}
