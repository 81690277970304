// Copyright 2024 The SeedV Lab (Beijing SeedV Technology Co., Ltd.)
// All Rights Reserved.

.icon {
  width: 24px;
  height: 24px;
  fill: #aaaaad;
}

.list {
  .title {
    color: #1f1f1f;
    font-size: 16px;
    font-weight: 600;
    line-height: 28px;
    margin-top: 16px;
    margin-bottom: 20px;
  }

  .item {
    margin-bottom: 10px;

    &:last-child {
      margin-bottom: 20px;
    }
  }
}

.container {
  .item {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    margin-bottom: 12px;

    .icon {
      width: 28px;
      height: 28px;
      cursor: pointer;
      pointer-events: fill;
      margin-top: -2px;
    }

    .svg {
      width: 19px;
      height: 19px;
      fill: #7c7c7c;
      margin-right: 4px;
    }
  }
}

.title {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 4px;
  font-size: 16px;
  .labels {
    display: flex;
    gap: 4px;
  }
  .label {
    line-height: 20px;
    border-radius: 20px;
    font-size: 14px;
    background: #e8e8eb;
    padding: 0 10px;
    display: flex;
    align-items: center;
    gap: 4px;
    &.standard {
      font-size: 12px;
      background: linear-gradient(180deg, #0a2da9 0%, #09268c 100%);
      color: #f8f0f0;
      svg {
        fill: #f8f0f0;
      }
    }
    &.pro {
      font-size: 12px;
      background: linear-gradient(180deg, #8c23a7 0%, #61078b 100%);
      color: #f8f0f0;
      svg {
        fill: #f8f0f0;
      }
    }
  }
}

.showMore {
  line-height: 32px;
  margin-top: 8px;
  text-align: center;
  color: #3021d9;
  font-weight: 500;
  span {
    cursor: pointer;
  }
}
.switch {
  position: absolute;
  right: 56px;
  top: 18px;
  display: flex;
  align-items: center;
  color: #1f1f1f;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;

  span {
    display: inline-block;
    margin-right: 12px;
  }
}
