@import 'scss/abstracts/mixins';

.previewer-box {
  display: flex;
  height: 36px;
  padding: 6px 12px;
  align-items: center;
  gap: 6px;
  border-radius: 4px;
  border: 1px solid rgba(0, 0, 0, 0.12);
  user-select: none;
  .previewer-box-label {
    color: #1f1f1f;
    font-size: 14px;
    font-weight: 600;
    line-height: 2;
    @include xs {
      display: none;
    }
  }
  .previewer-box-avatar {
    display: flex;
    width: 24px;
    height: 24px;
    padding-top: 0px;
    justify-content: center;
    align-items: center;
    border-radius: 59.93px;
    border: 0.706px solid #3021d6;
    overflow: hidden;
    background: var(
      --avatar-back-color,
      linear-gradient(180deg, #ede8fd 0%, #dfd6ff 100%)
    );
    img {
      width: 100%;
    }
  }
  .previewer-box-arrow {
    fill: #aaaaad;
  }
  &.disabled {
    cursor: not-allowed;
    .previewer-box-label {
      color: #aaaaad;
      font-weight: 500;
    }
  }
}
