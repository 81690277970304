// Copyright 2024 The SeedV Lab (Beijing SeedV Technology Co., Ltd.)
// All Rights Reserved.

.container {
  display: flex;
  flex-direction: column;
  gap: 10px;
  align-self: stretch;

  .script {
    font-size: 14px;
    line-height: 22px;
    word-break: break-word;
    &.double-script {
      line-height: 20px;
      color: #1f1f1f;
    }
  }
}
