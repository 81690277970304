.layout-option {
  cursor: pointer;
  flex: 0 0 100px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.selected {
  .layout-preview {
    &::after {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background: transparent;
      box-shadow: inset 0 0 0 2px #3021d9;
      border-radius: 5px;
    }
  }
}
.layout-preview {
  width: 100px;
  height: 56px;
  background: url(../../../../assets/images/avatar-background.jpg);
  background-size: 100% 100%;
  position: relative;
  overflow: hidden;
  border-radius: 5px;

  .layout-preview-image-wrapper {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    img {
      width: 100%;
      height: auto;
      user-select: none;
    }
    &.figure-left,
    &.figure-right {
      width: 56px;
      height: 50px;
      bottom: 0;
    }
    &.figure-left,
    &.circle-left {
      left: 0;
    }
    &.figure-right,
    &.circle-right {
      right: 0;
    }
    &.circle-left,
    &.circle-right {
      bottom: 0;
      width: 32px;
      height: 32px;
      margin: 6px 8px;
      border-radius: 50%;
      box-shadow: 0px 0.295px 1.476px 0px rgba(3, 4, 71, 0.08),
        0px 2.214px 4.427px 0px rgba(3, 4, 71, 0.1);
      background: var(
        --avatar-back-color,
        linear-gradient(180deg, #ede8fd 0%, #dfd6ff 100%)
      );
    }
  }
}
.layout-label {
  color: var(--Text-Color-Primary-Text-Black, #15151f);
  text-align: center;
  font-size: 12px;
  font-weight: 500;
  line-height: 28px;
  user-select: none;
  height: 24px;
}
