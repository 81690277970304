// Copyright 2024 The SeedV Lab (Beijing SeedV Technology Co., Ltd.)
// All Rights Reserved.

@import 'scss/abstracts/_mixins';

.container {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow-y: scroll;
  position: relative;
  padding-bottom: 134px;
  @include xs-sm {
    padding-top: 60px;
  }
  .i18n {
    position: absolute;
    right: 40px;
    top: 40px;
    @include xs-sm {
      display: none;
    }
  }
}

.i18n-tooltip {
  padding: 6px 8px;
  color: #1f1f1f;
  font-size: 12px;
  font-weight: 400;
  line-height: 20px;
  border-radius: 4px;
  background: #fff;
  box-shadow: 0px 0.2px 1px 0px rgba(3, 4, 71, 0.08),
    0px 1.5px 3px 0px rgba(3, 4, 71, 0.1);
}

.inputSectionWrap {
  display: flex;
  flex-wrap: nowrap;
  gap: 12px;
  padding: 0 40px;
  align-self: stretch;
  margin: 0 auto;
  width: 100%;
  justify-content: center;
  .inputSection {
    width: 196px;
    height: 124px;
    display: inline-flex;
    flex-direction: column;
    border-radius: 12px;
    cursor: pointer;
    background: #fff;
    flex-shrink: 0;
    position: relative;
    .info {
      height: 36px;
      padding: 0px 12px;
      color: #1f1f1f;
      font-size: 14px;
      font-weight: 600;
      line-height: 36px;
    }
    .img_info {
      height: 88px;
      overflow: hidden;
      border-radius: 0px 0px 12px 12px;
      &.generateCreation {
        background: #e6f6f1;
      }
      &.facelessShotVideos {
        background: #faf3d6;
      }
      &.bilingualStory {
        background: #dee8ff;
      }
      &.bilingualDialogue {
        background: rgb(219, 244, 255);
      }
      &.holidayGreeting {
        background: rgb(255, 221, 221);
      }
      .img {
        border-radius: 0px 0px 12px 12px;
        background-size: cover;
        background-position: center;
        height: 100%;
        &.generateCreation {
          background-image: url('../../../assets/images/generateCreation.jpg');
        }
        &.facelessShotVideos {
          background-image: url('../../../assets/images/facelessShotVideos.jpg');
        }
        &.bilingualStory {
          background-image: url('../../../assets/images/bilingualStory.jpg');
        }
        &.bilingualDialogue {
          background-image: url('../../../assets/images/bilingualDialogue.jpg');
        }
        &.holidayGreeting {
          background-image: url('../../../assets/images/holidayGreeting.jpg');
        }
      }
      .desc {
        color: var(--neutral-color-overlay-68, rgba(0, 0, 0, 0.68));
        font-size: 12px;
        font-weight: 400;
        line-height: 18px; /* 150% */
        padding: 12px;
      }
    }
    .tip {
      position: absolute;
      width: 16px;
      height: 16px;
      bottom: 6px;
      right: 6px;
      fill: rgba(0, 0, 0, 0.38);
      cursor: pointer;
    }
    .desc-visible-xs {
      display: none;
    }
    @include xs {
      width: 100%;
      height: 110px;
      flex-direction: row;
      gap: 16px;
      padding: 12px 16px;
      .info {
        flex-shrink: 1;
        min-width: 0;
        flex-grow: 1;
        height: 100%;
        color: #1f1f1f;
        font-size: 16px;
        font-weight: 600;
        line-height: 24px; /* 150% */
        display: flex;
        flex-direction: column;
        .title {
          flex-shrink: 0;
          line-height: 20px;
          @include text-overflow-ellipsis(2);
        }
        .desc-visible-xs {
          flex-grow: 1;
          display: block;
          color: var(--neutral-color-overlay-68, rgba(0, 0, 0, 0.68));
          font-size: 12px;
          font-weight: 400;
          line-height: 18px;
          @include text-overflow-ellipsis(3);
          &.desc-visible-xs-small {
            flex-grow: 0;
            @include text-overflow-ellipsis(2);
          }
        }
      }

      .tip {
        display: none;
      }
      .img_info {
        width: 164.325px;
        height: 86.075px;
        border-radius: 12px;
        flex-shrink: 0;
        max-width: 50%;
      }
    }
  }
  @include xs {
    flex-direction: column;
    padding: 0 16px;
    & > div {
      & > button {
        display: none;
      }
      & > div {
        flex-direction: column;
      }
    }
  }
}

.showMore {
  display: flex;
  height: 32px;
  min-width: 32px;
  padding: 6px 8px;
  justify-content: center;
  align-items: center;

  &.firstShowMore {
    margin-top: 0px;
  }
  button {
    color: #56565c;
    display: inline-flex;
  }
  svg {
    fill: #56565c;
  }
}
.projectGrid {
  margin-top: 36px;
  align-self: stretch;
  width: 100%;
  max-width: 1398px;
  margin: 0 auto;
  @include project-grid(auto, 24px, flex-start, 5, 24px);
  padding: 0 40px;
  @include lg {
    @include project-grid(auto, 24px, flex-start, 5, 24px);
  }
  @include md {
    @include project-grid(auto, 24px, flex-start, 4, 24px);
  }
  @include sm {
    @include project-grid(auto, 24px, flex-start, 3, 24px);
  }
  @include xs {
    @include project-grid(auto, 24px, flex-start, 2, 24px);
    padding: 0 16px;
  }
  .projectGridHeader {
    color: #1f1f1f;
    font-size: 20px;
    font-weight: 500;
    line-height: 24px;

    grid-row: 1 / 1;
    grid-column: 1 / -1;
    justify-self: flex-start;
    align-self: flex-end;
    display: flex;
    flex-direction: column;
    gap: 8px;
    width: 100%;
    .projectGridHeaderTitle {
      height: 32px;
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
  }
}
.recentProjects {
  margin-top: 48px;
}
.galleryProjects {
  padding-bottom: 24px;
  margin-top: 48px;
  .projectGridHeader {
    display: flex;
    flex-direction: column;
    gap: 16px;
  }
}
:global {
  .loading-info {
    grid-column: 1 / -1;
  }
}
