// Copyright 2024 The SeedV Lab (Beijing SeedV Technology Co., Ltd.)
// All Rights Reserved.

.container {
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 16px;

  .label {
    color: #1f1f1f;
    font-size: 20px;
    font-weight: 600;
    line-height: 28px;
    display: flex;
    justify-content: space-between;

    .left {
      display: flex;
      align-items: center;
      flex: 1;
      .text {
        display: inline-block;
        flex: 1;
      }
    }
  }
}
