// Copyright 2024 The SeedV Lab (Beijing SeedV Technology Co., Ltd.)
// All Rights Reserved.

@import 'scss/abstracts/mixins';

.mask {
  z-index: 9;
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.4);
  display: none;
  @include xs-sm() {
    &.active {
      display: block;
    }
  }
}

.sidebar {
  flex-shrink: 0;
  display: flex;
  width: 280px;
  padding: 16px 16px 8px 16px;
  flex-direction: column;
  background: #fff;

  .menu {
    display: none;
  }

  @include xs-sm() {
    width: auto;
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: 10;
    height: 60px;
    overflow: hidden;
    transition: height 0.3s ease-in-out;
    max-height: 575px;
    &.active {
      height: auto;
    }
    .menu {
      display: block;
      fill: #515151;
    }
  }

  .mootionWrap {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .menu {
      cursor: pointer;
    }
  }
  .mootion {
    color: #aaaaad;
    height: 32px;
    padding: 0px;
    display: block;
    cursor: default;
  }
  .sidebarLinkWrap {
    margin-top: 16px;
    display: flex;
    flex-direction: column;
    gap: 8px;

    .sidebarLink {
      color: #1f1f1f;
      display: flex;
      height: 40px;
      padding: 8px 12px;
      align-items: center;
      gap: 8px;
      font-size: 14px;
      line-height: 21px;
      border-radius: 20px;
      cursor: pointer;
      user-select: none;
      & > svg {
        width: 20px;
        height: 20px;
      }
      &.active {
        background: #ddd8fb;
        color: #4906d7;
        font-feature-settings: 'dlig' on;
        font-weight: 600;
      }
      &:hover {
        background: rgba(221, 216, 251, 0.7);
      }
    }
  }

  .userInfoWrap {
    display: flex;
    flex-direction: column;
    gap: 12px;
    margin-top: auto;

    .link {
      cursor: pointer;
      width: 86px;
      flex: 0 0 auto;
    }

    .invite-button {
      height: 36px;
      user-select: none;
      border-radius: 4px;
      background: #cfcaff;
      padding: 8px 4px;
      display: flex;
      justify-content: center;
      align-items: center;
      color: #1f1f1f;
      font-size: 16px;
      font-weight: 500;
      line-height: 21px;
      cursor: pointer;

      .icon {
        width: 18px;
        height: 18px;
        fill: #4906d7;
        margin-left: 4px;
      }
    }

    .userInfo {
      display: flex;
      align-items: center;
      gap: 8px;

      .avartar {
        width: 64px;
        height: 64px;
        border-radius: 64px;
        background: url('../../../assets/images/Avatar2.png') 50% / cover
          no-repeat;
      }

      .hasAvartar {
        background: none;
        user-select: none;
      }
    }
    .planInfo {
      display: flex;
      padding: 8px 4px;
      flex-direction: column;
      align-items: center;
      cursor: pointer;
      gap: 4px;
      border-radius: 4px;
      background: linear-gradient(104deg, #ffc7e8 20.76%, #bc93ff 94.38%);
      &.Free {
        background: linear-gradient(104deg, #eef1fa 20.76%, #faf0ff 94.38%);
      }
      .planType {
        color: #3021d9;
        font-feature-settings: 'dlig' on;
        font-size: 20px;
        font-weight: 500;
        line-height: 21px;
      }

      .creditInfo {
        display: flex;
        align-items: center;
        gap: 4px;
        justify-items: flex-end;
      }
    }
    .mobileShow {
      display: none;
      @include xs-sm() {
        display: block;
      }
    }
    .mobileHide {
      display: block;
      @include xs-sm() {
        display: none;
      }
    }
  }
}
.avartarMemu {
  user-select: none;
  color: #56565c;

  .avartarMemuItem {
    color: inherit;
  }
  li svg {
    fill: #7c7c7c;
  }
}

.list {
  margin-top: 6px;
  display: flex;
  justify-content: space-between;
  color: #ababab;
  font-size: 10px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  align-items: center;

  li {
    display: flex;
    align-items: center;

    a {
      color: #ababab;

      &:hover {
        text-decoration: underline;
      }
    }

    &.split {
      height: 10px;
      width: 1px;
      background: #ababab;
    }
  }
}
