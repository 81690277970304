// Copyright 2024 The SeedV Lab (Beijing SeedV Technology Co., Ltd.)
// All Rights Reserved.

.container {
  border-radius: 16px;
  border: 4px solid #3021d9;
  background: #fff;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  padding: 24px;

  .title {
    color: #1f1f1f;
    font-size: 18px;
    font-weight: 600;
    line-height: 24px;
  }

  .content {
    color: #1f1f1f;
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    margin: 16px 0;
  }

  .button-box {
    display: flex;
    justify-content: space-between;
    gap: 20px;

    .button {
      height: 44px;
      padding: 12px 24px 14px 24px;
      border-radius: 36px;
      font-size: 18px;
      font-weight: 600;
      display: flex;
      align-items: center;
      justify-content: center;
      background: none;
      cursor: pointer;
    }

    .cancel {
      color: #3021d9;
      border: 1px solid #3021d9;

      &:hover {
        border-color: #554ed9;
        color: #554ed9;
      }
    }

    .upgrade {
      background: #3021d9;
      color: #f7f3f2;
      text-shadow: 0px 1px 0px rgba(0, 0, 0, 0.25);
      flex-grow: 1;

      &:hover {
        background: #4c44d9;
      }
    }
  }
}
