.uploadContainer {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  cursor: pointer;
}

.file {
  height: 100%;
  width: 100%;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  opacity: 0;
  outline: 0;
  position: absolute;
  .fileInput {
    display: none;
    cursor: pointer;
  }
}

.errorToastContent {
  color: #1f1f1f;
  font-size: 16px;
  font-weight: 500;
  line-height: 1.5;
  span {
    display: inline-block;
    width: 16px;
  }
}
.errorToastFooter {
  margin-top: 16px;
  & > button {
    width: 100%;
  }
}
