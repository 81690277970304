// Copyright 2024 The SeedV Lab (Beijing SeedV Technology Co., Ltd.)
// All Rights Reserved.

.container {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  pointer-events: none;
  z-index: 9;

  .action-list {
    position: absolute;
    right: 0;
    top: 0;
    display: flex;

    .item {
      margin-right: 8px;
      position: relative;

      .button {
        cursor: pointer;
        pointer-events: fill;

        &:disabled {
          cursor: default;
        }
      }
    }
  }

  .insert-box {
    width: 24px;
    height: 24px;
    position: absolute;
    left: 0;
    right: 0;
    margin: 0 auto;
    display: flex;
    justify-content: center;
    align-items: center;

    .insert {
      width: 24px;
      height: 24px;
      pointer-events: fill;
    }

    &.top {
      top: 0px;
    }

    &.bottom {
      bottom: -36px;
    }
  }
}
