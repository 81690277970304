// Copyright 2024 The SeedV Lab (Beijing SeedV Technology Co., Ltd.)
// All Rights Reserved.

.generate-button {
  height: 44px;
  border-radius: 36px;
  padding: 12px 24px;
  background: #3021d9;
  color: #fff;
  font-size: 18px;
  font-weight: 600;
  display: flex;
  align-items: center;
  cursor: pointer;

  span {
    color: #fff;
    font-size: 14px;
    font-weight: 500;
    line-height: 21px;
    border-radius: 4px;
    background: rgba(0, 0, 0, 0.3);
    display: flex;
    align-items: center;
    padding: 0px 6px;
    margin: 0px 4px;
    transform: translateY(1px);

    .coin {
      fill: #e7e7e7;
      width: 14px;
      height: 14px;
      margin-right: 2px;
      margin-top: -1px;
    }
  }

  .right-arrow {
    width: 24px;
    height: 24px;
    fill: #fff;
  }

  &:disabled {
    background: #ababab;
    cursor: default;
  }
}
