// Copyright 2024 The SeedV Lab (Beijing SeedV Technology Co., Ltd.)
// All Rights Reserved.

.container {
  position: relative;
  flex: 1 1 452px;

  .mask {
    content: '';
    position: absolute;
    inset: 8px 4px 1px;
    background: rgba(255, 255, 255, 0.8);
    border-radius: 6px;
    &.focused {
      bottom: 2px;
    }
  }
}
