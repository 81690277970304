// Copyright 2024 The SeedV Lab (Beijing SeedV Technology Co., Ltd.)
// All Rights Reserved.

@import 'scss/abstracts/mixins';

.container {
  max-width: 1240px;
  width: calc(100vw - 48px);
  background: #fff;
  border-radius: 16px;
  padding: 24px;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  max-height: var(--safeAreaHeight);
  display: flex;
  flex-direction: column;

  .title {
    overflow: hidden;
    color: #1f1f1f;
    text-overflow: ellipsis;
    font-size: 24px;
    font-weight: 600;
    line-height: 24px;
    margin-bottom: 24px;
    min-height: 24px;
  }

  .content {
    height: 600px;
    overflow: hidden;

    &.loading {
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }

  .button-box {
    display: flex;
    justify-content: flex-end;
    overflow: hidden;
    margin-top: 16px;
    flex-wrap: wrap;
    gap: 12px;
    min-height: 40px;

    .button {
      height: 40px;

      &.download {
        .icon {
          width: 24px;
          height: 24px;
          fill: #3021d9;
        }

        span {
          color: #3021d9;
          font-size: 18px;
          font-weight: 600;
        }
      }

      &.use {
        width: 120px;
        color: #f7f3f2;

        .icon {
          width: 24px;
          height: 24px;
          fill: #f7f3f2;
          margin-left: 6px;
        }
      }
    }
  }

  @include xs-sm() {
    .content {
      height: auto;
      flex: 1;
      overflow: auto;
    }
  }
  @include sm {
    max-width: 528px;
    height: calc(var(--safeAreaHeight) - 143px);
    max-height: calc(var(--safeAreaHeight) - 36px);
  }
  @include xs {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    padding: 24px 16px;
    width: 100vw;
    height: calc(var(--safeAreaHeight) - 60px);
    .button-box {
      flex-direction: column;
      flex-wrap: nowrap;
      min-height: 144px;
      .button.use {
        width: 100%;
      }
    }
  }
}
