// Copyright 2024 The SeedV Lab (Beijing SeedV Technology Co., Ltd.)
// All Rights Reserved.

.container {
  height: 36px;
  border-radius: 6px;
  border: 1px solid rgba(0, 0, 0, 0.12);
  padding: 6px 8px;
  position: relative;
  display: flex;
  align-items: center;
  user-select: none;

  color: #1f1f1f;
  font-size: 14px;
  font-weight: 500;
  line-height: 1.7;

  .select {
    flex: 1 1 auto;
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    font-size: 14px;
    .value-wrap {
      width: calc(100% - 20px);
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
    .icon {
      display: flex;
      align-items: center;

      svg {
        fill: #7c7c7c;
      }
    }

    .dropdown {
      width: 20px;
      height: 20px;
      stroke: #aaaaad;
      stroke-opacity: 0.68;
    }
    & > div {
      width: 0;
    }
  }

  &.active {
    border-width: 2px;
    border-color: #3021d9;
    background: #f1f4f9;
    color: #1f1f1f;

    .select {
      .dropdown {
        stroke-opacity: 1;
      }
    }
  }
}
