// Copyright 2024 The SeedV Lab (Beijing SeedV Technology Co., Ltd.)
// All Rights Reserved.

.sectionTitle {
  font-size: 0.875rem;
  font-weight: 500;
  color: #4b5563;
  margin-bottom: 1rem;
}
.slideGridWrapper {
  position: relative;
  overflow: hidden;
  user-select: none;
}
.slideGrid {
  display: flex;
  gap: 12px;
  align-items: center;
  position: relative;
  transition: left 0.3s ease;
  left: 0;
}
.slideWrapper {
  position: relative;
  width: 68px;
  height: 68px;
  flex-shrink: 0;
  cursor: pointer;
  transition: transform 0.4s ease;
  border-radius: 50%;
  background: var(
    --slide-back-color,
    linear-gradient(180deg, #ede8fd 0%, #dfd6ff 100%)
  );
  overflow: hidden;

  &.none {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .icon {
    width: 24px;
    height: 24px;
    fill: rgba(255, 255, 255, 0.4);
  }
}
.slideImage {
  width: 100%;
  height: 100%;
  object-fit: cover;
  user-select: none;
  pointer-events: none;
}

.selected {
  outline: 2px solid #3021d9;
  outline-offset: -2px;
}
.navigationButton {
  width: 44px;
  height: 100%;
  position: absolute;

  top: 0;
  display: flex;
  align-items: center;
  cursor: pointer;
  transition: all 0.2s ease;
  z-index: 1;
  background: linear-gradient(270deg, #f9f9f9 23%, rgba(255, 255, 255, 0) 100%);
  svg {
    width: 60%;
    position: absolute;
  }
  &.leftBtn {
    left: 0;
    justify-content: flex-start;
    transform: rotate(180deg); /* 旋转45度 */
    svg {
      right: 0px;
    }
  }
  &.rightBtn {
    right: 0;
    justify-content: flex-end;
  }
}

.comingSoon {
  width: 30px;
  height: 68px;
  background: transparent;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #7d7d7d;
  font-family: Poppins;
  font-size: 12px;
  text-align: center;
  flex-shrink: 0;
  user-select: none;
  cursor: not-allowed;
  pointer-events: none;
}
